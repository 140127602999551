import { GridCellParams, GridColumns } from '@mui/x-data-grid'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import {
  addZipAreaOperators,
  createGridColumnsFrom,
  FilterOperator,
  MuiDataGrid,
  PageLayout,
  useGridData,
  CenterBox,
  Badge,
  PageInfoline,
} from 'common/components-mui'
import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { ConfigWeight } from 'common/graphql/schemaDefinition'

import { FallbackIssueWarnings } from '../components'
import { weightMap } from '../constants'
import matchingConfigsQuery from '../graphql/getMatchingConfigsForListPage.graphql'
import { FieldsOfLaw, GetMatchingConfigsForListPageQuery, Partners, Products } from '../interfaces/schemaDefinition'
import { listFormatter } from '../utils'

type FromArray<T> = T extends Array<infer U> ? U : never
type FetchedConfiguration = FromArray<GetMatchingConfigsForListPageQuery['matchingConfigs']['list']>

const dataObjectName = 'matchingConfigs'

const getStatus: FC<GridCellParams> = (params: GridCellParams) => {
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  const active = params.row.active as Boolean
  return active ? (
    <CenterBox>
      <Badge color="success">Aktiv</Badge>
    </CenterBox>
  ) : (
    <CenterBox>
      <Badge color="default">Inaktiv</Badge>
    </CenterBox>
  )
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
}

const columns: GridColumns = createGridColumnsFrom([
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  {
    field: 'chancellery',
    filterable: false,
    sortable: false,
    headerName: 'Kanzlei',
    valueGetter: params => {
      const location = params.row as FetchedConfiguration
      return location.chancelleryLocation.chancellery.name
    },
    flex: 0.167,
  },
  {
    field: 'location',
    filterable: false,
    sortable: false,
    headerName: 'Standort',
    valueGetter: params => {
      const location = params.row as FetchedConfiguration
      return `${location.chancelleryLocation.address.city}, ${location.chancelleryLocation.address.street}`
    },
    flex: 0.167,
  },
  {
    field: 'fieldsOfLaw',
    sortable: false,
    headerName: 'Rechtsgebiete',
    valueFormatter: params => (params.value as FieldsOfLaw).included.map(f => f.name).join(', '),
    flex: 0.167,
  },
  {
    field: 'zipAreas',
    sortable: false,
    filterOperators: addZipAreaOperators(),
    headerName: 'PLZ',
    valueFormatter: params => (params.value as Array<string>).join(', '),
    flex: 0.167,
  },
  {
    field: 'partners',
    sortable: false,
    headerName: 'Partner',
    valueFormatter: params => listFormatter((params.value as Partners).included),
    flex: 0.167,
  },
  {
    field: 'products',
    sortable: false,
    headerName: 'Produkte',
    valueFormatter: params => listFormatter((params.value as Products).included),
    flex: 0.167,
  },
  {
    field: 'weight',
    headerName: 'Gewichtung',
    filterable: false,
    valueFormatter: params => weightMap[params.value as ConfigWeight].toLocaleLowerCase() ?? 'normal',
    width: 150,
  },
  {
    field: 'active',
    headerName: 'Status',
    filterable: false,
    renderCell: getStatus,
    width: 150,
  },
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
])

// TODO: Redefine filter API. It is not a good practice to have the frontend needing to know
// how the Database layout looks like.
const mapFilterNames = (name: string): string => {
  if (name === 'partners') {
    return 'partnerIds.included'
  }
  if (name === 'products') {
    return 'productIds.included'
  }
  if (name === 'fieldsOfLaw') {
    return 'fieldOfLawIds.included'
  }
  return name
}

export const MatchingConfigListPage: FC = () => {
  const history = useHistory()
  const { data, error, actions, tableState } = useGridData<typeof dataObjectName, GetMatchingConfigsForListPageQuery>(
    CHANCELLERIES_ENDPOINT,
    matchingConfigsQuery,
    dataObjectName,
    {
      filters: [
        // Only get configurations which are not marked as deleted.
        // We don't show them ever.
        {
          name: 'deleted',
          value: false,
          operator: FilterOperator.Equals,
        },
        {
          name: 'fallback',
          value: false,
          operator: FilterOperator.Equals,
        },
        {
          name: 'tags',
          value: '',
          operator: FilterOperator.Empty,
        },
      ],
      sort: { sortBy: 'active', sortDirection: 'desc' },
    }
  )

  return (
    <PageLayout error={error} heading="Konfigurationsverwaltung" spacing="table">
      <FallbackIssueWarnings />
      <PageInfoline>Insgesamt {data?.total ?? '0'} Einträge</PageInfoline>
      <MuiDataGrid
        noBorder
        actions={actions}
        tableState={tableState}
        columns={columns}
        rows={data?.list ?? []}
        mapFilterNames={mapFilterNames}
        loading={!data}
        rowCount={data?.total}
        // eslint-disable-next-line fp/no-mutating-methods
        onRowClick={r => (r.row.id ? history.push(`/chancelleries/configurations/edit/${r.row.id}`) : undefined)}
      />
    </PageLayout>
  )
}
