import type { LoggedInUser } from 'common/user-context'
import * as roles from 'common/utils/roles'

export const LEGAL_HERO_FOL = 'Zeugnisprüfung Legal Hero'

export const FIELDS_OF_LAW: { [key: string]: [] } = {
  // 'Abfindung': [],
  // Aufhebungsvertrag: [],
  // Arbeitsrecht: [],
  'Arbeitsrecht: Abmahnung': [],
  'Arbeitsrecht: Arbeitsvertrag prüfen': [],
  'Arbeitsrecht: Aufhebungsvertrag': [],
  'Arbeitsrecht: Kündigung': [],
  'Arbeitsrecht: Zeugnisprüfung': [],
  'Arbeitsrecht: Sonstiges': [],
  'Arbeitsrecht: International': [],
  // 'Arbeitszeugnis': [],
  Arzthaftung: [],
  Asylrecht: [],
  Bankrecht: [],
  Baurecht: [],
  Dieselcheck: [],
  Dieselskandal: [],
  'DSGVO / Datenschutz': [],
  // 'Ehevertrag': [],
  Erbrecht: [],
  'Erbrecht: International': [],
  Fahrradrecht: [],
  Familienrecht: [],
  'Familienrecht: International': [],
  Gesellschaftsrecht: [],
  Gründung: [],
  Insolvenzrecht: [],
  Immobilienrecht: [],
  // 'Kündigung': [],
  // 'Kündigung Arbeitsverhältnis': [],
  Markenrecht: [],
  Medizinrecht: [],
  Mietrecht: [],
  'Mineko - Mietnebenkostencheck': [],
  // Musterfeststellungsklage: [],
  Ordnungswidrigkeiten: [],
  'PKV-Beitragsrückerstattung': [],
  'Prüfung Kündigung': [],
  Reiserecht: [],
  'Reiserecht: International': [],
  Schadensersatz: [],
  'SB-Verzicht Kulanz': [],
  'Schadensersatz Verkehrsrecht': [],
  // 'Scheidung': [],
  // 'Sorgerecht und Umgangsrecht': [],
  Sozialrecht: [],
  'Sozialrecht: International': [],
  Steuerrecht: [],
  Strafrecht: [],
  // 'Trennung': [],
  // 'Unterhalt': [],
  Urheberrecht: [],
  Verkehrsrecht: [],
  'Verkehrsrecht: International': [],
  Verkehrsunfall: [],
  Versicherungsrecht: [],
  Vertragsrecht: [],
  'Vertragsrecht: International': [],
  Verwaltungsrecht: [],
  Wettbewerbsrecht: [],
  'Widerruf Darlehen/Kredit': [],
  Wohnungseigentumsrecht: [],
  [LEGAL_HERO_FOL]: [],
}

type FOLEntries = Array<{ id: string; name: string }>

export const FIELDS_OF_LAW_ENTRIES: FOLEntries = Object.keys(FIELDS_OF_LAW).map(key => ({ id: key, name: key }))

/**
 * Make sure these rules in alignment with
 * https://klugo-gmbh.atlassian.net/wiki/spaces/KLUG/pages/2728919041/Rechtsgebiete
 *
 * Most field of laws are assignable to all roles. But for some roles, we have lists
 * of things that they are not allowed to assign. Unfortunaly these lists don't follow
 * logical/strict ruls, but are often only due to specific contract-designs.
 */
const FILTERS_PER_ROLE = {
  // Callcenter (i.e vocateur)
  CALLCENTER: FIELDS_OF_LAW_ENTRIES.filter(
    (k: { id: string }): boolean =>
      ![
        'Arzthaftung',
        'Gründung',
        'Dieselskandal',
        'Insolvenzrecht',
        'Mineko - Mietnebenkostencheck',
        'Prüfung Kündigung',
        LEGAL_HERO_FOL,
      ].includes(k.id)
  ),
  // Inbound-Lawyers
  TRB: FIELDS_OF_LAW_ENTRIES.filter(
    (k: { id: string }): boolean =>
      ![
        'Arzthaftung',
        'Dieselcheck',
        'Gründung',
        'Insolvenzrecht',
        'Prüfung Kündigung',
        LEGAL_HERO_FOL,
        'Arbeitsrecht: International',
        'Erbrecht: International',
        'Familienrecht: International',
        'Reiserecht: International',
        'Sozialrecht: International',
        'Verkehrsrecht: International',
        'Vertragsrecht: International',
      ].includes(k.id)
  ),
  // Insurance colleagues
  DEVK: FIELDS_OF_LAW_ENTRIES.filter(
    (k: { id: string }): boolean =>
      ![
        'Dieselcheck',
        'Gründung',
        'Insolvenzrecht',
        'Prüfung Kündigung',
        'Arbeitsrecht: International',
        'Erbrecht: International',
        'Familienrecht: International',
        'Reiserecht: International',
        'Sozialrecht: International',
        'Verkehrsrecht: International',
        'Vertragsrecht: International',
      ].includes(k.id)
  ),
  // Us
  ADMIN: FIELDS_OF_LAW_ENTRIES,
  // Us
  KLUGO: FIELDS_OF_LAW_ENTRIES.filter(
    (k: { id: string }): boolean =>
      !['Arzthaftung', 'Mineko - Mietnebenkostencheck', 'Prüfung Kündigung', LEGAL_HERO_FOL].includes(k.id)
  ),
  // The undefined rests receives nothing.
  // Better safe then sorry.
  FALLBACK: [],
}

export const FIELDS_OF_LAW_ENTRIES_FILTERED = (user: LoggedInUser | undefined): FOLEntries =>
  roles.isAdmin(user)
    ? FILTERS_PER_ROLE.ADMIN
    : roles.isEmployee(user)
    ? FILTERS_PER_ROLE.KLUGO
    : roles.isCallcenter(user)
    ? FILTERS_PER_ROLE.CALLCENTER
    : roles.isTRB(user) || roles.isTRBManager(user)
    ? FILTERS_PER_ROLE.TRB
    : roles.isChannel(user)
    ? FILTERS_PER_ROLE.DEVK
    : // We (i.e Klugo) isn't quite sure about
    // how to handle lawyers yet. Until we do,
    // we assume they behave like DEVK (see !1857)
    roles.isLawyer(user)
    ? FILTERS_PER_ROLE.DEVK
    : FILTERS_PER_ROLE.FALLBACK
