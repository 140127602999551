export const palette = {
  primary: {
    main: '#0a1343',
  },
  loginbg: {
    main: '#f7f7f9',
  },
  warningdark: {
    main: '#663c00',
  },
}
