import { AdviceRequestFlattened, AdviceRequestQuery, ProductType } from '../interfaces'

const mapCaseData = (response: AdviceRequestQuery['adviceRequest']): any => {
  if (!response.caseData) {
    return {}
  }
  return response.caseData.reduce(
    (caseData: any, field: any) => ({
      ...caseData,
      [field.name]: field.value,
    }),
    {}
  )
}

// eslint-disable-next-line complexity
export const mapResponseToAdviceRequest = (response: AdviceRequestQuery['adviceRequest']): AdviceRequestFlattened => ({
  id: response.id,
  createdAt: new Date(response.createdAt),
  foa: response.person.foa || '',
  firstname: response.person.firstname || '',
  lastname: response.person.lastname || '',
  phone: response.person.phone,
  email: response.person.email,
  zip: response.person.address.zip,
  street: response.person.address.street || '',
  streetNr: response.person.address.streetNr || '',
  city: response.person.address.city || '',
  country: response.person.address.country || '',
  description: response.description || '',
  externalNotes: response.externalNotes || '',
  internalNotes: response.internalNotes || '',
  adviceId: response.adviceId,
  fieldOfLaw: response.fieldOfLaw?.id,
  contactType: response.contactType,
  chancellery: response.chancellery,
  status: response.status,
  agbsAccepted: !!response.agbsAccepted,
  dataAccepted: !!response.dataAccepted,
  insured: !!response.insured,
  newsletter: !!response.newsletter,
  partner: response.partner,
  product: response.product || {
    id: 'not-set',
    price: 0,
    name: '',
    type: ProductType.Consumer,
  },

  companyName: response.company?.name || '',
  companyCompanyType: response.company?.companyType || '',
  companyAddressStreet: response.company?.address?.street || '',
  companyAddressStreetNr: response.company?.address?.streetNr || '',
  companyAddressZip: response.company?.address?.zip || '',
  companyAddressCity: response.company?.address?.city || '',
  companyTaxId: response.company?.taxId,

  billingCompanyName: response.billingCompany?.name || '',
  billingCompanyCompanyType: response.billingCompany?.companyType || '',
  billingCompanyAddressStreet: response.billingCompany?.address?.street || '',
  billingCompanyAddressStreetNr: response.billingCompany?.address?.streetNr || '',
  billingCompanyAddressZip: response.billingCompany?.address?.zip || '',
  billingCompanyAddressCity: response.billingCompany?.address?.city || '',
  billingCompanyTaxId: response.billingCompany?.taxId,

  preferredCallbackTime: response.preferredCallbackTime,
  insuranceNumber: response.insuranceNumber || '',
  invoice: response.invoice,
  ...mapCaseData(response),
})
