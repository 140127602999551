import React, { ChangeEventHandler, Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'

import { Roles } from 'common/constants'
import { HistoryEventType } from 'common/graphql/schemaDefinition'
import { UserContextProps, withUser } from 'common/user-context'
import { uploadFiles, containsSome, getQueryParam } from 'common/utils'
import { ProductType } from 'packages-mui/advice/interfaces'
import { readAdviceRequest } from 'packages/lawyers/actions/readAdviceRequest'
import { PERSON_DATA } from 'packages/lawyers/constants'

import {
  setToClosed,
  setToComplete,
  fetchAdviceDetail,
  getChancelleryLocations,
  lockAdviceRequest,
  setToMandate,
  mapResponseToAdviceRequest,
  setToPotential,
  redirect,
  saveAdviceRequest,
  saveAttachments,
  unlockAdviceRequest,
  sendToEConsult,
  confirmAdvice,
} from '../actions'
import { AdviceDetailView } from '../components/AdviceDetailView'
import { CloseModal } from '../components/CloseModal'
import { CompleteModal } from '../components/CompleteModal'
import { ConfirmAdviceModal } from '../components/ConfirmAdviceModal'
import { DatePickerModal } from '../components/DatePickerModal'
import { EConsultModal } from '../components/EConsultModal'
import { FilesModal } from '../components/FilesModal'
import { FinishAdviceModal } from '../components/FinishAdviceModal'
import { LockModal } from '../components/LockModal'
import { MandatesModal } from '../components/MandatesModal'
import { RedirectModal } from '../components/RedirectModal'
import { SaveModal } from '../components/SaveModal'
import { AdviceRequest, CompleteInput, ContactType } from '../interfaces'
import { hasEConsult, convertToSaveAdviceRequestInput } from '../utils'

interface ErrorMap {
  [key: string]: string
}

const defaultAdviceRequest: () => AdviceRequest = () => ({
  createdAt: new Date(),
  foa: '',
  firstname: '',
  lastname: '',
  street: '',
  streetNr: '',
  city: '',
  zip: '',
  email: '',
  phone: '',
  agbsAccepted: false,
  dataAccepted: false,
  insured: false,
  newsletter: false,
  damageNumber: '',
  partnerId: '',
  deductible: false,
  deductibleAmount: '',
  protection: false,
  protectionAmount: [''],
  disputeAmount: '',
  internalPartnerNotes: '',
  instance: '',
  description: '',
  internalNotes: '',
  externalNotes: '',
  closeReason: '',
  files: [],
  lawyerNotes: '',
  resultNotes: '',
  status: 'new',
  adviceId: '',
  language: {
    code: 'de',
    language: 'Deutsch',
  },
  fieldOfLaw: {
    id: '',
    name: '',
  },
  legalProtectionInsurer: '',
  insuranceNumber: '',
  reminderDate: new Date(),
  mandateId: '',
  mandateName: '',
  revenue: 0,
  history: [],
  lockedBy: '',
  product: {
    price: 0,
    netto: 0,
    name: '',
    id: '',
    type: ProductType.Consumer,
    services: [''],
  },
})

type AdviceDetailProps = RouteComponentProps<{}> & UserContextProps

interface AdviceDetailState {
  adviceDetail: AdviceRequest
  openAppointmentModal: boolean
  openCloseModal: boolean
  openMandateModal: boolean
  openMandateActions: boolean
  openCompleteModal: boolean
  openRedirectModal: boolean
  openSaveModal: boolean
  openFilesModal: boolean
  openLockModal: boolean
  openEConsultModal: boolean
  openConfirmAdviceModal: boolean
  openFinishModal: boolean
  isMounted: boolean
  chancelleryLocations: Array<{ id: string; name: string; address: { street: string; zip: string; city: string } }>
  confirmLoading: boolean
  redirectTarget: string
  redirectLoading: boolean
  redirectSuccess: boolean
  mandateLoading: boolean
  mandateSuccess: boolean
  closeLoading: boolean
  closeSuccess: boolean
  completeLoading: boolean
  completeSuccess: boolean
  fileLoading: boolean
  saveLoading: boolean
  appointmentloading: boolean
  appointmentSuccess: boolean
  withOutSaveSuccess: boolean
  saveSuccess: boolean
  eConsultSuccess: boolean
  errors: ErrorMap
  fileButton: string
  roleLawyer: boolean
  useEConsult?: boolean
  hasEConsult: boolean
  hasPremium: boolean
  transferloading: boolean
  editMode: boolean
  prevAdviceDetail: AdviceRequest
  editablePersonData: boolean
  loading?: boolean
}

export const AdviceDetailContainer = withUser(
  class AdviceDetailContainer extends Component<AdviceDetailProps, AdviceDetailState> {
    state: AdviceDetailState = {
      adviceDetail: defaultAdviceRequest(),
      prevAdviceDetail: defaultAdviceRequest(),
      openAppointmentModal: false,
      openCloseModal: false,
      openMandateModal: false,
      openMandateActions: false,
      openCompleteModal: false,
      openRedirectModal: false,
      openSaveModal: false,
      openFilesModal: false,
      openLockModal: false,
      openEConsultModal: false,
      openConfirmAdviceModal: false,
      openFinishModal: false,
      isMounted: true,
      chancelleryLocations: [],
      confirmLoading: false,
      redirectTarget: '',
      redirectLoading: false,
      redirectSuccess: false,
      mandateLoading: false,
      mandateSuccess: false,
      closeLoading: false,
      closeSuccess: false,
      completeLoading: false,
      completeSuccess: false,
      fileLoading: false,
      saveLoading: false,
      appointmentloading: false,
      appointmentSuccess: false,
      errors: {},
      fileButton: 'Anhang hinzufügen',
      roleLawyer: false,
      withOutSaveSuccess: false,
      saveSuccess: false,
      eConsultSuccess: false,
      transferloading: false,
      hasEConsult: false,
      hasPremium: false,
      editMode: false,
      editablePersonData: true,
    }

    async componentDidMount() {
      const partnerId = getQueryParam(this.props.location, 'partnerId')
      const adviceId = getQueryParam(this.props.location, 'adviceId')
      const { user } = this.props
      if (user && containsSome([Roles.Lawyer])(user.roles)) {
        this.setState({ roleLawyer: true })
      }
      if (partnerId) {
        const adviceDetail = {
          ...this.state.adviceDetail,
          partnerId,
          agbsAccepted: true,
        }
        this.setState({ adviceDetail })
      }

      if (!adviceId) {
        this.props.history.push('/404')
        return
      }

      const adviceRequest = await this.fetchAdviceRequest(adviceId)
      const chancelleryLocations = await getChancelleryLocations()
      this.setState({
        chancelleryLocations,
        // Work around to get some Test Steps
        adviceDetail: mapResponseToAdviceRequest(adviceRequest),
        openLockModal: !!adviceRequest.lockedBy && adviceRequest.lockedBy !== user!.id,
        hasEConsult: hasEConsult(adviceRequest.chancellery!.id || '', chancelleryLocations),
      })

      if (!adviceRequest.read) {
        readAdviceRequest(adviceRequest.id, true)
      }

      if (!adviceRequest.lockedBy) {
        lockAdviceRequest(adviceRequest.id)
      }
    }

    componentWillUnmount() {
      if (this.state.adviceDetail.id) {
        unlockAdviceRequest(this.state.adviceDetail.id)
      }
      this.setState({ isMounted: false })
    }

    fetchAdviceRequest = async (adviceId: string) =>
      fetchAdviceDetail(adviceId).then(response => {
        const { adviceRequest, error } = response
        if (error && error.graphQLErrors[0].message === '404-adviceRequest-not-found') {
          this.props.history.push('/404')
        }
        return adviceRequest
      })

    onOpenCloseModal = () => {
      this.setState({ openCloseModal: true, openFinishModal: false })
    }

    onCloseRequest = () => {
      let errors: { [prop: string]: string } = {}
      if (!this.state.adviceDetail.closeReason) {
        errors = {
          ...errors,
          closeReason: 'Bitte einen Grund angeben.',
        }
      }

      if (Object.keys(errors).length) {
        this.setState({ errors })
        return
      }

      const adviceDetail = {
        ...this.state.adviceDetail,
      }

      this.setState({ closeLoading: true })

      const closeInput = {
        id: adviceDetail.id ? adviceDetail.id : '',
        resultNotes: adviceDetail.resultNotes ? adviceDetail.resultNotes : '',
        lawyerNotes: adviceDetail.lawyerNotes ? adviceDetail.lawyerNotes : '',
        closeReason: adviceDetail.closeReason ? adviceDetail.closeReason : '',
      }

      setToClosed(closeInput)
        .then(result => {
          if (result) {
            const adviceDetail = {
              ...this.state.adviceDetail,
              status: 'closed' as const,
              id: result,
            }
            this.setState({ adviceDetail, closeSuccess: true, closeLoading: false })
          }
        })
        .catch(error => {
          this.setState({ closeLoading: false })
          throw error
        })
    }

    onCompleteClose = () => {
      this.setState({ openCloseModal: false })
      this.props.history.push(
        this.state.adviceDetail.product.type === ProductType.Business
          ? '/chancellery/b2b/closed'
          : '/chancellery/advice-requests/closed-requests'
      )
    }

    onOpenRedirectModal = () => {
      this.setState({ openRedirectModal: true })
    }

    setRedirectTarget = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        redirectTarget: event.currentTarget.value,
        errors: {},
      })
    }

    onRedirectRequest = async () => {
      let errors: { [prop: string]: string } = {}
      if (!this.state.redirectTarget) {
        errors = {
          ...errors,
          redirectTarget: 'Bitte eine Wahl angeben.',
        }
      }

      if (Object.keys(errors).length) {
        this.setState({ errors })
        return
      }

      this.setState({ redirectLoading: true })
      redirect(this.state.adviceDetail.id!, this.state.redirectTarget)
        .then(result => {
          if (result) {
            const adviceDetail = {
              ...this.state.adviceDetail,
              id: result,
            }
            this.setState({
              adviceDetail,
              redirectSuccess: true,
              redirectLoading: false,
            })
          }
        })
        .catch(error => {
          this.setState({ redirectLoading: false })
          throw error
        })
    }

    onCompleteRedirect = () => {
      this.setState({ openRedirectModal: false })
      this.props.history.push('/chancellery/advice-requests/open-requests')
    }

    onOpenMandateModal = () => {
      this.setState({ openMandateModal: true, openFinishModal: false })
    }

    setUseEConsult: ChangeEventHandler<HTMLInputElement> = event => {
      this.setState({
        useEConsult: event.currentTarget.value === '1',
      })
    }

    onSetMandate = () => {
      let errors: { [prop: string]: string } = {}

      if (!this.state.adviceDetail.mandateName) {
        errors = {
          ...errors,
          mandateName: 'Bitte einen Aktennamen angeben.',
        }
      }

      if (this.state.hasEConsult && this.state.useEConsult === undefined) {
        errors = {
          ...errors,
          useEConsult: 'Bitte wählen.',
        }
      }

      if (Object.keys(errors).length) {
        this.setState({ errors })
        return
      }

      if (this.state.useEConsult) {
        this.onSendMandateWithEConsult()
      } else {
        this.onSendMandate()
      }
    }

    onSendMandateWithEConsult = () => {
      this.setState({ transferloading: true, openMandateActions: false })
      const adviceDetail = {
        ...this.state.adviceDetail,
        status: 'mandate' as const,
      }

      const mandateInput = {
        id: adviceDetail.id ? adviceDetail.id : '',
        resultNotes: adviceDetail.resultNotes ? adviceDetail.resultNotes : '',
        lawyerNotes: adviceDetail.lawyerNotes ? adviceDetail.lawyerNotes : '',
        mandateId: adviceDetail.mandateId ? adviceDetail.mandateId : '',
        mandateName: adviceDetail.mandateName ? adviceDetail.mandateName : '',
      }

      setToMandate(mandateInput, true)
        .then(() => {
          this.setState({ mandateSuccess: true, transferloading: false })
        })
        .catch(error => {
          this.setState({ transferloading: false })
          throw error
        })
    }

    onSendMandate = () => {
      const adviceDetail = {
        ...this.state.adviceDetail,
      }

      this.setState({ mandateLoading: true })

      const mandateInput = {
        id: adviceDetail.id ? adviceDetail.id : '',
        resultNotes: adviceDetail.resultNotes ? adviceDetail.resultNotes : '',
        lawyerNotes: adviceDetail.lawyerNotes ? adviceDetail.lawyerNotes : '',
        mandateId: adviceDetail.mandateId ? adviceDetail.mandateId : '',
        mandateName: adviceDetail.mandateName ? adviceDetail.mandateName : '',
      }

      setToMandate(mandateInput, false)
        .then(result => {
          if (result) {
            const adviceDetail = {
              ...this.state.adviceDetail,
              status: 'mandate' as const,
              id: result,
            }
            this.setState({ adviceDetail, mandateSuccess: true, mandateLoading: false })
          }
        })
        .catch(error => {
          this.setState({ mandateLoading: false })
          throw error
        })
    }

    onCompleteMandate = () => {
      this.setState({ openMandateModal: false })
      this.props.history.push('/chancellery/advice-requests/mandates')
    }

    onOpenCompleteModal = () => {
      this.setState({ openCompleteModal: true })
    }

    onFinishedRequest = () => {
      const adviceDetail = {
        ...this.state.adviceDetail,
      }

      this.setState({ completeLoading: true })

      let completeInput: CompleteInput

      if (adviceDetail.revenue === 0) {
        completeInput = {
          id: adviceDetail.id || '',
          revenue: 0,
          noRevenueReason: adviceDetail.noRevenueReason,
        }
      } else {
        completeInput = {
          id: adviceDetail.id || '',
          revenue: Number(adviceDetail.revenue) || undefined,
        }
      }

      setToComplete(completeInput)
        .then(result => {
          if (result) {
            const adviceDetail = {
              ...this.state.adviceDetail,
              status: 'complete' as const,
              id: result,
            }
            this.setState({ adviceDetail, completeSuccess: true, completeLoading: false })
          }
        })
        .catch(error => {
          this.setState({ completeLoading: false })
          throw error
        })
    }

    onViewSales = () => {
      this.setState({ openCompleteModal: false })
      this.props.history.push(`/chancellery/sales/${this.state.adviceDetail.adviceId}`)
    }

    onCompleteComplete = () => {
      this.setState({ openCompleteModal: false })
      this.props.history.push('/chancellery/advice-requests/mandates')
    }

    onOpenAppointmentModal = () => {
      this.setState({ openAppointmentModal: true, openFinishModal: false })
    }

    onAppointmentChange = (termin: Date) => {
      const adviceDetail = {
        ...this.state.adviceDetail,
        reminderDate: termin,
      }
      this.setState({ adviceDetail, errors: {} })
    }

    onSetAppointment = () => {
      let errors: { [prop: string]: string } = {}
      if (!this.state.adviceDetail.reminderDate) {
        errors = {
          ...errors,
          reminderDate: 'Bitte einen Erinnerungstermin angeben.',
        }
      }

      if (this.state.adviceDetail.reminderDate ? this.state.adviceDetail.reminderDate.getTime() < Date.now() : false) {
        errors = {
          ...errors,
          reminderDate: 'Erinnerungstermin muss in der Zukunft liegen',
        }
      }

      if (Object.keys(errors).length) {
        this.setState({ errors })
        return
      }

      const adviceDetail = {
        ...this.state.adviceDetail,
      }

      this.setState({ adviceDetail, appointmentloading: true })

      const potentialInput = {
        id: adviceDetail.id ? adviceDetail.id : '',
        resultNotes: adviceDetail.resultNotes ? adviceDetail.resultNotes : '',
        lawyerNotes: adviceDetail.lawyerNotes ? adviceDetail.lawyerNotes : '',
        reminderDate: adviceDetail.reminderDate,
      }
      setToPotential(potentialInput)
        .then(result => {
          if (result) {
            const adviceDetail = {
              ...this.state.adviceDetail,
              status: 'potential' as const,
              id: result,
            }
            this.setState({ adviceDetail, appointmentSuccess: true, appointmentloading: false })
          }
        })
        .catch(error => {
          this.setState({ appointmentloading: false })
          throw error
        })
    }

    onCompleteAppointment = () => {
      this.setState({ openAppointmentModal: false })
      this.props.history.push('/chancellery/advice-requests/potential-mandates')
    }

    setNotes = (event: React.FormEvent<HTMLInputElement>) => {
      const { name, value } = event.currentTarget
      if (!name) {
        throw 'missing name on event target'
      } else {
        let adviceDetail = { ...this.state.adviceDetail }
        if (
          name === 'resultNotes' ||
          name === 'mandateId' ||
          name === 'mandateName' ||
          name === 'closeReason' ||
          name === 'lawyerNotes'
        ) {
          adviceDetail = {
            ...this.state.adviceDetail,
            [name]: value,
          }
        } else if (name === 'noRevenueReason') {
          adviceDetail = {
            ...this.state.adviceDetail,
            [name]: value,
          }
        }
        this.setState({ adviceDetail, errors: {} })
      }
    }

    onOpenSaveModal = () => {
      if (this.state.roleLawyer) {
        this.setState({ openSaveModal: true })
      } else {
        this.onRedirect()
      }
    }

    onContinueSave = async () => {
      this.setState({ saveLoading: true })

      const adviceRequest = {
        ...this.state.adviceDetail,
        contactType: this.state.adviceDetail.contactType && (this.state.adviceDetail.contactType as ContactType),
      }
      saveAdviceRequest(convertToSaveAdviceRequestInput(adviceRequest))
        .then(result => {
          if (result) {
            this.setState({ saveSuccess: true, saveLoading: false })
          }
        })
        .catch(error => {
          this.setState({ saveLoading: false })
          throw error
        })
    }

    onFinishedSaveClick = () => {
      if (!this.state.editMode) {
        this.onRedirect()
      }
      this.setState({
        openSaveModal: false,
        editMode: false,
        saveSuccess: false,
      })
    }

    onCancelSave = () => {
      this.setState({ withOutSaveSuccess: true })
    }

    onCanceledSaveClick = () => {
      if (!this.state.editMode) {
        this.onRedirect()
      }

      this.setState({
        adviceDetail: this.state.prevAdviceDetail,
        openSaveModal: false,
        editMode: false,
        withOutSaveSuccess: false,
      })
    }

    onOpenFilesModal = () => {
      if (!this.state.adviceDetail.lockedBy) {
        this.setState({ openFilesModal: true })
      }
    }

    getBase64 = async (file: File) =>
      new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        // tslint:disable-next-line
        reader.onload = () => {
          resolve(reader.result as string)
        }
        // tslint:disable-next-line
        reader.onerror = error => {
          reject(error)
        }
        reader.readAsDataURL(file)
      })

    onAddFiles = async (event: React.FormEvent<HTMLInputElement>) => {
      if (event.currentTarget.files !== null) {
        const files = await uploadFiles([...event.currentTarget.files])
        await saveAttachments(this.state.adviceDetail.id!, [...this.state.adviceDetail.files, ...files])
        this.setState(state => ({
          adviceDetail: {
            ...state.adviceDetail,
            files: [...this.state.adviceDetail.files, ...files],
          },
          fileButton: `${files.length} Anhänge hinzufügt!`,
        }))
      }
    }

    onContinueLock = () => {
      this.setState({ openLockModal: false })
    }

    onCancelLock = () => {
      this.setState({ openLockModal: false })
      this.props.history.push('/chancellery/advice-requests/open-requests')
    }

    changePersonData = (event: React.FormEvent<HTMLInputElement>) => {
      const { name, value } = event.currentTarget
      const field = PERSON_DATA[name]

      if (!name) {
        throw 'missing name on event target'
      } else {
        let adviceDetail = { ...this.state.adviceDetail }
        adviceDetail = {
          ...this.state.adviceDetail,
          [field]: value,
        }
        this.setState({ adviceDetail })
      }
    }

    onCloseModal = () => {
      const newAdviceDetail = this.state.adviceDetail
      this.setState({
        adviceDetail: newAdviceDetail,
        openAppointmentModal: false,
        openMandateModal: false,
        openCompleteModal: false,
        openCloseModal: false,
        openRedirectModal: false,
        openFilesModal: false,
        openMandateActions: false,
        openSaveModal: false,
        openEConsultModal: false,
        redirectSuccess: false,
        mandateSuccess: false,
        closeSuccess: false,
        completeSuccess: false,
        appointmentSuccess: false,
        errors: {},
      })
    }

    onRedirect = () => {
      unlockAdviceRequest(this.state.adviceDetail.id!)
      this.props.history.go(-1)
    }

    onEditMode = () => {
      this.setState({ editMode: true, prevAdviceDetail: this.state.adviceDetail })
    }

    onOpenEConsultModal = () => {
      if (!this.state.adviceDetail.lockedBy) {
        this.setState({ openEConsultModal: true })
      }
    }

    onCompleteEConsult = () => {
      this.setState({ openEConsultModal: false })
      this.props.history.push('/chancellery/advice-requests/mandates')
    }

    onTransferClick = async () => {
      this.setState({ transferloading: true })
      await sendToEConsult(this.state.adviceDetail.id!)
      this.setState({ transferloading: false, eConsultSuccess: true })
    }

    pushError = (error: ErrorMap): void => {
      this.setState(prevState => ({
        errors: { ...prevState.errors, ...error },
      }))
    }

    onOpenAdviceConfirmModal = (): void => {
      this.setState({ openConfirmAdviceModal: true })
    }

    onCloseAdviceConfirmModal = (): void => {
      this.setState({ openConfirmAdviceModal: false })
    }

    onConfirmAdvice = async (): Promise<void> => {
      this.setState({ confirmLoading: true })
      const confirmedDate = await confirmAdvice(this.state.adviceDetail.id || '')

      this.setState(prevState => ({
        adviceDetail: {
          ...prevState.adviceDetail,
          adviceConfirmed: new Date(confirmedDate),
        },
        openConfirmAdviceModal: false,
        confirmLoading: false,
      }))
    }

    onOpenFinishModal = (): void => {
      this.setState({ openFinishModal: true })
    }

    onCloseFinishModal = (): void => {
      this.setState({ openFinishModal: false })
    }

    onAdviceSaved = (adviceDetail: AdviceRequest) => {
      this.setState({ adviceDetail })
    }

    render() {
      const {
        errors,
        adviceDetail,
        openAppointmentModal,
        openMandateModal,
        openCompleteModal,
        openCloseModal,
        openRedirectModal,
        openSaveModal,
        openFilesModal,
        openLockModal,
        openEConsultModal,
        openConfirmAdviceModal,
        openFinishModal,
        chancelleryLocations,
        confirmLoading,
        redirectTarget,
        redirectLoading,
        redirectSuccess,
        mandateLoading,
        mandateSuccess,
        closeLoading,
        closeSuccess,
        completeLoading,
        appointmentloading,
        appointmentSuccess,
        withOutSaveSuccess,
        saveSuccess,
        eConsultSuccess,
        saveLoading,
        fileLoading,
        fileButton,
        roleLawyer,
        transferloading,
        hasPremium,
      } = this.state

      const hasEConsult = !!adviceDetail.history.find(h => h.type === HistoryEventType.SentToEConsult)

      return (
        <Fragment>
          <AdviceDetailView
            adviceDetail={adviceDetail}
            onBack={this.onRedirect}
            onCloseAdviceRequest={this.onCloseRequest}
            onOpenAppointmentModal={this.onOpenAppointmentModal}
            onOpenMandateModal={this.onOpenMandateModal}
            onOpenCompleteModal={this.onOpenCompleteModal}
            onOpenCloseModal={this.onOpenCloseModal}
            onOpenFilesModal={this.onOpenFilesModal}
            onOpenEConsultModal={this.onOpenEConsultModal}
            onOpenAdviceConfirmModal={this.onOpenAdviceConfirmModal}
            onOpenFinishModal={this.onOpenFinishModal}
            onAddFiles={this.onAddFiles}
            onAdviceSaved={this.onAdviceSaved}
            fileButton={fileButton}
            roleLawyer={roleLawyer}
            hasEConsult={hasEConsult}
            hasPremium={hasPremium}
          />
          <ConfirmAdviceModal
            open={openConfirmAdviceModal}
            onConfirmClick={this.onConfirmAdvice}
            onCloseClick={this.onCloseAdviceConfirmModal}
            loading={confirmLoading}
            title="Haben Sie Kontakt mit Ihrem Kunden aufgenommen und möchten mit der weiteren Fallbearbeitung fortfahren?"
            confirmBtnTitle="Erstkontakt bestätigen"
          />
          <FinishAdviceModal
            open={openFinishModal}
            onCloseClick={this.onCloseFinishModal}
            onOpenCloseModal={this.onOpenCloseModal}
            onOpenMandateModal={this.onOpenMandateModal}
            onOpenAppointmentModal={this.onOpenAppointmentModal}
          />
          <DatePickerModal
            adviceDetail={adviceDetail}
            open={openAppointmentModal}
            loading={appointmentloading}
            success={appointmentSuccess}
            onCancelClick={this.onCloseModal}
            onAppointmentClick={this.onSetAppointment}
            onCompleteClick={this.onCompleteAppointment}
            onAppointmentChange={this.onAppointmentChange}
            setNotes={this.setNotes}
            errors={errors}
          />
          <MandatesModal
            adviceDetail={adviceDetail}
            open={openMandateModal}
            loading={mandateLoading}
            hasEConsult={this.state.hasEConsult}
            useEConsult={this.state.useEConsult}
            setUseEConsult={this.setUseEConsult}
            success={mandateSuccess}
            transferloading={transferloading}
            onMandateClick={this.onSetMandate}
            onCancelClick={this.onCloseModal}
            onCompleteClick={this.onCompleteMandate}
            setNotes={this.setNotes}
            errors={errors}
          />
          <CompleteModal
            open={openCompleteModal}
            loading={completeLoading}
            onCancelClick={this.onCloseModal}
            onViewSalesClick={this.onViewSales}
          />
          <CloseModal
            adviceDetail={adviceDetail}
            open={openCloseModal}
            loading={closeLoading}
            success={closeSuccess}
            onCancelClick={this.onCloseModal}
            onCloseClick={this.onCloseRequest}
            onCompleteClick={this.onCompleteClose}
            setNotes={this.setNotes}
            errors={errors}
          />
          <RedirectModal
            chancelleries={chancelleryLocations}
            open={openRedirectModal}
            loading={redirectLoading}
            success={redirectSuccess}
            redirectTarget={redirectTarget}
            onCancelClick={this.onCloseModal}
            onCompleteClick={this.onCompleteRedirect}
            onRedirectClick={this.onRedirectRequest}
            setRedirectTarget={this.setRedirectTarget}
            errors={errors}
          />
          <SaveModal
            open={openSaveModal}
            loading={saveLoading}
            saveSuccess={saveSuccess}
            withOutSaveSuccess={withOutSaveSuccess}
            onCancelClick={this.onCloseModal}
            onWithoutSaveClick={this.onCancelSave}
            onContinueSaveClick={this.onContinueSave}
            onCanceledSaveClick={this.onCanceledSaveClick}
            onFinishedSaveClick={this.onFinishedSaveClick}
          />
          <FilesModal
            open={openFilesModal}
            loading={fileLoading}
            onCancelClick={this.onCloseModal}
            files={adviceDetail.files || []}
            onAddFiles={this.onAddFiles}
          />
          <LockModal open={openLockModal} onCancelClick={this.onCancelLock} onContinueClick={this.onContinueLock} />
          <EConsultModal
            open={openEConsultModal}
            success={eConsultSuccess}
            transferloading={transferloading}
            onCancelClick={this.onCloseModal}
            onCompleteClick={this.onCompleteEConsult}
            onTransferClick={this.onTransferClick}
          />
        </Fragment>
      )
    }
  }
)
