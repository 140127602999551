import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Alert, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  PageLayout,
  LoadingMessage,
  AbortButton,
  SaveButton,
  DeleteWithConfirmation,
  FormActionBox,
  Grid,
} from 'common/components-mui'
import { Autocomplete, Checkbox, Select, GroupInput } from 'common/components-mui/react-hook-form'
import { CHANCELLERIES_ENDPOINT, FIELDS_OF_LAW_ENTRIES } from 'common/constants'
import { useParams, useRedirect } from 'common/hooks'
import { request, addOptionalPropIf, enqueueSnackbar } from 'common/utils'

import { getConfigData } from '../actions'
import { FallbackIssueWarnings } from '../components'
import { weightMap } from '../constants'
import saveMatchingConfigMutation from '../graphql/saveMatchingConfig.graphql'
import { useProductAndPartnerData } from '../hooks'
import { ConfigurationFormPageParams } from '../interfaces'
import { ConfigurationFormValues, configurationInitialValues, configurationSchema } from '../interfaces/formSchemas'
import { ConfigWeight, MatchingConfigInput, SaveMatchingConfigMutation } from '../interfaces/schemaDefinition'
import { calculatePriority, sortProducts, toProductType, mapMatchingConfigAPIDataToForm } from '../utils'

type isOptionEqual = <T extends string | { id: string; name: string }>(option: T, value: T) => boolean

const toApiInputFormat = (values: ConfigurationFormValues): MatchingConfigInput => {
  const { locationId, zipCodes, fieldsOfLaw, partners, products, weight, priority, configurationId, active, deleted } = values

  const baseInput = {
    zipAreas: zipCodes,
    active,
    deleted,
    fieldOfLawIds: {
      included: fieldsOfLaw,
    },
    productIds: {
      included: products.map(p => p.id),
    },
    partnerIds: {
      included: partners.map(p => p.id),
    },
    weight,
    priority,
    chancelleryLocationId: locationId ?? '',
    fallback: false,
  }

  const withId = addOptionalPropIf<{ id: string }, MatchingConfigInput>(configurationId.length > 0)({ id: configurationId })

  return withId(baseInput)
}

export const ConfigurationFormPage: FC = () => {
  const { id, lid: chancelleryLocationId } = useParams<ConfigurationFormPageParams>()
  const [configurationDataLoading, setConfigurationDataLoading] = useState(false)
  const redirect = useRedirect()

  const toOverview = (): void => redirect('/chancelleries')

  const {
    products: { response: productsResponse, error: productsError },
    productsNew: { response: productsNewResponse, error: productsNewError },
    partners: { response: partnersResponse, error: partnersError },
    isLoading: isLoadingProductsAndPartner,
  } = useProductAndPartnerData()

  const hasError = productsError || productsNewError || partnersError
  const isLoading = isLoadingProductsAndPartner && configurationDataLoading
  const products = [...(productsResponse?.data.products.list || []), ...(productsNewResponse?.data.products.list || [])]
  const initialValues = {
    ...configurationInitialValues,
    locationId: chancelleryLocationId || '',
  }

  const { reset, control, handleSubmit, getValues, setValue, watch } = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(configurationSchema),
  })
  const [fieldsOfLaw, selectedProducts, partners, configurationId, zipCodes] = watch([
    'fieldsOfLaw',
    'products',
    'partners',
    'configurationId',
    'zipCodes',
  ])

  useEffect(() => {
    if (id) {
      setConfigurationDataLoading(true)
      getConfigData(id).then(values => {
        reset(values)
        setConfigurationDataLoading(false)
      })
    }
  }, [id, reset])

  const isOptionEqual: isOptionEqual = (option, value) =>
    typeof option === 'object' && typeof value === 'object'
      ? option.id === value.id && option.name === value.name
      : option === value

  const sortFol = (a: string, b: string): number => a.localeCompare(b)
  const sortPartner = (a: { id: string; name: string }, b: { id: string; name: string }): number => a.name.localeCompare(b.name)
  // eslint-disable-next-line fp/no-mutating-methods
  partnersResponse?.data.partners.list.sort(sortPartner)

  const onSubmit = (values: ConfigurationFormValues): Promise<void> => {
    const prioBaselineConfig = {
      folMax: FIELDS_OF_LAW_ENTRIES.length,
      productsMax: productsResponse?.data.products.list.length ?? 0,
      partnersMax: partnersResponse?.data.partners.list.length ?? 0,
      weight: values.weight,
    }
    const input: MatchingConfigInput = toApiInputFormat({ ...values, priority: calculatePriority(prioBaselineConfig, values) })
    return request<SaveMatchingConfigMutation>(CHANCELLERIES_ENDPOINT, saveMatchingConfigMutation, {
      matchingConfig: input,
    })
      .then(({ saveMatchingConfig }) => {
        if (saveMatchingConfig) {
          reset(mapMatchingConfigAPIDataToForm(saveMatchingConfig))
          enqueueSnackbar('Konfiguration wurde gespeichert.', { variant: 'success' })
        }
      })
      .catch(() => {
        enqueueSnackbar('Konfiguration konnte nicht gespeichert werden.', { variant: 'error' })
      })
  }

  return (
    <PageLayout heading={id ? 'Konfiguration bearbeiten' : 'Konfiguration anlegen'}>
      <FallbackIssueWarnings />
      <LoadingMessage isLoading={isLoading} />

      {hasError ? (
        <Alert severity="error">
          {partnersError
            ? 'Partner konnten nicht geladen werden.'
            : productsError
            ? 'B2B Produkte konnten nicht geladen werden.'
            : 'B2C Produkte konnten nicht geladen werden.'}
        </Alert>
      ) : (
        <Box component="form" id="configuration" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} alignItems="center">
            <Grid sm={12}>
              <Checkbox control={control} name="active" label="Aktiv" disabled={isLoading} />
            </Grid>
            <Grid sm={12}>
              <GroupInput
                onChange={v => setValue('zipCodes', v)}
                value={zipCodes}
                name="zipCodes"
                label="PLZ"
                disabled={isLoading}
              />
            </Grid>
            <Grid sm={12} md={9}>
              <Autocomplete
                multiple
                sortSelected={sortFol}
                label="Rechtsgebiete"
                name="fieldsOfLaw"
                control={control}
                limitTags={5}
                ChipProps={{ color: 'primary' }}
                disabled={isLoading}
                options={FIELDS_OF_LAW_ENTRIES.map(f => f.name)}
                sx={{ sm: { marginTop: '1.5rem' }, md: { marginTop: 0 } }}
              />
            </Grid>
            <Grid md={3}>
              <Button
                onClick={() =>
                  getValues('fieldsOfLaw').length === FIELDS_OF_LAW_ENTRIES.length
                    ? setValue('fieldsOfLaw', [])
                    : // eslint-disable-next-line fp/no-mutating-methods
                      setValue('fieldsOfLaw', FIELDS_OF_LAW_ENTRIES.map(f => f.name).sort(sortFol))
                }
                variant="outlined"
                color="primary"
              >
                {fieldsOfLaw.length === FIELDS_OF_LAW_ENTRIES.length ? 'Alle entfernen' : 'Alle auswählen'}
              </Button>
            </Grid>
            {products.length > 0 && (
              <>
                <Grid sm={12} md={9}>
                  <Autocomplete
                    control={control}
                    multiple
                    name="products"
                    label="Produkte"
                    fullWidth
                    limitTags={5}
                    ChipProps={{ color: 'primary' }}
                    disabled={isLoading}
                    options={sortProducts(products, true)}
                    isOptionEqualToValue={isOptionEqual}
                    getOptionLabel={(p: { id: string; name: string }) => `${p.id} (${p.name})`}
                  />
                </Grid>
                <Grid sm={3}>
                  <Button
                    onClick={() =>
                      getValues('products').length === products.length
                        ? setValue('products', [])
                        : setValue('products', sortProducts(products).map(toProductType))
                    }
                    variant="outlined"
                    color="primary"
                  >
                    {selectedProducts.length === products.length ? 'Alle entfernen' : 'Alle auswählen'}
                  </Button>
                </Grid>
              </>
            )}
            {partnersResponse && (
              <>
                <Grid sm={12} md={9}>
                  <Autocomplete
                    multiple
                    control={control}
                    sx={{ sm: { marginTop: '1.5rem' }, md: { marginTop: 0 } }}
                    name="partners"
                    label="Partner"
                    sortSelected={sortPartner}
                    options={partnersResponse.data.partners.list}
                    getOptionLabel={p => (typeof p === 'object' ? p.name : p)}
                    isOptionEqualToValue={isOptionEqual}
                    fullWidth
                    limitTags={5}
                    ChipProps={{ color: 'primary' }}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid md={3}>
                  <Button
                    onClick={() =>
                      partners.length === partnersResponse.data.partners.list.length
                        ? setValue('partners', [])
                        : setValue('partners', partnersResponse.data.partners.list)
                    }
                    variant="outlined"
                    color="primary"
                  >
                    {partners.length === partnersResponse.data.partners.list.length ? 'Alle entfernen' : 'Alle auswählen'}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          <Box sx={{ marginTop: '3rem' }}>
            <Select control={control} name="weight" label="Gewichtung" disabled={isLoading} fullWidth>
              {Object.entries(ConfigWeight).map(w => (
                <option key={w[1]} value={w[1]}>
                  {weightMap[w[1]]?.toLocaleLowerCase() ?? w[1]}
                </option>
              ))}
            </Select>
          </Box>

          <FormActionBox>
            {configurationId && (
              <DeleteWithConfirmation
                actionButtonText="Konfiguration löschen"
                onConfirm={async () => {
                  setValue('deleted', true)
                  onSubmit(getValues())
                }}
              >
                <Typography>Diese Aktion ist nicht umkehrbar. Konfiguration wird permanent gelöscht.</Typography>
              </DeleteWithConfirmation>
            )}
            <AbortButton onClick={toOverview} />
            <SaveButton form="configuration" />
            <SaveButton
              submit={false}
              onClick={() => {
                onSubmit(getValues()).then(toOverview)
              }}
            >
              Speichern und Schließen
            </SaveButton>
          </FormActionBox>
        </Box>
      )}
    </PageLayout>
  )
}
