import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material'
import { remove } from 'ramda'
import React, { memo, FunctionComponent, useState, useCallback, useMemo } from 'react'
import { Controller, FieldError } from 'react-hook-form'

import { ControlledFormField } from 'common/interfaces'

export type Option = {
  id: string
  label: string
}

type SubListProps = {
  elements: Array<Option>
  checked: Array<string>
  onToggle: (id: string) => void
  onFocus: (focused: boolean) => void
  maxHeight: number
}

const SubList: FunctionComponent<SubListProps> = props => {
  const { elements, checked, onToggle, onFocus, maxHeight } = props
  return (
    <Paper sx={{ overflow: 'auto', height: `${maxHeight}px` }} tabIndex={-1}>
      <List>
        {elements.map(option => {
          const labelId = `list-label-${option.id}`
          return (
            <ListItem
              key={option.id}
              button
              onClick={() => onToggle(option.id)}
              onFocus={() => onFocus(true)}
              onBlur={() => onFocus(false)}
            >
              <ListItemIcon>
                <Checkbox
                  tabIndex={-1}
                  checked={checked.includes(option.id)}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} secondary={option.label} />
            </ListItem>
          )
        })}
      </List>
    </Paper>
  )
}

const MemoizedSubList = memo(SubList)

type TransferListProps = {
  label: string
  options: Array<Option>
  value: Array<string>
  error?: FieldError
  onChange: (newOptions: Array<string>) => void
  className?: string
  maxHeight?: number
}

export const TransferList: FunctionComponent<TransferListProps> = ({
  value,
  options,
  onChange,
  label,
  error,
  maxHeight = 300,
}) => {
  const [focused, setFocused] = useState(false)
  const [checkedOptionIds, setCheckedOptionIds] = useState<Array<string>>([])

  // Make sure not to change options for SubList on every render.
  const selectedOptions = useMemo(() => options.filter(option => value.includes(option.id)), [options, value])
  const notSelectedOptions = useMemo(
    () => options.filter(option => !selectedOptions.map(o => o.id).includes(option.id)),
    [options, selectedOptions]
  )

  // Used for (un)checking the checkboxes
  const onToggle = useCallback(
    (id: string): void => {
      setCheckedOptionIds(oldList =>
        checkedOptionIds.includes(id) ? remove(oldList.indexOf(id), 1, oldList) : oldList.concat(id)
      )
    },
    [checkedOptionIds]
  )

  // Changes overall input value in formik: Adding options
  const moveCheckedToRight = (): void => {
    const leftCheckedIds = notSelectedOptions.filter(option => checkedOptionIds.includes(option.id)).map(o => o.id)
    const newValue = selectedOptions.map(o => o.id).concat(leftCheckedIds)
    onChange(newValue)
    setCheckedOptionIds(oldList => oldList.filter(id => !leftCheckedIds.includes(id)))
  }

  // Changes overall input value in formik: Removing options
  const moveCheckedToLeft = (): void => {
    const rightCheckedIds = selectedOptions.filter(option => checkedOptionIds.includes(option.id)).map(o => o.id)
    const newValue = selectedOptions.filter(option => !checkedOptionIds.includes(option.id)).map(o => o.id)
    onChange(newValue)
    setCheckedOptionIds(oldList => oldList.filter(id => !rightCheckedIds.includes(id)))
  }

  return (
    <FormControl component="fieldset" error={Boolean(error)} focused={focused} fullWidth>
      <FormLabel component="legend">{label}</FormLabel>
      <Grid container spacing={2} sx={{ maxHeight: `${maxHeight}`, flexWrap: 'nowrap' }}>
        <Grid item xs={5}>
          <MemoizedSubList
            elements={notSelectedOptions}
            checked={checkedOptionIds}
            onToggle={onToggle}
            onFocus={setFocused}
            maxHeight={maxHeight}
          />
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center" sx={{ height: `${maxHeight}`, justifyContent: 'center' }}>
            <Grid item>
              <Button variant="outlined" onClick={moveCheckedToRight}>
                &gt;
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={moveCheckedToLeft}
                sx={theme => ({
                  margin: theme.spacing(1, 0),
                })}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <MemoizedSubList
            elements={selectedOptions}
            checked={checkedOptionIds}
            onToggle={onToggle}
            onFocus={setFocused}
            maxHeight={maxHeight}
          />
        </Grid>
      </Grid>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}

export const ControlledTransferList: ControlledFormField<Omit<TransferListProps, 'value' | 'onChange'>> = props => {
  const { label, name, control, options, maxHeight } = props
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...restField }, fieldState: { error } }) => (
        <TransferList {...restField} maxHeight={maxHeight} options={options} label={label} error={error} />
      )}
    />
  )
}
