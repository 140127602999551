import { zodResolver } from '@hookform/resolvers/zod'
import LockIcon from '@mui/icons-material/Lock'
import { Box, InputAdornment, Stack, Typography, Button } from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { FC, useState } from 'react'
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import SVGKlugoLogo from 'common/icons/klugo-logo-original.svg'
import { resetPasswordMutation } from 'packages/userauth/actions/resetPassword'

import SVGExit from '../assets/images/exit.svg'
import SVGConfirmation from '../assets/images/icon_bestätigung.svg'
import { Link, LoginTextField, SigninBox } from '../components'
import { passwordResetSchema } from '../constants'
import { PasswordResetFormFields } from '../interfaces'

export const PasswordReset: FC = () => {
  const history = useHistory()
  const theme = useTheme()
  const { control, handleSubmit } = useForm<PasswordResetFormFields>({
    defaultValues: { email: '' },
    resolver: zodResolver(passwordResetSchema),
  })

  const [errorMessage, setErrorMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)

  const resetErrorMessage = (): void => {
    setErrorMessage('')
  }

  const handleCancel = (): void => {
    // eslint-disable-next-line fp/no-mutating-methods
    history.push('/login')
  }

  const handleRedirect = (): void => {
    setIsSuccess(false)
  }

  const handleFieldError: SubmitErrorHandler<PasswordResetFormFields> = (error): void => {
    setErrorMessage(error.email?.message || '')
  }

  const sendPassword: SubmitHandler<PasswordResetFormFields> = data => {
    resetPasswordMutation(data.email, `${window.location.origin}/password-set/`)
      .then(() => {
        setIsSuccess(true)
      })
      .catch(error => {
        setErrorMessage(error.message)
        throw new Error(error)
      })
  }

  return (
    <SigninBox>
      <Stack alignItems="center">
        <SVGKlugoLogo width="124" height="40" />
        <Typography variant="loginTitle">Passwortwiederherstellung</Typography>
      </Stack>
      {isSuccess ? (
        <Stack textAlign="center" className="login-body">
          <SVGConfirmation height="80" width="80" style={{ margin: theme.spacing(0, 'auto') }} />
          <Typography variant="body2" color="green">
            Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen des Passworts zugeschickt.
          </Typography>
          <Typography sx={{ marginTop: theme.spacing(4.5) }} variant="body2" color="TextGray">
            Sie haben keine E-Mail erhalten?
          </Typography>
          <Button variant="loginContained" fullWidth sx={{ margin: theme.spacing(4, 0, 2) }} onClick={handleRedirect}>
            Erneut abschicken
          </Button>
          <Link to="/login">Zurück zum Login</Link>
        </Stack>
      ) : (
        <Box component="form" noValidate textAlign="center" onSubmit={handleSubmit(sendPassword, handleFieldError)}>
          <Typography m={theme.spacing(2.5, 0)} variant="body2" color="GrayText" textAlign="center">
            Bitte geben Sie Ihre E-Mail-Adresse an, um Ihr Passwort zurückzusetzen.
          </Typography>
          <LoginTextField
            control={control}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
            onChange={resetErrorMessage}
            placeholder="E-Mail-Adresse"
            type="email"
            name="email"
            error={!!errorMessage}
          />

          {errorMessage && (
            <Typography color="error" sx={{ marginTop: theme.spacing(1) }} variant="subtitle2" fontWeight={400}>
              {errorMessage}
            </Typography>
          )}

          <Button variant="loginContained" fullWidth type="submit" sx={{ marginTop: theme.spacing(5) }}>
            Abschicken
          </Button>

          <Button
            variant="loginContained"
            sx={{ marginTop: theme.spacing(2) }}
            type="button"
            onClick={handleCancel}
            fullWidth
            color="secondary"
            startIcon={<SVGExit height="14" width="14" />}
          >
            Abbrechen
          </Button>
        </Box>
      )}
    </SigninBox>
  )
}
