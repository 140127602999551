import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/finishMatching.graphql'
import { AdviceRequest } from '../interfaces'
import { FinishMatchingMutation, FinishMatchingMutationVariables } from '../interfaces/schemaDefinition'

const partnerFields = [
  'damageNumber',
  'deductible',
  'deductibleAmount',
  'protection',
  'protectionAmount',
  'disputeAmount',
  'internalPartnerNotes',
]

export const finishMatching = async (adviceRequest: AdviceRequest) => {
  const variables: FinishMatchingMutationVariables = {
    finishMatching: {
      id: adviceRequest.id,
      fieldOfLawId: adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.id : '',
      language: adviceRequest.language ? adviceRequest.language.code : 'de',
      person: {
        foa: adviceRequest.foa,
        firstname: adviceRequest.firstname,
        lastname: adviceRequest.lastname,
        email: adviceRequest.email,
        phone: adviceRequest.phone,
        address: {
          street: adviceRequest.street,
          streetNr: adviceRequest.streetNr,
          city: adviceRequest.city,
          zip: adviceRequest.zip,
        },
      },
      company: {
        name: adviceRequest.companyName,
        companyType: adviceRequest.companyCompanyType,
        taxId: adviceRequest.companyTaxId,
        address: {
          street: adviceRequest.companyAddressStreet,
          streetNr: adviceRequest.companyAddressStreetNr,
          city: adviceRequest.companyAddressCity,
          zip: adviceRequest.companyAddressZip,
        },
      },
      agbsAccepted: adviceRequest.agbsAccepted,
      dataAccepted: adviceRequest.dataAccepted,
      insured: adviceRequest.insured,
      legalProtectionInsurer: adviceRequest.legalProtectionInsurer,
      insuranceNumber: adviceRequest.insuranceNumber ? adviceRequest.insuranceNumber.replace(/\s|_/g, '') : undefined,
      description: adviceRequest.description,
      internalNotes: adviceRequest.internalNotes,
      externalNotes: adviceRequest.externalNotes,
      chancelleryId: adviceRequest.chancelleryId,
      contactType: adviceRequest.contactType,
      preferredCallbackTime: adviceRequest.preferredCallbackTime ? new Date(adviceRequest.preferredCallbackTime) : undefined,
      partnerId: adviceRequest.partner ? adviceRequest.partner.id : 'klugo',
      caseData: partnerFields.map(field => ({
        name: field,
        value: (adviceRequest as any)[field],
      })),
    },
  }

  const response = await request<FinishMatchingMutation, FinishMatchingMutationVariables>(
    ADVICE_REQUESTS_ENDPOINT,
    query,
    variables
  )
  return response
}
