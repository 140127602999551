import { TextField as MuiTextField, TextFieldProps } from '@mui/material'
import React, { ForwardRefRenderFunction, forwardRef } from 'react'
import { Controller } from 'react-hook-form'

import { ControlledFormField } from 'common/interfaces'

const StyledTextField: ForwardRefRenderFunction<HTMLDivElement, TextFieldProps> = (props, ref) => (
  <MuiTextField variant="outlined" {...props} ref={ref} />
)
const ForwardedStyledTextField = forwardRef(StyledTextField)

const TextField: ControlledFormField<TextFieldProps, { hideErrorMessage?: boolean }> = props => {
  // eslint-disable-next-line fp/no-rest-parameters
  const { control, name, onChange, hideErrorMessage = false, ...rest } = props

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <ForwardedStyledTextField
          {...rest}
          {...field}
          error={fieldState.invalid}
          helperText={!hideErrorMessage && fieldState.error?.message}
          name={name}
        />
      )}
    />
  )
}

export { ForwardedStyledTextField as StyledTextField, TextField }
