import { Role } from 'common/api/types'
import { BadgeProps } from 'common/components-mui'
import { ROLES, ROLE_IDS } from 'common/constants'
import { AdviceRequestStatus } from 'packages/chancellery-search/interfaces/AdviceRequest'

import { StatusTextEntry } from '../interfaces'

export interface AdviceListStatusProps {
  role?: Role
  status: AdviceRequestStatus
}

/* Role-Id -> status -> label text */
export const STATUS_TEXT: { [key: string]: StatusTextEntry } = {
  [ROLES[ROLE_IDS.Administrator].id]: {
    new: 'Neu',
    awaiting_call: 'Erwarte Anruf',
    awaiting_callback: 'Erwarte Rückruf',
    resubmission: 'Terminerinnerung',
    editing: 'In Bearbeitung',
    cancelled: 'Storniert',
    at_chancellery: 'Offen',
    potential: 'Wiedervorlage',
    mandate: 'Mandat',
    processing: 'In Bearbeitung',
    closed: 'Geschlossen',
    complete: 'Abgelegt',
  },
  [ROLES[ROLE_IDS.Employee].id]: {
    new: 'Neu',
    awaiting_call: 'Erwarte Anruf',
    awaiting_callback: 'Erwarte Rückruf',
    resubmission: 'Terminerinnerung',
    editing: 'In Bearbeitung',
    cancelled: 'Storniert',
    at_chancellery: 'Offen',
    potential: 'Wiedervorlage',
    mandate: 'Mandat',
    processing: 'In Bearbeitung',
    closed: 'Geschlossen',
    complete: 'Abgelegt',
  },
  [ROLES[ROLE_IDS.Lawyer].id]: {
    at_chancellery: 'Offen',
    awaiting_callback: 'Erwarte Anruf',
    potential: 'Wiedervorlage',
    mandate: 'Mandat',
    processing: 'In Bearbeitung',
    closed: 'Geschlossen',
    complete: 'Abgelegt',
  },
  [ROLES[ROLE_IDS.Callcenter].id]: {
    new: 'Neu',
    editing: 'In Bearbeitung',
    awaiting_call: 'Erwarte Anruf',
    awaiting_callback: 'Erwarte Rückruf',
    resubmission: 'Terminerinnerung',
    cancelled: 'Storniert',
    at_chancellery: 'Zugeordnet',
    potential: 'Zugeordnet',
    mandate: 'Zugeordnet',
    processing: 'Zugeordnet',
    closed: 'Zugeordnet',
    complete: 'Zugeordnet',
  },
  [ROLES[ROLE_IDS.TRB].id]: {
    new: 'Neu',
    resubmission: 'Wiedervorlage',
    editing: 'In Bearbeitung',
    cancelled: 'Storniert',
    awaiting_callback: 'Erwarte Rückruf',
    at_chancellery: 'Zugeordnet',
    potential: 'Zugeordnet',
    mandate: 'Zugeordnet',
    processing: 'Zugeordnet',
    closed: 'Geschlossen',
    complete: 'Zugeordnet',
  },
  [ROLES[ROLE_IDS.TRBManager].id]: {
    new: 'Neu',
    resubmission: 'Terminerinnerung',
    editing: 'In Bearbeitung',
    cancelled: 'Storniert',
    awaiting_callback: 'Erwarte Rückruf',
    at_chancellery: 'Zugeordnet',
    potential: 'Zugeordnet',
    mandate: 'Zugeordnet',
    processing: 'Zugeordnet',
    closed: 'Geschlossen',
    complete: 'Zugeordnet',
  },
  [ROLES[ROLE_IDS.Channel].id]: {
    new: 'Neu',
    resubmission: 'Wiedervorlage',
    editing: 'In Bearbeitung',
    cancelled: 'Storniert',
    at_chancellery: 'Zugeordnet',
    awaiting_callback: 'Zugeordnet',
    potential: 'Zugeordnet',
    mandate: 'Zugeordnet',
    processing: 'Zugeordnet',
    closed: 'Geschlossen',
    complete: 'Zugeordnet',
  },
}

export const STATUS_COLORS: { [key: string]: BadgeProps['color'] } = {
  new: 'warning',
  awaiting_call: 'warning',
  awaiting_callback: 'info',
  resubmission: 'info',
  editing: 'warning',
  cancelled: 'default',
  at_chancellery: 'success',
  processing: 'success',
  potential: 'success',
  mandate: 'success',
  closed: 'success',
  complete: 'success',
}
