import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton } from '@mui/material'
import React, { FC } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { AddButton } from 'common/components-mui'
import { Autocomplete, TextField } from 'common/components-mui/react-hook-form'
import { FieldOfLaw } from 'common/graphql/schemaDefinition'

import { chancelleryInitialValues } from '../interfaces/formSchemas'
import { getNewOrderVolumeEntry, getRemainingFols } from '../utils'

import { InputLegend } from './InputLegend'

export const OrderVolumeSetting: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { control, watch } = useFormContext<typeof chancelleryInitialValues>()
  const orderVolumes = watch('orderVolumes')
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'orderVolumes',
  })
  const getFolOptions = (index: number): Array<FieldOfLaw> => getRemainingFols(orderVolumes, orderVolumes[index].fieldOfLaw)
  return (
    <>
      <InputLegend>Auftragsvolumen</InputLegend>
      <Box mt={3}>
        {fields.map((field, index) => (
          <Box key={field.id} display="flex" flexWrap="wrap" justifyContent="space-between" gap={3} mb={3}>
            <Autocomplete
              label="Rechtsbegiet"
              options={getFolOptions(index)}
              control={control}
              name={`orderVolumes.${index}.fieldOfLaw`}
              getOptionLabel={f => (typeof f === 'string' ? f : f.name)}
              isOptionEqualToValue={
                (option, optionValue) =>
                  option.id === optionValue.id && option.name === optionValue.name /* Default is strict equality */
              }
              fullWidth
              disabled={disabled}
            />
            <TextField control={control} name={`orderVolumes.${index}.weeklyMin`} disabled={disabled} label="Wöchentlich min" />
            <TextField control={control} name={`orderVolumes.${index}.weeklyMax`} disabled={disabled} label="Wöchentlich max" />
            <Box mt={0.5}>
              <IconButton onClick={() => remove(index)} disabled={disabled} size="large">
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        <Box mb={3}>
          <AddButton
            aria-label="Auftragsvolumen hinzufügen"
            disabled={disabled}
            onClick={() => append(getNewOrderVolumeEntry())}
          />
        </Box>
      </Box>
    </>
  )
}
