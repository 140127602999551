import { CircularProgress } from '@mui/material'
import React, { ChangeEventHandler, FC, useEffect, useState } from 'react'
import { Button } from 'reactstrap'

import { DamageNumberField } from 'common/components/Devk'
import { CheckboxField, InputFieldProps } from 'common/components/Form'
import { checkDamageNumber } from 'packages/chancellery-search/actions'
import { CheckDamageNumberQuery } from 'packages/chancellery-search/interfaces/schemaDefinition'

type DamageNumberinputProps = {
  hasWebserviceAccess: boolean
  damageNumberDisabled?: boolean
  damageNumber: string
  error?: string
  maskOptions?: InputFieldProps['maskOptions']
  onFieldChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCheckboxChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCaseDataChange: (
    vnr: string,
    disputeAmount: string,
    deductible: boolean,
    deductibleAmount: string,
    protection: boolean,
    protectionAmount: Array<string>
  ) => void
}

/**
 * The key is a combination of three boolean values converted to a number. These
 * values have a fixed position in the key:
 *
 * `consultation extrajudical firstInstance`
 */
enum ProtectionAmount {
  FIRST_INSTANCE = 'nur I. Instanz',
  EXTRAJUDICAL = 'Nur die außergerichtliche Tätigkeit',
  CONSULTATION = 'nur eine Beratung',
}

type DamageNumberResponse = CheckDamageNumberQuery['checkDamageNumber']
type ProtectionAmountOptions = Pick<DamageNumberResponse, 'consultation' | 'extrajudicial' | 'firstInstance'>

const getProtectionAmount = (amount: ProtectionAmountOptions): Array<string> =>
  [
    amount.consultation ? ProtectionAmount.CONSULTATION : '',
    amount.extrajudicial ? ProtectionAmount.EXTRAJUDICAL : '',
    amount.firstInstance ? ProtectionAmount.FIRST_INSTANCE : '',
  ].filter(Boolean)

export const DamageNumberInput: FC<DamageNumberinputProps> = ({
  onFieldChange,
  onCheckboxChange,
  onCaseDataChange,
  hasWebserviceAccess,
  error,
  damageNumber,
  damageNumberDisabled,
  maskOptions,
}) => {
  const [loading, setLoading] = useState(false)
  const [badFetch, setBadFetch] = useState('')
  const [caseData, setCaseData] = useState<null | DamageNumberResponse>(null)

  const showTakeDataButton = !damageNumberDisabled && hasWebserviceAccess && caseData && !error

  useEffect(() => {
    if (damageNumberDisabled) {
      setCaseData(null)
    }
  }, [damageNumberDisabled])

  const onTakeData = (): void => {
    if (!caseData) return
    const protectionAmount = getProtectionAmount(caseData)

    const protection = !!protectionAmount
    const deductible = !!parseInt(caseData.deductibleAmount ?? '0', 10)
    onCaseDataChange(
      caseData.vnr ?? '',
      caseData.deductibleAmount?.replace('.', ',') ?? '',
      deductible,
      caseData.deductibleAmount?.replace('.', ',') ?? '',
      protection,
      protectionAmount
    )
  }

  const onDamageNumberChange: ChangeEventHandler<HTMLInputElement> = async event => {
    onFieldChange(event)
    const cleanedDamageNumber = event.target.value.replace(/\D/g, '')
    const getDamageData = hasWebserviceAccess && cleanedDamageNumber.length === 14

    if (!getDamageData) {
      setCaseData(null)
      return
    }

    setBadFetch('')
    setLoading(true)

    try {
      const response = await checkDamageNumber(cleanedDamageNumber)

      if (!response.exists) {
        setBadFetch('Es existieren keine Daten zu dieser Schadennummer')
      } else {
        setCaseData(response)
      }
    } catch (_e) {
      setBadFetch('Es konnten keine Daten abgerufen werden')
    }

    setLoading(false)
  }

  return (
    <>
      <DamageNumberField
        label="Schadensnummer"
        disabled={damageNumberDisabled}
        value={damageNumber}
        maskOptions={maskOptions}
        onChange={onDamageNumberChange}
        name="damageNumber"
        id="input-damageNumber"
        error={badFetch || error}
        width="24rem"
      />
      {loading && <CircularProgress style={{ marginTop: '2rem', marginLeft: '-3rem', marginRight: '1.5rem' }} size={24} />}
      {showTakeDataButton && (
        <Button onClick={onTakeData} style={{ marginTop: 'auto' }}>
          Daten Übernehmen
        </Button>
      )}
      <CheckboxField
        className="padding-top-1-5rem"
        onChange={onCheckboxChange}
        checked={!!damageNumberDisabled}
        name="damageNumberDisabled"
        id="checkbox-damageNumberDisabled"
        label="Keine Schadensnummer vorhanden"
      />
    </>
  )
}
