import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack, Typography } from '@mui/material'
import { OptionsObject } from 'notistack'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import { DeleteWithConfirmation } from 'common/components-mui'
import { TextField } from 'common/components-mui/react-hook-form'
import { enqueueSnackbar } from 'common/utils'

import { deleteCustomerData } from '../actions'
import { adviceIdValidationSchema } from '../constants'

const defaultValues = {
  adviceId: '',
}

const handleDeleteCustomerData = async (adviceId: string): Promise<void> => {
  const result = await deleteCustomerData(adviceId)
  const isSuccess = result.deleteCustomerData.success

  const options: OptionsObject = { variant: isSuccess ? 'success' : 'error' }
  const message = isSuccess
    ? `Kundendaten von Fall-ID ${adviceId} wurden erfolgreich gelöscht.`
    : `Fall-ID ${adviceId} konnte nicht gefunden werden.`

  enqueueSnackbar(message, options)
}

export const DeleteCustomerData: FC = () => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    watch,
    control,
    getValues,
    reset,
    formState: { isValid },
  } = useForm({
    defaultValues,
    resolver: zodResolver(adviceIdValidationSchema),
  })

  const handleConfirm = async (): Promise<void> => {
    const adviceId = getValues('adviceId')
    setIsSubmitting(true)
    try {
      await handleDeleteCustomerData(adviceId)
    } catch (error) {
      enqueueSnackbar(`Systemfehler`, {
        variant: 'error',
      })
    }
    reset(defaultValues)
    setIsSubmitting(false)
  }

  const watchedId = watch('adviceId')
  return (
    <Stack spacing={3}>
      <TextField control={control} name="adviceId" label="Fall-ID" disabled={isSubmitting} />
      <Box>
        <DeleteWithConfirmation
          actionButtonText="Kundendaten löschen"
          disabled={!watchedId || !isValid}
          overrideOpenState={{ open: deleteConfirmOpen, setOpen: setDeleteConfirmOpen }}
          onConfirm={handleConfirm}
        >
          <Box>
            <Typography>
              Diese Aktion ist nicht umkehrbar!
              <br /> Folgende Kundendaten vom Fall <b>{watchedId}</b> werden permanent gelöscht:
            </Typography>
            <Box component="ul" sx={{ li: { typography: 'body1' }, mt: 2 }}>
              <li>Vorname</li>
              <li>Nachname</li>
              <li>Telefonnummer</li>
              <li>E-Mail-Adresse</li>
              <li>Stadt</li>
              <li>Straße</li>
              <li>Hausnummer</li>
              <li>Fallbeschreibung</li>
            </Box>
          </Box>
        </DeleteWithConfirmation>
      </Box>
    </Stack>
  )
}
