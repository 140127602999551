import { CheckboxProps, FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

import { ControlledFormField } from 'common/interfaces'

export const Checkbox: ControlledFormField<CheckboxProps, { label: string }> = props => {
  const { name, control, label, disabled } = props

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          disabled={disabled}
          {...field}
          checked={field.value}
          control={<MuiCheckbox name={name} color="primary" />}
        />
      )}
    />
  )
}
