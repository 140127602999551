import { Theme } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'

export const createTypography: (theme: Theme) => TypographyOptions = theme => ({
  fontFamily: 'Poppins, Arial',
  loginTitle: {
    ...theme.typography.h6,
    fontFamily: 'Poppins, Arial',
    letterSpacing: 0.7,
    fontWeight: 400,
    color: theme.palette.grey[600],
    marginTop: theme.spacing(2),
  },
})
