import { HistoryEventType } from 'common/graphql/schemaDefinition'

import { AdviceRequest, AdviceRequestResponse } from '../interfaces'

const mapCaseData = (response: AdviceRequestResponse) => {
  if (!response.caseData) {
    return {}
  }
  return response.caseData.reduce(
    (caseData: any, field: any) => ({
      ...caseData,
      [field.name]: field.value,
    }),
    {}
  )
}
const getConfirmedDate = (response: AdviceRequestResponse): Date | undefined => {
  const event = response.history.find(event => event.type === HistoryEventType.AdviceConfirmed)
  return event ? new Date(event.date) : undefined
}

const DEFAULT_CASE_DATA = {
  damageNumber: '',
  deductible: false,
  deductibleAmount: '',
  disputeAmount: '',
  protection: false,
  protectionAmount: '',
  internalPartnerNotes: '',
}

// tslint:disable-next-line:cyclomatic-complexity
// eslint-disable-next-line complexity
export const mapResponseToAdviceRequest: (response: AdviceRequestResponse) => AdviceRequest = response => ({
  ...DEFAULT_CASE_DATA,
  id: response.id,
  createdAt: new Date(response.createdAt),
  reminderDate: new Date(),
  foa: response.person.foa || '',
  firstname: response.person.firstname || '',
  lastname: response.person.lastname || '',
  phone: response.person.phone,
  email: response.person.email,
  zip: response.person.address.zip,
  street: response.person.address.street || '',
  streetNr: response.person.address.streetNr || '',
  city: response.person.address.city || '',
  description: response.description || '',
  externalNotes: response.externalNotes || '',
  internalNotes: response.internalNotes || '',
  company: response.company,
  billingCompany: response.billingCompany,
  adviceId: response.adviceId,
  fieldOfLaw: response.fieldOfLaw,
  contactType: response.contactType,
  chancellery: response.chancellery,
  status: response.status,
  agbsAccepted: !!response.agbsAccepted,
  dataAccepted: !!response.dataAccepted,
  insured: !!response.insured,
  legalProtectionInsurer: response.legalProtectionInsurer || '',
  insuranceNumber: response.insuranceNumber || '',
  newsletter: !!response.newsletter,
  partner: response.partner ? response.partner : '',
  resultNotes: response.resultNotes || '',
  lawyerNotes: response.lawyerNotes || '',
  mandateName: response.mandateName || '',
  disputeAmount: response.disputeAmount || '',
  revenue: response.revenue,
  preferredCallbackTime: response.preferredCallbackTime ? new Date(response.preferredCallbackTime) : '',
  files: response.files ? response.files : [],
  lockedBy: response.lockedBy,
  history: response.history || [],
  product: response.product || {
    id: 'premium-basic',
  },
  assignedTo: response.assignedTo,
  proposal: response.proposal,
  payment: response.payment,
  adviceConfirmed: getConfirmedDate(response),
  ...mapCaseData(response),
})
