import { formatCurrency, isNotEmpty } from '@grapes-agency/universal'
import { Field as FormikField, Formik } from 'formik'
import { omit } from 'ramda'
import React, { FC, useState, useEffect, ReactNode } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { CheckboxField, InputField, TextAreaField } from 'common/components/Form'
import { Spinner } from 'common/components/Spinner'
import { URLs } from 'common/constants'
import { FIELDS_OF_LAW } from 'common/constants/fieldsOfLaw'
import { Button, Panel } from 'common/ui'
import { dateToLabel } from 'common/utils'

import { CustomSelect } from '../../../common/components/CustomSelect'
import { CustomFieldArray } from '../components/CustomFieldArray'
import { FormattedList } from '../components/FormattedList'
import { Status } from '../components/Status'
import { TooltipLabel } from '../components/TooltipLabel'
import { PRODUCT_CATEGORIES, PRODUCT_CONTACT_TYPES, PRODUCT_TYPES } from '../constants/mappings'
import { PRODUCT_TOOLTIPS } from '../constants/tooltips'
import { isProductIdAvailable } from '../graphql/isProductIdAvailable'
import { getProduct } from '../graphql/product'
import { saveProduct } from '../graphql/saveProduct'
import { Product, ProductType } from '../interfaces/schemaDefinition'
import { isKebabCase, isValidPrice, MapEntries, MapEntry, MapToOptions, sleep } from '../utils'

import styles from './ProductBusinessContainer.module.scss'

interface Field {
  label: ReactNode
  node: ReactNode
}

interface Navigation {
  id: string
  name: string
  active: boolean
  variants?: Array<Navigation>
}

type FomikProduct = Omit<Product, 'variants'>

const initialProduct: FomikProduct = {
  id: '',
  parentId: '',
  name: '',
  type: ProductType.Business,
  title: '',
  subtitle: '',
  slug: '',
  contactTypes: [],
  labelPrice: 0,
  price: 0,
  netto: 0,
  tax: 0,
  active: false,
  firstActive: undefined,
  fieldsOfLaw: [],
  features: [],
  secondaryFeatures: [],
  uniqueSellingPoints: [],
  description: '',
  shortDescription: '',
  categories: [],
  createdBy: {
    id: '',
    name: '',
  },
  services: [],
  image: '',
  metaDescription: '',
  metaTitle: '',
}

export const ProductBusinessContainer: FC = () => {
  const [currentPathname, setCurrentPathname] = useState<string>('')
  const [navigation, setNavigation] = useState<Navigation>({ id: '', name: '', active: false })
  const [product, setProduct] = useState<FomikProduct>(initialProduct)
  const [formikProduct, setFormikProduct] = useState<FomikProduct>(initialProduct)
  const [loading, setLoading] = useState<boolean>(true)
  const [mode, setMode] = useState<'view' | 'edit' | 'create'>('view')
  const [errors, setErrors] = useState<{ [prop: string]: string }>({})

  const history = useHistory()
  const productPath = history.location.pathname.replace(`${URLs.productBusiness.home}/`, '').split('/')
  const productVariant = productPath.length > 1

  // force revalidation on url change
  if (currentPathname !== history.location.pathname) {
    window.scrollTo({ top: 0 })
    setLoading(true)
    setMode('view')
    setCurrentPathname(history.location.pathname)
  }

  useEffect(() => {
    const fetchProduct: () => void = async () => {
      try {
        const res = await getProduct(productPath[0])

        // create new product variant
        if (productPath[productPath.length - 1] === 'new') {
          const newVariant = {
            ...initialProduct,
            ...omit(['variants'], res),
            ...{ id: `${res.id}-variant-${res.variants.length + 1}`, parentId: res.id },
          }
          setProduct(newVariant)
          setFormikProduct(newVariant)
          setMode('create')
        } else {
          setNavigation(res)

          // view/edit product variant
          if (productVariant) {
            const childProduct = {
              ...initialProduct,
              ...omit(
                ['variants'],
                res.variants.find((variant: Product) => variant.id === productPath[1])
              ),
            }
            setProduct(childProduct)
            setFormikProduct(childProduct)
          }

          // view/edit main product
          else {
            const parentProduct = { ...initialProduct, ...omit(['variants'], res) }
            setProduct(parentProduct)
            setFormikProduct(parentProduct)
          }
        }
        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    }

    // create new main product
    if (productPath[0] === 'new') {
      setProduct(initialProduct)
      setFormikProduct(initialProduct)
      setMode('create')
      setLoading(false)
    } else if (productPath.length > 0 && loading) {
      fetchProduct()
    }
  }, [loading, productPath, productVariant])

  const productFields: Array<Field> = [
    { label: <TooltipLabel label="ID" tooltip={PRODUCT_TOOLTIPS.id} />, node: product.id },
    { label: <TooltipLabel label="Hauptprodukt" tooltip={PRODUCT_TOOLTIPS.parentId} />, node: product.parentId },
    { label: <TooltipLabel label="Name" tooltip={PRODUCT_TOOLTIPS.name} />, node: product.name },
    { label: <TooltipLabel label="Typ" tooltip={PRODUCT_TOOLTIPS.type} />, node: MapEntry(product.type, PRODUCT_TYPES) },
    { label: <TooltipLabel label="Titel" tooltip={PRODUCT_TOOLTIPS.title} />, node: product.title },
    { label: <TooltipLabel label="Untertitel" tooltip={PRODUCT_TOOLTIPS.subtitle} />, node: product.subtitle },
    { label: <TooltipLabel label="Slug" tooltip={PRODUCT_TOOLTIPS.slug} />, node: product.slug },
    {
      label: <TooltipLabel label="Verfügbare Kontaktarten" tooltip={PRODUCT_TOOLTIPS.contactTypes} />,
      node: MapEntries(product.contactTypes, PRODUCT_CONTACT_TYPES).join(', '),
    },
    {
      label: <TooltipLabel label="Fake-Preis" tooltip={PRODUCT_TOOLTIPS.labelPrice} />,
      node: formatCurrency({ unit: 'EUR' })(product.labelPrice),
    },
    {
      label: <TooltipLabel label="Brutto-Preis" tooltip={PRODUCT_TOOLTIPS.price} />,
      node: formatCurrency({ unit: 'EUR' })(product.price),
    },
    {
      label: <TooltipLabel label="Netto-Preis" tooltip={PRODUCT_TOOLTIPS.netto} />,
      node: formatCurrency({ unit: 'EUR' })(product.netto),
    },
    {
      label: <TooltipLabel label="Steuern" tooltip={PRODUCT_TOOLTIPS.tax} />,
      node: formatCurrency({ unit: 'EUR' })(product.tax),
    },
    { label: <TooltipLabel label="Status" tooltip={PRODUCT_TOOLTIPS.active} />, node: <Status status={product.active} /> },
    {
      label: <TooltipLabel label="Livegang" tooltip={PRODUCT_TOOLTIPS.firstActive} />,
      node: product.firstActive ? dateToLabel(new Date(product.firstActive)) : undefined,
    },
    {
      label: <TooltipLabel label="Rechtsgebiete" tooltip={PRODUCT_TOOLTIPS.fieldsOfLaw} />,
      node: product.fieldsOfLaw.join(', '),
    },
    {
      label: <TooltipLabel label="Features" tooltip={PRODUCT_TOOLTIPS.features} />,
      node: <FormattedList data={product.features} />,
    },
    {
      label: <TooltipLabel label="Erweiterte Features" tooltip={PRODUCT_TOOLTIPS.secondaryFeatures} />,
      node: <FormattedList data={product.secondaryFeatures} />,
    },
    {
      label: <TooltipLabel label="USPs" tooltip={PRODUCT_TOOLTIPS.uniqueSellingPoints} />,
      node: <FormattedList data={product.uniqueSellingPoints} />,
    },
    { label: <TooltipLabel label="Beschreibung" tooltip={PRODUCT_TOOLTIPS.description} />, node: product.description },
    {
      label: <TooltipLabel label="Kurzbeschreibung" tooltip={PRODUCT_TOOLTIPS.shortDescription} />,
      node: product.shortDescription,
    },
    {
      label: <TooltipLabel label="Kategorien" tooltip={PRODUCT_TOOLTIPS.categories} />,
      node: MapEntries(product.categories, PRODUCT_CATEGORIES).join(', '),
    },
    { label: <TooltipLabel label="Erstellt durch" tooltip={PRODUCT_TOOLTIPS.createdBy} />, node: product.createdBy.name },
    {
      label: <TooltipLabel label="Leistungen des Anwalts" tooltip={PRODUCT_TOOLTIPS.services} />,
      node: <FormattedList data={product.services} />,
    },
    { label: <TooltipLabel label="Produktbild" tooltip={PRODUCT_TOOLTIPS.image} />, node: product.image },
    {
      label: <TooltipLabel label="Meta Beschreibung" tooltip={PRODUCT_TOOLTIPS.metaDescription} />,
      node: product.metaDescription,
    },
    {
      label: <TooltipLabel label="Meta Titel" tooltip={PRODUCT_TOOLTIPS.metaTitle} />,
      node: product.metaTitle,
    },
  ]

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    handleSubmit: React.FormEventHandler<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()
    e.persist()
    handleSubmit(e)
  }

  const handleAbort = (): void => {
    if (mode === 'edit') {
      setMode('view')
      window.scrollTo({ top: 0 })
    } else if (mode === 'create') {
      if (productVariant) {
        // eslint-disable-next-line fp/no-mutating-methods
        history.push(`${URLs.productBusiness.home}/${productPath[0]}`)
      } else {
        // eslint-disable-next-line fp/no-mutating-methods
        history.push(URLs.productsBusiness.home)
      }
    }
  }

  const isExistingProduct = async (productId2: string): Promise<boolean> => {
    try {
      return !!(await isProductIdAvailable(productId2))
    } catch (err) {
      return false
    }
  }

  const handleEditMode = (): void => {
    setMode('edit')
    window.scrollTo({ top: 0 })
  }

  const validate = (values: Product): Promise<void> =>
    sleep(300).then(async () => {
      const { id, name, price, netto, labelPrice } = values
      const errors: { [prop: string]: string } = {}
      if (mode === 'create') {
        if (!isNotEmpty(id) || !isKebabCase(id) || id === 'new') {
          errors.id = 'Bitte geben Sie eine gültige ID an (kebab-case).'
        } else if (await isExistingProduct(id)) {
          errors.id = 'Diese Produkt-ID existiert bereits.'
        }
      }
      if (!isNotEmpty(name)) {
        errors.name = 'Bitte geben Sie einen Produktnamen an.'
      }
      if (product.type === ProductType.Consumer && !price) {
        errors.price = 'Bitte geben Sie einen Brutto-Preis an.'
      } else if (product.type === ProductType.Consumer && !isValidPrice(price)) {
        errors.price = 'Bitte geben Sie einen validen Brutto-Preis an.'
      }
      if (product.type === ProductType.Business && !netto) {
        errors.netto = 'Bitte geben Sie einen Netto-Preis an.'
      } else if (product.type === ProductType.Business && !isValidPrice(netto)) {
        errors.netto = 'Bitte geben Sie einen validen Netto-Preis an.'
      }
      if (!isValidPrice(labelPrice)) {
        errors.labelPrice = 'Bitte geben Sie einen validen Fake-Preis an.'
      }
      setErrors(errors)
    })

  return (
    <Panel>
      <Spinner condition={loading} center>
        <>
          {navigation.variants && mode === 'view' && (
            <div className={styles.navigation}>
              <ul>
                <Link to={`${URLs.productBusiness.home}/${navigation.id}`}>
                  <li>{navigation.name}</li>
                </Link>
                <ul>
                  {navigation.variants.map((variant: Navigation, index: number) => (
                    <Link to={`${URLs.productBusiness.home}/${navigation.id}/${variant.id}`} key={index}>
                      <li>{variant.name}</li>
                    </Link>
                  ))}
                  <Link to={`${URLs.productBusiness.home}/${navigation.id}/new`}>
                    <li style={{ marginTop: '1.25rem' }}> + Neue Variante</li>
                  </Link>
                </ul>
              </ul>
            </div>
          )}
          <h2 style={{ textAlign: 'center' }}>
            {mode === 'create'
              ? productVariant
                ? `Neue Variante für "${navigation.name}"`
                : 'Neues Business Produkt'
              : `${productVariant ? 'Variante' : 'Produkt'}: ${product.name}`}
          </h2>
          {mode === 'view' ? (
            <>
              <div className={styles.productView}>
                <div className={styles.fields}>
                  {productFields.map((field: Field, i: number) => (
                    <div key={i} className={styles.field}>
                      <div className={styles.label}>{field.label}</div>
                      <div className={styles.node}>
                        <div>{field.node || '-'}</div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={styles.productButtons}>
                  <Button onClick={() => handleEditMode()}>Bearbeiten</Button>
                </div>
              </div>
            </>
          ) : (
            <Formik
              initialValues={formikProduct}
              validate={validate}
              validateOnChange={false}
              onSubmit={async (values: Product) => {
                if (Object.keys(errors).length === 0) {
                  const apiProduct = omit(['createdBy', 'firstActive'], {
                    ...values,
                    createdBy: values.createdBy.id,
                    labelPrice: !!values.labelPrice ? parseFloat(values.labelPrice.toString()) : 0,
                    price: !!values.price ? parseFloat(values.price.toString()) : 0,
                    netto: !!values.netto ? parseFloat(values.netto.toString()) : 0,
                  })

                  if (product.type === ProductType.Consumer) {
                    await saveProduct(omit(['tax', 'netto'], apiProduct))
                  } else if (product.type === ProductType.Business) {
                    await saveProduct(omit(['tax', 'price'], apiProduct))
                  }
                  window.scrollTo({ top: 0 })
                  setLoading(true)
                  setMode('view')
                } else {
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }
              }}
            >
              {({ values, handleChange, handleSubmit, handleBlur }) => (
                <form onSubmit={e => onSubmit(e, handleSubmit)} className={styles.productForm}>
                  <div className={styles.activeWrapper}>
                    <CheckboxField id="active" name="active" label="Aktiviert" checked={values.active} onChange={handleChange} />
                  </div>
                  <div className={styles.mainForm}>
                    <div style={{ width: '40rem' }}>
                      {mode === 'create' && (
                        <div className={styles.formRow}>
                          <InputField
                            id="id"
                            name="id"
                            label="ID:"
                            value={values.id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.id}
                            required
                          />
                        </div>
                      )}
                      <div className={styles.formRow}>
                        <InputField
                          id="name"
                          name="name"
                          label="Name:"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.name}
                          required
                        />
                      </div>
                      <div className={styles.formRow}>
                        <InputField id="title" name="title" label="Titel:" value={values.title} onChange={handleChange} />
                      </div>
                      <div className={styles.formRow}>
                        <TextAreaField
                          id="subtitle"
                          name="subtitle"
                          label="Untertitel:"
                          value={values.subtitle}
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.formRow}>
                        <InputField id="slug" name="slug" label="Slug:" value={values.slug} onChange={handleChange} />
                      </div>
                      <div className={styles.formRow}>
                        <FormikField
                          name="contactTypes"
                          options={MapToOptions(PRODUCT_CONTACT_TYPES)}
                          component={CustomSelect}
                          placeholder="Kontaktarten auswählen..."
                          isMulti={true}
                          label="Verfügbare Kontaktarten:"
                        />
                      </div>
                      <div className={styles.formRow}>
                        <InputField
                          id="labelPrice"
                          name="labelPrice"
                          label="Fake-Preis:"
                          value={values.labelPrice.toString()}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.labelPrice}
                          required
                        />
                      </div>
                      {values.type === ProductType.Consumer ? (
                        <div className={styles.formRow}>
                          <InputField
                            id="price"
                            name="price"
                            label="Brutto-Preis:"
                            value={values.price.toString()}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.price}
                            required
                          />
                        </div>
                      ) : (
                        <div className={styles.formRow}>
                          <InputField
                            id="netto"
                            name="netto"
                            label="Netto-Preis:"
                            value={values.netto.toString()}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.netto}
                            required
                          />
                        </div>
                      )}

                      <div className={styles.formRow}>
                        <FormikField
                          name="fieldsOfLaw"
                          options={Object.keys(FIELDS_OF_LAW).map(key => ({ label: key, value: key }))}
                          component={CustomSelect}
                          placeholder="Rechtsgebiete auswählen..."
                          isMulti={true}
                          label="Rechtsgebiete:"
                        />
                      </div>
                      <div className={styles.formRow}>
                        <CustomFieldArray
                          values={values.features}
                          name="features"
                          label="Features:"
                          fallback="Feature hinzufügen:"
                          onChange={handleChange}
                          inputType="textarea"
                        />
                      </div>
                      <div className={styles.formRow}>
                        <CustomFieldArray
                          values={values.secondaryFeatures}
                          name="secondaryFeatures"
                          label="Erweiterte Features:"
                          fallback="Erweiterte Features hinzufügen:"
                          onChange={handleChange}
                          inputType="textarea"
                        />
                      </div>
                    </div>
                    <div style={{ width: '40rem' }}>
                      <div className={styles.formRow}>
                        <CustomFieldArray
                          values={values.uniqueSellingPoints}
                          name="uniqueSellingPoints"
                          label="USPs:"
                          fallback="USP hinzufügen:"
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.formRow}>
                        <TextAreaField
                          id="description"
                          name="description"
                          label="Beschreibung:"
                          value={values.description}
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.formRow}>
                        <TextAreaField
                          id="shortDescription"
                          name="shortDescription"
                          label="Kurzbeschreibung:"
                          value={values.shortDescription}
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.formRow}>
                        <FormikField
                          name="categories"
                          options={MapToOptions(PRODUCT_CATEGORIES)}
                          component={CustomSelect}
                          placeholder="Rechtsgebiete auswählen..."
                          isMulti={true}
                          label="Kategorien:"
                        />
                      </div>
                      <div className={styles.formRow}>
                        <CustomFieldArray
                          values={values.services}
                          name="services"
                          label="Leistungen des Anwalts:"
                          fallback="Leistung hinzufügen:"
                          onChange={handleChange}
                          inputType="textarea"
                        />
                      </div>
                      <div className={styles.formRow}>
                        <InputField id="image" name="image" label="Bildpfad:" value={values.image} onChange={handleChange} />
                      </div>
                      <div className={styles.formRow}>
                        <TextAreaField
                          id="metaDescription"
                          name="metaDescription"
                          label="Meta Beschreibung:"
                          value={values.metaDescription}
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.formRow}>
                        <InputField
                          id="metaTitle"
                          name="metaTitle"
                          label="Meta Titel:"
                          value={values.metaTitle}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.productButtons}>
                    <Button onClick={() => handleSubmit} type="submit">
                      {mode === 'edit' ? 'Speichern' : 'Erstellen'}
                    </Button>
                    <Button onClick={() => handleAbort()} type="reset">
                      Abbrechen
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </>
      </Spinner>
    </Panel>
  )
}
