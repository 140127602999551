import { Box } from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { ComponentProps, FC } from 'react'

export const SigninBox: FC<ComponentProps<typeof Box>> = props => {
  const theme = useTheme()
  const { sx } = props
  return (
    <Box
      {...props}
      sx={{
        position: 'absolute',
        backgroundColor: theme.palette.loginbg.main,
        maxWidth: '362px',
        width: '100%',
        minWidth: '350px',
        top: '50%',
        left: '50%',
        borderRadius: theme.spacing(2.5),
        boxShadow: '0 20px 25px rgba(0, 0, 0, 0.15)',
        transform: 'translate(-50%, -50%)',
        padding: theme.spacing(5),
        zIndex: theme.zIndex.mobileStepper,
        ...sx,
      }}
    />
  )
}
