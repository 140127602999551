import { FormControl, Input, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { FC } from 'react'

import { TextAreaField, SelectField, CheckboxField, FieldSet, FieldRow, InputField } from 'common/components/Form'
import { DEDUCTIBLE_AMOUNTS, PROTECTION_AMOUNTS } from 'common/constants'
import SVGCaseData from 'common/icons/CaseData.svg'
import { currencyFormatter } from 'common/utils'

import { AdviceRequest } from '../../../interfaces'

import { DamageNumberInput } from './DamageNumberInput'

interface DEVKFormProps {
  adviceRequest: AdviceRequest
  errors: { [key: string]: string }
  hasWebserviceAccess: boolean
  onFieldChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCheckboxChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onMultiSelectChange: (e: SelectChangeEvent<Array<string>>) => void
  onCaseDataChange: (
    vnr: string,
    disputeAmount: string,
    deductible: boolean,
    deductibleAmount: string,
    protection: boolean,
    protectionAmount: Array<string>
  ) => void
}

const EMPTY_OPTION = {
  label: '---Bitte wählen---',
  value: '',
}

export const DevkForm: FC<DEVKFormProps> = ({
  adviceRequest,
  onFieldChange,
  onCheckboxChange,
  onCaseDataChange,
  onMultiSelectChange,
  errors,
  hasWebserviceAccess,
}) => (
  <FieldSet className="devk-fieldset" label="DEVK Falldaten" icon={<SVGCaseData />}>
    <div className="devk-background">
      <FieldRow>
        <DamageNumberInput
          damageNumber={adviceRequest.damageNumber}
          damageNumberDisabled={adviceRequest.damageNumberDisabled}
          onFieldChange={onFieldChange}
          onCaseDataChange={onCaseDataChange}
          hasWebserviceAccess={hasWebserviceAccess}
          onCheckboxChange={onCheckboxChange}
        />
      </FieldRow>
      <FieldRow>
        <InputField
          label="Streitwert"
          onChange={onFieldChange}
          value={adviceRequest.disputeAmount}
          name="disputeAmount"
          id="disputeAmount"
          width="24rem"
        />
      </FieldRow>
      <FieldRow>
        <CheckboxField
          className="padding-top-1-5rem"
          onChange={onCheckboxChange}
          checked={!!adviceRequest.deductible}
          name="deductible"
          id="checkbox-deductible"
          label="Selbstbeteiligung"
          width="12.5rem"
        />
        <SelectField
          label="Höhe der Selbstbeteiligung"
          disabled={!adviceRequest.deductible}
          onChange={onFieldChange}
          value={adviceRequest ? adviceRequest.deductibleAmount : ''}
          name="deductibleAmount"
          id="deductibleAmount"
          error={errors.deductibleAmount}
          width="12.5rem"
          options={[
            EMPTY_OPTION,
            ...DEDUCTIBLE_AMOUNTS.map(v => ({
              value: v,
              label: currencyFormatter(v),
            })),
          ]}
        />
      </FieldRow>
      <FieldRow>
        <CheckboxField
          className="padding-top-1-5rem"
          onChange={onCheckboxChange}
          checked={adviceRequest.protection}
          name="protection"
          id="checkbox-protection"
          label="Kostenschutz"
          width="12.5rem"
        />
        <div>
          <InputLabel
            sx={{
              mx: 1.5,
              fontSize: '14px',
              fontWeight: 500,
              color: 'white',
            }}
            id="select-protectionAmount-label"
          >
            Umfang Kostenschutz
          </InputLabel>

          <FormControl disabled={!adviceRequest.protection} sx={{ minWidth: '15rem', mx: 1.5, fontSize: 2 }}>
            <Select
              id="select-protectionAmount"
              name="protectionAmount"
              sx={{
                backgroundColor: '#f7f7f9',
                pl: 2,
                py: 0.5,
                borderRadius: 1,
                maxWidth: '20rem',
                color: '#495057',
                '&.Mui-disabled': {
                  backgroundColor: '#e9ecef',
                },
                '&.Mui-disabled #select-protectionAmount': {
                  color: '#495057',
                  WebkitTextFillColor: '#495057',
                },
              }}
              multiple
              displayEmpty
              value={adviceRequest.protectionAmount}
              onChange={onMultiSelectChange}
              input={<Input disableUnderline />}
            >
              <MenuItem value="" disabled>
                ---Bitte wählen---
              </MenuItem>
              {PROTECTION_AMOUNTS.map(name => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </FieldRow>
      <FieldRow>
        <TextAreaField
          onChange={onFieldChange}
          value={adviceRequest.internalPartnerNotes}
          name="internalPartnerNotes"
          id="internalPartnerNotes"
          label="DEVK interne Bemerkungen"
        />
      </FieldRow>
    </div>
  </FieldSet>
)
