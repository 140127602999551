import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material'
import { GridColDef, GridCellParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import {
  FilterOperator,
  useGridData,
  MuiDataGrid,
  PageLayout,
  HeaderButtonGroup,
  PageInfoline,
  createGridColumnsFrom,
  CenterBox,
  ExportButton,
  Badge,
} from 'common/components-mui'
import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import { FallbackIssueWarnings } from '../components'
import getCSVExportQuery from '../graphql/getCSVExport.graphql'
import getChancelleriesQuery from '../graphql/getChancelleries.graphql'
import {
  ChancelleryStatus,
  GetChancelleriesQuery,
  GetCsvExportQuery,
  GetCsvExportQueryVariables,
} from '../interfaces/schemaDefinition'

const FILE_BASE = process.env.FILE_BASE || ''
const getExportUrl = (): Promise<string> =>
  request<GetCsvExportQuery, GetCsvExportQueryVariables>(CHANCELLERIES_ENDPOINT, getCSVExportQuery).then(
    result => `${FILE_BASE}/${result.createExport}`
  )

const dataObjectName = 'chancelleries'

const getStatus: FC<GridCellParams> = params => {
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  const row = params.row as GetChancelleriesQuery['chancelleries']['list'][number]
  const inheritInactive = row.chancelleryLocations.every(location => !location.active)
  const status = inheritInactive ? ChancelleryStatus.Inactive : row.status
  return status === ChancelleryStatus.Archived ? (
    <CenterBox>
      <Badge color="error">Ausgetreten</Badge>
    </CenterBox>
  ) : status === ChancelleryStatus.Inactive ? (
    <CenterBox>
      <Badge color="default">Inaktiv</Badge>
    </CenterBox>
  ) : status === ChancelleryStatus.Limited ? (
    <CenterBox>
      <Badge color="warning" title="Ein oder mehrere Standorte sind nicht oder nur eingeschränkt verfügbar.">
        Eingeschränkt
      </Badge>
    </CenterBox>
  ) : (
    <CenterBox>
      <Badge color="success">Aktiv</Badge>
    </CenterBox>
  )
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
}

const columns: Array<GridColDef> = createGridColumnsFrom([
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  { field: 'name', headerName: 'Kanzlei', flex: 0.25 },
  {
    field: 'modifiedAt',
    headerName: 'Geändert am',
    valueFormatter: params => dayjs(params.value as string).format('DD.MM.YYYY'),
    width: 200,
  },
  { field: 'status', sortable: false, filterable: false, headerName: 'Status', width: 200, renderCell: getStatus },
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
])

export const ChancelleryListPage: FC = () => {
  const history = useHistory()
  const { data, error, actions, tableState } = useGridData<'chancelleries', GetChancelleriesQuery>(
    CHANCELLERIES_ENDPOINT,
    getChancelleriesQuery,
    dataObjectName,
    {
      sort: { sortBy: 'name', sortDirection: 'asc' },
      filters: [
        {
          name: 'tags',
          operator: FilterOperator.Empty,
          value: '',
        },
      ],
    }
  )

  const toggleFilter = (): void => {
    if (tableState.filters?.some(f => f.name === 'deleted')) {
      actions.setAPIFilter(tableState.filters.filter(f => f.name !== 'deleted'))
    } else {
      actions.setAPIFilter([
        ...(tableState.filters ?? []),
        {
          name: 'deleted',
          operator: FilterOperator.Equals,
          value: false,
        },
      ])
    }
  }

  // On mount add filter
  useEffect(() => {
    if (!tableState.filters?.some(f => f.name === 'deleted')) {
      toggleFilter()
    }
    // I really want that *only* to run once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const totalCount = data?.total ?? 0
  const deletedCount = data?.totalDeleted ?? 0
  const inactiveCount = data?.totalInactive ?? 0
  const limitedCount = data?.totalLimited ?? 0
  const activeCount = totalCount - deletedCount - limitedCount

  return (
    <PageLayout error={error} heading="Kanzleiverwaltung" spacing="table">
      <FallbackIssueWarnings />
      <HeaderButtonGroup>
        <ExportButton
          getExportUrl={getExportUrl}
          hoverText="Liste aller Kanzleien als CSV-Datei herunterladen"
          dialogTitle="CSV-Export"
        />
        {/* eslint-disable-next-line fp/no-mutating-methods */}
        <Button onClick={() => history.push('/chancelleries/new')}>
          <AddCircleOutlineIcon titleAccess="Kanzlei hinzufügen" />
        </Button>
      </HeaderButtonGroup>
      <PageInfoline>
        Insgesamt {totalCount} Einträge | {activeCount} aktiv | {inactiveCount} inaktiv | {limitedCount} eingeschränkt |{' '}
        {deletedCount} ausgetreten
      </PageInfoline>
      <MuiDataGrid
        noBorder
        actions={actions}
        tableState={tableState}
        columns={columns}
        rows={data?.list ?? []}
        loading={!data}
        rowCount={totalCount - deletedCount}
        // eslint-disable-next-line fp/no-mutating-methods
        onRowClick={r => (r.row.id ? history.push(`/chancelleries/edit/${r.row.id}`) : undefined)}
      />
      <Box mx={2} my={1}>
        <FormControlLabel
          control={<Checkbox checked={!tableState.filters?.some(f => f.name === 'deleted')} onChange={toggleFilter} />}
          label="Ausgetretene Kanzleien anzeigen"
        />
      </Box>
    </PageLayout>
  )
}
