import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Optional, either } from 'common/utils'
import { verifyUser } from 'packages/userauth/actions/verifyUser'
import { User } from 'packages/userauth/interfaces/schemaDefinition'

import { UserProvider as UserContextProvider } from './UserContext'
import { LoggedInUser } from './interfaces'

export const UserProvider: FunctionComponent = ({ children }) => {
  const history = useHistory()
  const [user, setUser] = useState<LoggedInUser>()
  const [confirmSignOut, setConfirmSignOut] = useState(false)

  const redirectToLogin = useCallback((): boolean => {
    // eslint-disable-next-line fp/no-mutating-methods
    history.push('/login')
    return true
  }, [history])

  useEffect(() => {
    try {
      verifyUser(localStorage.getItem('token')).then(
        either((storageUser: Optional<User>) => {
          if (storageUser) {
            // eslint-disable-next-line fp/no-mutation
            document.cookie = `token=${localStorage.getItem('token')};path=/`
            setUser({
              id: storageUser.id,
              name: storageUser.name,
              email: storageUser.email,
              organisation: storageUser.organization || '',
              organisationType: storageUser.organizationType || '',
              channel: storageUser.channel || '',
              roles: storageUser.roles.map(r => r.name),
              accessBitSet: storageUser.accessBitSet,
            })
            return true
          }
          return false
        }, redirectToLogin)
      )
    } catch (error) {
      console.error(error)
    }
  }, [redirectToLogin])

  const onConfirmSignOut = (): void => {
    localStorage.removeItem('token')
    // eslint-disable-next-line fp/no-mutation
    document.cookie = 'token='
    setUser(undefined)
    setConfirmSignOut(false)
    redirectToLogin()
  }

  const contextValue = {
    user,
    confirmSignOut,
    setUser,
    signOut: () => setConfirmSignOut(true),
    // eslint-disable-next-line fp/no-mutating-methods
    userProfile: () => history.push('/user-profile'),
    onConfirmSignOut,
    onCancelSignOut: () => setConfirmSignOut(false),
  }

  return <UserContextProvider value={contextValue}>{children}</UserContextProvider>
}
