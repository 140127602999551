import { zodResolver } from '@hookform/resolvers/zod'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Typography } from '@mui/material'
import React, { FC, useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import {
  PageLayout,
  LoadingMessage,
  SaveButton,
  AbortButton,
  DeleteWithConfirmation,
  FormActionBox,
  Grid,
} from 'common/components-mui'
import { TextField, Autocomplete } from 'common/components-mui/react-hook-form'
import { FIELDS_OF_LAW_ENTRIES } from 'common/constants/fieldsOfLaw'
import { useRedirect } from 'common/hooks'
import { enqueueSnackbar } from 'common/utils'

import { getLocationData, saveChancelleryLocation } from '../actions'
import { FieldOfLawPhoneEntry, OpeningHoursSettings, FallbackIssueWarnings, SimpleUserTable } from '../components'
import { GlobalHolidays } from '../components/GlobalHolidays'
import { HolidaySettings } from '../components/HolidaySettings'
import { InputLegend } from '../components/InputLegend'
import { MatchingConfigurationsTable } from '../components/MatchingConfigurationsTable'
import { ChancelleryLocationsFormPageParams } from '../interfaces'
import { locationInitialValues, LocationFormValues, chancelleryLocationSchema } from '../interfaces/formSchemas'
import { ChancelleryLocationInput } from '../interfaces/schemaDefinition'
import { toLocationApiInputFormat, mapChancelleryLocationAPIDataToForm } from '../utils'

export const ChancelleryLocationsFormPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { cid, id } = useParams<ChancelleryLocationsFormPageParams>()
  const redirect = useRedirect()
  const methods = useForm({
    defaultValues: locationInitialValues,
    resolver: zodResolver(chancelleryLocationSchema),
  })

  const { control, reset, getValues, watch, handleSubmit, setValue } = methods
  const [locationId, chancelleryId] = watch(['locationId', 'chancelleryId'])
  const users = getValues('users')

  const onSubmit = (values: LocationFormValues): Promise<string | undefined> => {
    const input: ChancelleryLocationInput = toLocationApiInputFormat({ ...values, chancelleryId: (chancelleryId || cid) ?? '' })
    return saveChancelleryLocation(input)
      .then(({ saveChancelleryLocation: savedChancelleryLocation }) => {
        if (savedChancelleryLocation) {
          reset(mapChancelleryLocationAPIDataToForm(savedChancelleryLocation))
          enqueueSnackbar(`Standort '${values.streetAddress}' wurde ${values.deleted ? 'gelöscht' : 'gespeichert'}`, {
            variant: 'success',
          })

          return savedChancelleryLocation.id
        }

        return undefined
      })
      .catch((e: Error) => {
        enqueueSnackbar(e.message === 'address_not_found' ? 'Adresse wurde nicht gefunden.' : 'Ein Fehler ist aufgetreten.', {
          variant: 'error',
        })

        return undefined
      })
  }

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      getLocationData(id).then(data => {
        reset(data)
        setIsLoading(false)
      })
    }
  }, [setIsLoading, reset, id])

  const toEdit = (savedId?: string): void => (savedId ? redirect(`/chancelleries/locations/edit/${savedId}`) : undefined)
  const toOverview = (): void => redirect(`/chancelleries`)

  return (
    <PageLayout heading={id ? 'Standort bearbeiten' : 'Standort anlegen'}>
      <FallbackIssueWarnings />
      <Box component="form" id="locations" onSubmit={handleSubmit(values => onSubmit(values).then(toEdit))}>
        <LoadingMessage isLoading={isLoading} />
        <Grid container spacing={4}>
          <Grid
            sm={12}
            lg={6}
            display="grid"
            sx={theme => ({
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridTemplateRows: 'repeat(3, auto)',
              gap: theme.spacing(3),
            })}
          >
            <TextField control={control} sx={{ gridColumn: '1/3' }} name="phone" label="Telefon" disabled={isLoading} fullWidth />
            <TextField
              control={control}
              sx={{ gridColumn: '1/3' }}
              name="streetAddress"
              label="Adresse"
              disabled={isLoading}
              fullWidth
            />
            <TextField control={control} name="zip" label="Postleitzahl" disabled={isLoading} fullWidth />
            <TextField control={control} name="city" label="Stadt" fullWidth disabled={isLoading} />
          </Grid>
          <Grid sm={12} lg={8}>
            <Box component="fieldset">
              <InputLegend>Fallback für</InputLegend>
              <Autocomplete
                sx={{
                  marginTop: 2,
                }}
                name="fallbackFor"
                label="Rechtsgebiet"
                options={FIELDS_OF_LAW_ENTRIES.map(({ name }) => name)}
                control={control}
                multiple
                fullWidth
                limitTags={5}
                ChipProps={{ color: 'primary' }}
                disabled={isLoading}
              />
            </Box>
          </Grid>
          <Grid sm={12}>
            {locationId ? (
              <MatchingConfigurationsTable chancelleryLocationId={locationId} />
            ) : (
              <Box display="flex" alignItems="center" mb={3}>
                <InfoIcon color="primary" sx={theme => ({ marginRight: theme.spacing(2) })} />
                <Typography>Um Konfigurationen hinzuzufügen, müsssen Sie den Standort erst mit „Speichern“ anlegen.</Typography>
              </Box>
            )}
            <SimpleUserTable title="Verknüpfte Anwälte" users={users} />
          </Grid>

          <FormProvider {...methods}>
            <Grid component="fieldset" sm={12} lg={6}>
              <OpeningHoursSettings disabled={isLoading} />
            </Grid>
            <Grid component="fieldset" sm={12} lg={6}>
              <HolidaySettings disabled={isLoading} />
              <GlobalHolidays
                title="Weitere Urlaubszeiten für alle Standorte"
                chancelleryId={chancelleryId ?? ''}
                skipLocation={locationId}
              />
            </Grid>
            <Grid component="fieldset" sm={12} md={6}>
              <FieldOfLawPhoneEntry disabled={isLoading} />
            </Grid>
          </FormProvider>
        </Grid>

        <FormActionBox>
          {id && (
            <DeleteWithConfirmation
              actionButtonText="Standort löschen"
              onConfirm={async () => {
                setValue('deleted', true)
                onSubmit(getValues()).then(toOverview)
              }}
            >
              <Typography>Diese Aktion ist nicht umkehrbar. Standort wird permanent gelöscht.</Typography>
            </DeleteWithConfirmation>
          )}
          <AbortButton onClick={toOverview} />
          <SaveButton form="locations" />
          <SaveButton
            submit={false}
            onClick={() => {
              onSubmit(getValues()).then(toOverview)
            }}
          >
            Speichern und Schließen
          </SaveButton>
        </FormActionBox>
      </Box>
    </PageLayout>
  )
}
