import React, { useContext, useCallback, useState, FC } from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { Page404 } from 'common/components'
import { GlobalContextProvider } from 'common/components/GlobalContext'
import { Roles, URLs } from 'common/constants'
import { UserContext, withAuthorization } from 'common/user-context'
import { isLawyer } from 'common/utils'
import { AdminToolsPage } from 'packages-mui/admin-tools'
import { AdviceForeignFormPage, AdviceListPage } from 'packages-mui/advice'
import {
  ChancelleryListPage,
  ChancelleryFormPage,
  ChancelleryLocationsFormPage,
  ConfigurationFormPage,
  MatchingConfigListPage,
  ChancelleryLocationsVolumesListPage,
  IssuesDashboardPage,
} from 'packages-mui/chancelleries'
import { FieldsOfLawListPage, FieldsOfLawDetailPage } from 'packages-mui/fields-of-law'
import { PartnersListPage, PartnerFormPage } from 'packages-mui/partners'
import { ProductsListPage, ProductDetailsPage } from 'packages-mui/products'
import { ProductsBusinessListPage } from 'packages-mui/products-business'
import { SupportPage } from 'packages-mui/support'
import { UserFormPage, UsersListPage } from 'packages-mui/users'
import { AdviceListContainer } from 'packages/advice'
import { CallbacksView } from 'packages/callbacks'
import { ChancellerySearchContainer } from 'packages/chancellery-search'
import { DashboardContainer } from 'packages/dashboard'
import { LawyerControllingPage, LawyerContextProvider } from 'packages/lawyer-controlling'
import { B2bListView, AdviceDetailContainer, LawyerContainer, SalesContainer, SalesDetailContainer } from 'packages/lawyers'
import { ProductBusinessContainer } from 'packages/products-business'
import { Search } from 'packages/search'
import { UserProfileContainer } from 'packages/user-profile'

import { Content, Main } from './Main'
import { Navigation } from './Navigation'
import { HeaderPanel } from './headerpanel/HeaderPanel'

const UserFromAuthorized = withAuthorization([Roles.Administrator])(UserFormPage)

const UserListAuthorized = withAuthorization([Roles.Administrator])(UsersListPage)
const ChancelleriesFormAuthorized = withAuthorization([Roles.Administrator, Roles.Employee])(ChancelleryFormPage)
const ChancelleriesListAuthorized = withAuthorization([Roles.Administrator, Roles.Employee])(ChancelleryListPage)
const MatchingConfigListAuthorized = withAuthorization([Roles.Administrator, Roles.Employee])(MatchingConfigListPage)
const ChancelleryLocationsFormAuthorized = withAuthorization([Roles.Administrator, Roles.Employee])(ChancelleryLocationsFormPage)
const ConfigurationsFormAuthorized = withAuthorization([Roles.Administrator, Roles.Employee])(ConfigurationFormPage)
const ChancelleryLocationsVolumesListAuthorized = withAuthorization([Roles.Administrator, Roles.Employee])(
  ChancelleryLocationsVolumesListPage
)
const AdminToolsAuthorized = withAuthorization([Roles.Administrator])(AdminToolsPage)
const FieldsOfLawDetailAuthorized = withAuthorization([Roles.Administrator, Roles.Employee])(FieldsOfLawDetailPage)
const FieldsOfLawListAuthorized = withAuthorization([Roles.Administrator, Roles.Employee])(FieldsOfLawListPage)
const IssuesDashboardAuthorized = withAuthorization([Roles.Administrator, Roles.Employee])(IssuesDashboardPage)
const PartnersListAuthorized = withAuthorization([Roles.Administrator, Roles.Employee])(PartnersListPage)
const PartnerFormAuthorized = withAuthorization([Roles.Administrator, Roles.Employee])(PartnerFormPage)
const LawyerAuthorized = withAuthorization([Roles.Lawyer, Roles.Administrator])(LawyerContainer)
const SalesLawyerAuthorized = withAuthorization([Roles.Lawyer, Roles.Administrator])(SalesContainer)
const SalesDetailLawyerAuthorized = withAuthorization([Roles.Lawyer, Roles.Administrator])(SalesDetailContainer)
const B2bLawyerAuthorized = withAuthorization([Roles.Lawyer, Roles.Administrator])(B2bListView)
const SupportAuthorized = withAuthorization([Roles.Lawyer, Roles.Administrator])(SupportPage)
const AdviceDetailAuthorized = withAuthorization([Roles.Lawyer, Roles.Administrator, Roles.Callcenter, Roles.Channel])(
  AdviceDetailContainer
)
const AdviceRequestsListAuthorized = withAuthorization([
  Roles.Administrator,
  Roles.Employee,
  Roles.Callcenter,
  Roles.Channel,
  Roles.TRB,
  Roles.TRBManager,
])(AdviceListPage)
const AdviceRequestsListOldAuthorized = withAuthorization([
  Roles.Administrator,
  Roles.Employee,
  Roles.Callcenter,
  Roles.Channel,
  Roles.TRB,
  Roles.TRBManager,
])(AdviceListContainer)
const AdviceRequestsFormAuthorized = withAuthorization([
  Roles.Administrator,
  Roles.Employee,
  Roles.Callcenter,
  Roles.Channel,
  Roles.TRB,
  Roles.TRBManager,
  Roles.Lawyer,
])(ChancellerySearchContainer)
const AdviceRequestsFormForeignAuthorized = withAuthorization([Roles.Administrator, Roles.Employee, Roles.Channel])(
  AdviceForeignFormPage
)
const AdviceSearchAuthorized = withAuthorization([Roles.Lawyer, Roles.Callcenter, Roles.Administrator])(Search)

const DashboardAuthorized = withAuthorization([Roles.Lawyer, Roles.Administrator])(DashboardContainer)
const UserProfileAuthorized = withAuthorization([Roles.Lawyer, Roles.Administrator])(UserProfileContainer)
const LawyerControllingAuthorized = withAuthorization([Roles.Lawyer])(LawyerControllingPage)
const CallbacksAuthorized = withAuthorization([Roles.Administrator, Roles.Employee, Roles.Callcenter])(CallbacksView)
const ProductsBusinessListAuthorized = withAuthorization([Roles.Administrator])(ProductsBusinessListPage)
const ProductsBusinessFormAuthorized = withAuthorization([Roles.Administrator])(ProductBusinessContainer)
const ProductsListAuthorized = withAuthorization([Roles.Administrator])(ProductsListPage)
const ProductFormAuthorized = withAuthorization([Roles.Administrator])(ProductDetailsPage)

const IndexPage: FC = () => {
  const { user } = useContext(UserContext)

  if (!user) {
    return null
  }
  return isLawyer(user) ? <Redirect to="/chancellery/dashboard" /> : <Redirect to="/advice-requests" />
}

export const Backend: FC = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const onToggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen)
  }, [menuOpen, setMenuOpen])

  const redirectTo404 = ({ history }: RouteComponentProps<{}>): null => {
    // eslint-disable-next-line fp/no-mutating-methods
    history.push('/404')
    return null
  }

  return (
    <GlobalContextProvider>
      <LawyerContextProvider>
        <HeaderPanel onMenuToggle={onToggleMenu} />
        <Navigation menuOpen={menuOpen} onMenuToggle={onToggleMenu} />
        <Main>
          <Content>
            <Switch>
              <Route path="/users/edit/:id" component={UserFromAuthorized} />
              <Route path="/users/new" component={UserFromAuthorized} />
              <Route path="/users" component={UserListAuthorized} />

              <Route path="/advice-requests/form" component={AdviceRequestsFormAuthorized} />
              <Route path="/advice-requests/form-foreign" component={AdviceRequestsFormForeignAuthorized} />
              <Route path="/advice-requests" component={AdviceRequestsListAuthorized} />
              <Route path="/advice-requests-old" component={AdviceRequestsListOldAuthorized} />

              <Route path={URLs.chancellery.adviceRequests.home} component={LawyerAuthorized} />
              <Route path={URLs.chancellery.adviceRequests.openRequests} component={LawyerAuthorized} />
              <Route path={URLs.chancellery.adviceRequests.potentialMandates} component={LawyerAuthorized} />
              <Route path={URLs.chancellery.adviceRequests.closedRequests} component={LawyerAuthorized} />
              <Route path={URLs.chancellery.adviceRequests.mandates} component={LawyerAuthorized} />
              <Route path={URLs.chancellery.adviceRequests.completeMandates} component={LawyerAuthorized} />
              <Route path="/advice-request/detail" component={AdviceDetailAuthorized} />

              <Route path={URLs.chancellery.b2b.open} component={B2bLawyerAuthorized} />
              <Route path={URLs.chancellery.b2b.processing} component={B2bLawyerAuthorized} />
              <Route path={URLs.chancellery.b2b.complete} component={B2bLawyerAuthorized} />
              <Route path={URLs.chancellery.b2b.closed} component={B2bLawyerAuthorized} />
              <Route path={URLs.chancellery.b2b.home} render={() => <Redirect to={URLs.chancellery.b2b.open} />} />

              <Route path="/chancelleries/configurations/edit/:id" component={ConfigurationsFormAuthorized} />
              <Route path="/chancelleries/configurations/:lid/new" component={ConfigurationsFormAuthorized} />
              <Route path="/chancelleries/configurations" component={MatchingConfigListAuthorized} />
              <Route path="/chancelleries/locations/edit/:id" component={ChancelleryLocationsFormAuthorized} />
              <Route path="/chancelleries/locations/:cid/new" component={ChancelleryLocationsFormAuthorized} />
              <Route path="/chancelleries/locations-volumes" component={ChancelleryLocationsVolumesListAuthorized} />

              <Route path="/chancelleries/edit/:id" component={ChancelleriesFormAuthorized} />
              <Route path="/chancelleries/new" component={ChancelleriesFormAuthorized} />
              <Route path="/chancelleries/issues" component={IssuesDashboardAuthorized} />
              <Route path="/chancelleries" component={ChancelleriesListAuthorized} />

              <Route path="/chancellery/dashboard" component={DashboardAuthorized} />
              <Route path={URLs.lawyerControlling.home} component={LawyerControllingAuthorized} />

              <Route path="/chancellery/sales/:id" component={SalesDetailLawyerAuthorized} />
              <Route path="/chancellery/sales" component={SalesLawyerAuthorized} />

              <Route path="/partners/edit/:id" component={PartnerFormAuthorized} />
              <Route path="/partners/new" component={PartnerFormAuthorized} />
              <Route path="/partners" component={PartnersListAuthorized} />

              <Route path="/support" component={SupportAuthorized} />

              <Route path="/user-profile" component={UserProfileAuthorized} />

              <Route path="/search/:searchTerm" component={AdviceSearchAuthorized} />

              <Route path="/callbacks" component={CallbacksAuthorized} />

              <Route path="/product-business" component={ProductsBusinessFormAuthorized} />
              <Route path="/products-business" component={ProductsBusinessListAuthorized} />
              <Route path="/products/edit/:id" component={ProductFormAuthorized} />
              <Route path="/products/new" component={ProductFormAuthorized} />
              <Route path="/products" component={ProductsListAuthorized} />

              <Route path="/fields-of-law/:id" component={FieldsOfLawDetailAuthorized} />
              <Route path="/fields-of-law" component={FieldsOfLawListAuthorized} />

              <Route path="/admin-tools" component={AdminToolsAuthorized} />

              <Route path="/404" component={Page404} />
              <Route path="/" exact component={IndexPage} />
              <Route render={redirectTo404} />
            </Switch>
          </Content>
        </Main>
      </LawyerContextProvider>
    </GlobalContextProvider>
  )
}
