import { zodResolver } from '@hookform/resolvers/zod'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Typography, Alert, Stack } from '@mui/material'
import { pipe } from 'ramda'
import React, { useEffect, FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import uriTemplate from 'uri-templates'

import {
  PageLayout,
  LoadingMessage,
  AbortButton,
  SaveButton,
  DeleteWithConfirmation,
  FormActionBox,
  Grid,
} from 'common/components-mui'
import { TextField, Checkbox } from 'common/components-mui/react-hook-form'
import { useParams, useRedirect } from 'common/hooks'
import { addOptionalPropIf, enqueueSnackbar } from 'common/utils'

import { getChancelleryData, saveChancellery } from '../actions'
import {
  ChancelleryLocationsTable,
  GlobalHolidays,
  OrderVolumeSetting,
  FallbackIssueWarnings,
  SimpleUserTable,
} from '../components'
import { InputLegend } from '../components/InputLegend'
import { ChancelleryFormPageParams } from '../interfaces'
import { chancelleryInitialValues, chancellerySchema, ChancelleryFormValues, LocalOrderVolume } from '../interfaces/formSchemas'
import { ChancelleryInput, SaveChancelleryMutation } from '../interfaces/schemaDefinition'
import { mapChancelleryAPIDataToForm } from '../utils'

const TO_OVERVIEW = '/chancelleries'
const TO_EDIT_VIEW = uriTemplate('/chancelleries/edit/{id}')

export const ChancelleryFormPage: FC = () => {
  const { id } = useParams<ChancelleryFormPageParams>()
  const redirect = useRedirect()
  const [isLoading, setIsLoading] = useState(false)
  const methods = useForm({
    defaultValues: chancelleryInitialValues,
    resolver: zodResolver(chancellerySchema),
  })

  const { control, reset, watch, getValues, handleSubmit, setValue } = methods

  const onSubmit = (values: ChancelleryFormValues): Promise<SaveChancelleryMutation | void> => {
    const {
      active,
      deleted,
      name,
      contactPersonSalutation,
      contactPersonGivenName,
      contactPersonFamilyName,
      phone,
      email,
      orderVolumes,
      chancelleryId,
      ustId,
      iban,
      eConsultId = '',
      secupay,
      mollieId,
      services,
      powerBI1 = '',
      powerBI2 = '',
      callbacks,
    } = values
    const input: ChancelleryInput = {
      name,
      active,
      deleted,
      contactPerson: {
        foa: contactPersonSalutation,
        firstname: contactPersonGivenName,
        lastname: contactPersonFamilyName,
      },
      phone,
      email,
      orderVolumes: orderVolumes
        .filter((entry): entry is LocalOrderVolume => Boolean(entry.fieldOfLaw?.id))
        .map(entry => ({
          weeklyMin: entry.weeklyMin !== '' ? parseInt(entry.weeklyMin, 10) : undefined,
          weeklyMax: entry.weeklyMax !== '' ? parseInt(entry.weeklyMax, 10) : undefined,
          fieldOfLawId: entry.fieldOfLaw.id,
        })),
      eConsult: {
        active: eConsultId.length > 0,
        customerId: eConsultId,
      },
      secupay: {
        active: secupay.length > 0,
        contractId: secupay,
      },
      mollieId,
      services,
      callbacks,
    }
    const withPowerBI = addOptionalPropIf<{ powerBI: Array<string> }, ChancelleryInput>(
      powerBI1.length > 0 && powerBI2.length > 0
    )({
      powerBI: [powerBI1, powerBI2],
    })

    const withId = addOptionalPropIf<{ id: string }, ChancelleryInput>(!!chancelleryId && chancelleryId.length > 0)({
      id: chancelleryId ?? '',
    })
    const withTaxId = addOptionalPropIf<{ taxId: string }, ChancelleryInput>(ustId.length > 0)({ taxId: ustId })
    const withIban = addOptionalPropIf<{ iban: string | undefined }, ChancelleryInput>(!!iban && iban.length > 0)({
      iban: iban ?? '',
    })

    const withOptionalValues = pipe(withId, withPowerBI, withTaxId, withIban)
    const wholeInput: ChancelleryInput = withOptionalValues(input)

    return saveChancellery(wholeInput)
      .then(({ saveChancellery: savedChancellery }) => {
        if (savedChancellery) {
          reset(mapChancelleryAPIDataToForm(savedChancellery))
          enqueueSnackbar(
            savedChancellery.deleted
              ? 'Die Kanzlei wurde erfolgreich als gelöscht markeirt'
              : `Kanzlei mit der id: ${savedChancellery.id} wurde erfolgreich gespeichert.`,
            { variant: 'success' }
          )
        }
        return { saveChancellery: savedChancellery }
      })
      .catch(() => {
        enqueueSnackbar(`Das abspeichern der Kanzlei war nicht erfolgreich.`, { variant: 'error' })
      })
  }

  const [deleted, chancelleryId] = watch(['deleted', 'chancelleryId'])

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      getChancelleryData(id).then(data => {
        reset(data)
        setIsLoading(false)
      })
    } else {
      reset()
    }
  }, [id, reset])

  const disabled = isLoading || deleted

  return (
    <PageLayout heading={deleted ? 'Kanzleidetails' : id ? 'Kanzlei bearbeiten' : 'Kanzlei anlegen'}>
      <FallbackIssueWarnings />
      <Box component="form" id="chancellery" onSubmit={handleSubmit(onSubmit)}>
        <LoadingMessage isLoading={isLoading} />
        {deleted && (
          <Box mb={3}>
            <Alert severity="warning">Diese Kanzlei wurde als „ausgetreten“ markiert. Änderungen sind nicht mehr möglich.</Alert>
          </Box>
        )}
        <Grid container spacing={3}>
          <Grid sm={12} md={6} display="flex" flexDirection="column" gap={3}>
            <Checkbox control={control} name="active" label="Aktiv" disabled={disabled} />
            <TextField control={control} name="name" label="Name Kanzlei" disabled={disabled} fullWidth />
            <TextField
              control={control}
              name="contactPersonSalutation"
              label="Ansprechpartner Anrede"
              placeholder='z.B. "Frau"'
              disabled={disabled}
              fullWidth
            />
            <TextField
              control={control}
              name="contactPersonFamilyName"
              label="Ansprechpartner Name"
              disabled={disabled}
              fullWidth
            />
            <TextField
              control={control}
              name="contactPersonGivenName"
              label="Ansprechpartner Vorname(n)"
              disabled={disabled}
              fullWidth
            />
            <TextField control={control} name="phone" label="Telefon" disabled={disabled} fullWidth />
            <TextField control={control} name="email" label="Email-Adresse" disabled={disabled} fullWidth />
            {chancelleryId && <TextField control={control} name="chancelleryId" label="Kanzlei-ID" disabled fullWidth />}
            <TextField control={control} name="ustId" label="Umsatzsteuer-ID" disabled={disabled} fullWidth />
            <TextField control={control} name="iban" label="IBAN" disabled={disabled} fullWidth />
            <TextField control={control} name="eConsultId" label="E-Consult ID" disabled={disabled} fullWidth />
            <TextField control={control} name="secupay" label="Secupay-Nummer" disabled={disabled} fullWidth />
            <TextField control={control} name="mollieId" label="Mollie-ID" disabled={disabled} fullWidth />
            <TextField
              control={control}
              name="services"
              label="Kunden Benefits"
              disabled={disabled}
              multiline
              rows={1}
              fullWidth
            />
            <Checkbox control={control} name="callbacks" label="Rückrufe aktiviert / Macht Rückrufe selbst" disabled={disabled} />
          </Grid>
          <Grid sm={12} md={6} component="fieldset">
            <FormProvider {...methods}>
              <OrderVolumeSetting disabled={disabled} />
            </FormProvider>
          </Grid>

          {chancelleryId ? (
            <Grid sm={12}>
              <ChancelleryLocationsTable chancelleryId={chancelleryId} disabled={disabled} />
              <GlobalHolidays title="Für alle Standorte geltende Urlaubszeiten" chancelleryId={chancelleryId} />
            </Grid>
          ) : (
            <Grid sm={12} display="flex" alignItems="center" mb={3}>
              <InfoIcon color="primary" sx={theme => ({ marginRight: theme.spacing(2) })} />
              <Typography>Um Standorte hinzuzufügen, müsssen Sie die Kanzlei erst mit „Speichern“ anlegen.</Typography>
            </Grid>
          )}
          <Grid sm={12}>
            <SimpleUserTable title="Verknüpfte Anwälte" users={getValues('users')} />
          </Grid>

          <Grid sm={12} md={6} component="fieldset">
            <InputLegend>Power BI</InputLegend>
            <Stack spacing={3}>
              <TextField control={control} name="powerBI1" label="Link für Bearbeitungsstand" disabled={disabled} fullWidth />
              <TextField control={control} name="powerBI2" label="Link für Umwandlungsquote" disabled={disabled} fullWidth />
            </Stack>
          </Grid>
        </Grid>
        <FormActionBox>
          {id && !deleted && (
            <DeleteWithConfirmation
              actionButtonText="Kooperation beenden"
              onConfirm={async () => {
                setValue('deleted', true)
                onSubmit(getValues()).then(() => redirect(TO_OVERVIEW))
              }}
            >
              <Typography>Diese Aktion ist nicht umkehrbar. Kooperation mit Kanzlei wird permanent beendet.</Typography>
            </DeleteWithConfirmation>
          )}
          <AbortButton
            onClick={() => {
              redirect(TO_OVERVIEW)
            }}
          >
            {deleted ? 'Schließen' : null}
          </AbortButton>
          {deleted || (
            <>
              <SaveButton form="chancellery" />
              <SaveButton
                submit={false}
                onClick={() =>
                  handleSubmit(values => {
                    onSubmit(values).then(data => {
                      const toEdit = !getValues('chancelleryId')
                      const savedId = data ? data.saveChancellery?.id : undefined
                      const to = toEdit && savedId ? TO_EDIT_VIEW.fillFromObject({ id: savedId }) : undefined

                      if (to) {
                        redirect(to)
                      }
                    })
                  })
                }
              >
                Speichern und Schließen
              </SaveButton>
            </>
          )}
        </FormActionBox>
      </Box>
    </PageLayout>
  )
}
