import { ProductType, Status, AdviceRequestFlattened } from '../interfaces'

export const EMPTY_ADVICE_REQUEST: AdviceRequestFlattened = {
  foa: '',
  fieldOfLaw: null,
  createdAt: new Date(),
  firstname: '',
  lastname: '',
  street: '',
  streetNr: '',
  city: '',
  zip: '',
  country: null,
  email: '',
  phone: '',
  companyName: '',
  companyCompanyType: '',
  companyAddressStreet: '',
  companyAddressStreetNr: '',
  companyAddressZip: '',
  companyAddressCity: '',
  companyTaxId: '',
  billingCompanyName: '',
  billingCompanyCompanyType: '',
  billingCompanyAddressStreet: '',
  billingCompanyAddressStreetNr: '',
  billingCompanyAddressZip: '',
  billingCompanyAddressCity: '',
  billingCompanyTaxId: '',
  agbsAccepted: false,
  dataAccepted: false,
  damageNumber: '',
  damageNumberDisabled: false,
  insuranceNumber: '',
  deductible: false,
  deductibleAmount: '',
  protection: false,
  protectionAmount: '',
  disputeAmount: '',
  internalPartnerNotes: '',
  instance: '',
  description: '',
  internalNotes: '',
  externalNotes: '',
  closeReason: '',
  status: Status.NEW,
  adviceId: '',
  insured: false,
  newsletter: false,
  resultNotes: '',
  lawyerNotes: '',
  product: {
    id: '',
    name: '',
    price: 0,
    type: ProductType.Consumer,
  },
  language: {
    code: 'de',
    language: 'Deutsch',
  },
}
