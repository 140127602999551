import {
  Typography,
  LinearProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
  Radio,
  Box,
} from '@mui/material'
import React, { FunctionComponent, useState } from 'react'
import { useForm } from 'react-hook-form'

import { RadioGroup } from 'common/components-mui/react-hook-form'
import { useUser } from 'common/user-context'
import { isAdmin, isEmployee } from 'common/utils/roles'

import { createExport } from '../actions'
import { ExportType } from '../interfaces'
import { ExportModalProps } from '../interfaces/ExportModalProps'

export const ExportModal: FunctionComponent<ExportModalProps> = ({ open, filters, sort, onCancelClick }) => {
  const [loading, setLoading] = useState(false)
  const [link, setLink] = useState('')
  const [error, setError] = useState(null)

  const { user } = useUser()
  const { handleSubmit, control } = useForm<{ exportType: ExportType }>({
    defaultValues: {
      exportType: ExportType.Controlling,
    },
  })

  const fetchAndStoreExportLink = async (values: { exportType: ExportType }): Promise<void> => {
    setLoading(true)

    try {
      const exportLink = await createExport({
        sort,
        filters,
        type: values.exportType,
      })

      setLink(`${process.env.FILE_BASE || ''}/${exportLink.createExport}`)
    } catch (e) {
      setError(e)
    }

    setLoading(false)
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Export</DialogTitle>
      <DialogContent dividers>
        <Box minWidth="360px">
          {error ? (
            <Typography align="center" gutterBottom>
              Es ist ein Fehler aufgetreten.
            </Typography>
          ) : loading ? (
            <>
              <Typography align="center">Export wird vorbereitet …</Typography>
              <LinearProgress />
            </>
          ) : !link ? (
            <Box component="form" onSubmit={handleSubmit(fetchAndStoreExportLink)}>
              <Box mb={3}>
                <RadioGroup control={control} label="Export-Typ" name="exportType">
                  <FormControlLabel label="Controlling" control={<Radio />} value={ExportType.Controlling} />
                  {(isAdmin(user) || isEmployee(user)) && (
                    <>
                      <FormControlLabel label="Bewertungen" control={<Radio />} value={ExportType.Ekomi} />
                      <FormControlLabel label="ASCII" control={<Radio />} value={ExportType.Ascii} />
                    </>
                  )}
                </RadioGroup>
              </Box>
              <Button type="submit" variant="contained">
                Anfordern
              </Button>
            </Box>
          ) : (
            <Typography align="center" gutterBottom>
              Export bereit!
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!link}
          onClick={() => {
            onCancelClick()
            setLink('')
          }}
          target="_blank"
          href={link || ''}
        >
          Herunterladen
        </Button>
      </DialogActions>
    </Dialog>
  )
}
