export const passwordErrorMessages = {
  required_error: 'Bitte geben Sie ein Passwort an.',
  invalid_type_error: 'Bitte geben Sie eine valides Passwort an.',
}

export const confirmPasswordErrorMessages = {
  required_error: 'Die Passwörter stimmen nicht überein.',
  invalid_type_error: 'Die Passwörter stimmen nicht überein.',
}

export const emailErrorMessages = {
  required_error: 'Bitte geben Sie eine E-Mail an.',
  invalid_type_error: 'Bitte geben Sie eine valide E-Mail an.',
}
