export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccessBitSet: any;
  Date: any;
  FilterValue: any;
};

export type AccessTuple = {
  __typename?: 'AccessTuple';
  action: Scalars['Int'];
  topic: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type AddressInput = {
  city: Scalars['String'];
  street: Scalars['String'];
  streetNr?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type Chancellery = {
  __typename?: 'Chancellery';
  active: Scalars['Boolean'];
  callbacks?: Maybe<Scalars['Boolean']>;
  chancelleryLocations: Array<ChancelleryLocation>;
  contactPerson: ContactPerson;
  deleted: Scalars['Boolean'];
  eConsult?: Maybe<EConsultSettings>;
  email: Scalars['String'];
  hasB2B?: Maybe<Scalars['Boolean']>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  modifiedAt: Scalars['Date'];
  mollieId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderVolumes: Array<OrderVolume>;
  phone: Scalars['String'];
  powerBI?: Maybe<Array<Scalars['String']>>;
  secupay?: Maybe<SecupaySettings>;
  services?: Maybe<Scalars['String']>;
  status: ChancelleryStatus;
  tags?: Maybe<Array<Scalars['String']>>;
  taxId?: Maybe<Scalars['String']>;
  users: Array<User>;
};

export type ChancelleryInput = {
  active: Scalars['Boolean'];
  callbacks?: InputMaybe<Scalars['Boolean']>;
  contactPerson: ContactPersonInput;
  deleted?: InputMaybe<Scalars['Boolean']>;
  eConsult: EConsultSettingsInput;
  email: Scalars['String'];
  iban?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  mollieId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  orderVolumes?: InputMaybe<Array<OrderVolumeInput>>;
  phone: Scalars['String'];
  powerBI?: InputMaybe<Array<Scalars['String']>>;
  products?: InputMaybe<Array<Scalars['String']>>;
  secupay: SecupaySettingsInput;
  services?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  taxId?: InputMaybe<Scalars['String']>;
};

export type ChancelleryList = {
  __typename?: 'ChancelleryList';
  list: Array<Chancellery>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
  totalDeleted: Scalars['Int'];
  totalInactive: Scalars['Int'];
  totalLimited: Scalars['Int'];
};

export type ChancelleryLocation = {
  __typename?: 'ChancelleryLocation';
  active: Scalars['Boolean'];
  address: Address;
  chancellery: Chancellery;
  chancelleryId: Scalars['String'];
  deleted: Scalars['Boolean'];
  eConsult?: Maybe<EConsultSettings>;
  fallbackFor: Array<FieldOfLaw>;
  fieldOfLawPhones: Array<FieldOfLawPhone>;
  holidays: Array<Holiday>;
  id: Scalars['ID'];
  name: Scalars['String'];
  openingHours: Array<OpeningHoursValue>;
  phone: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
  users: Array<User>;
};

export type ChancelleryLocationInput = {
  active: Scalars['Boolean'];
  address: AddressInput;
  chancelleryId: Scalars['String'];
  deleted: Scalars['Boolean'];
  fallbackFor: Array<Scalars['String']>;
  fieldOfLawPhones: Array<FieldOfLawPhoneInput>;
  holidays: Array<HolidayInput>;
  id?: InputMaybe<Scalars['ID']>;
  openingHours: Array<OpeningHoursValueInput>;
  phone: Scalars['String'];
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type ChancelleryLocationList = {
  __typename?: 'ChancelleryLocationList';
  list: Array<ChancelleryLocation>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ChancelleryStatus {
  Active = 'active',
  Archived = 'archived',
  Inactive = 'inactive',
  Limited = 'limited'
}

export type Company = {
  __typename?: 'Company';
  city?: Maybe<Scalars['String']>;
  companyType?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type CompanyInput = {
  address?: InputMaybe<AddressInput>;
  companyType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  taxId?: InputMaybe<Scalars['String']>;
};

export enum ConfigWeight {
  Default = 'DEFAULT',
  Few = 'FEW',
  Less = 'LESS',
  More = 'MORE',
  Much = 'MUCH'
}

export type ContactPerson = {
  __typename?: 'ContactPerson';
  firstname: Scalars['String'];
  foa: Scalars['String'];
  lastname: Scalars['String'];
};

export type ContactPersonInput = {
  firstname: Scalars['String'];
  foa: Scalars['String'];
  lastname: Scalars['String'];
};

export type Coverage = {
  __typename?: 'Coverage';
  ok: Scalars['Boolean'];
  problems?: Maybe<CoverageProblemList>;
};

export type CoverageCacheConfiguration = {
  configs?: InputMaybe<Array<CoverageErrorType>>;
  fallbacks?: InputMaybe<Array<CoverageErrorType>>;
};

export enum CoverageErrorType {
  MissingField = 'MISSING_FIELD',
  MissingPartner = 'MISSING_PARTNER',
  MissingProduct = 'MISSING_PRODUCT',
  MissingZipArea = 'MISSING_ZIP_AREA',
  TemporaryMissingField = 'TEMPORARY_MISSING_FIELD'
}

export type CoverageInfo = {
  __typename?: 'CoverageInfo';
  configs: Coverage;
  fallbacks: Coverage;
};


export type CoverageInfoConfigsArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type CoverageInfoFallbacksArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};

export type CoverageMissingProblem = {
  __typename?: 'CoverageMissingProblem';
  errorType: CoverageErrorType;
  fieldOfLawId: Scalars['String'];
};

export type CoveragePartnerProblem = {
  __typename?: 'CoveragePartnerProblem';
  errorType: CoverageErrorType;
  fieldOfLawId: Scalars['String'];
  info: PartnerErrorInfo;
};

export type CoverageProblem = CoverageMissingProblem | CoveragePartnerProblem | CoverageProductProblem | CoverageTemporaryMissingProblem | CoverageZipProblem;

export type CoverageProblemList = {
  __typename?: 'CoverageProblemList';
  list: Array<CoverageProblem>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type CoverageProductProblem = {
  __typename?: 'CoverageProductProblem';
  errorType: CoverageErrorType;
  fieldOfLawId: Scalars['String'];
  info: ProductErrorInfo;
};

export type CoverageTemporaryMissingProblem = {
  __typename?: 'CoverageTemporaryMissingProblem';
  errorType: CoverageErrorType;
  fieldOfLawId: Scalars['String'];
  info: PeriodErrorInfo;
};

export type CoverageZipProblem = {
  __typename?: 'CoverageZipProblem';
  errorType: CoverageErrorType;
  fieldOfLawId: Scalars['String'];
  info: ZipErrorInfo;
};

export type DeletionSuccess = {
  __typename?: 'DeletionSuccess';
  success: Scalars['Boolean'];
};

export type EConsultSettings = {
  __typename?: 'EConsultSettings';
  active: Scalars['Boolean'];
  customerId: Scalars['String'];
};

export type EConsultSettingsInput = {
  active: Scalars['Boolean'];
  customerId: Scalars['String'];
};

export enum ExportVariant {
  Default = 'DEFAULT',
  OrderVolume = 'ORDER_VOLUME'
}

export type FieldOfLaw = {
  __typename?: 'FieldOfLaw';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type FieldOfLawIds = {
  excluded?: InputMaybe<Array<Scalars['String']>>;
  included: Array<Scalars['String']>;
};

export type FieldOfLawPhone = {
  __typename?: 'FieldOfLawPhone';
  fieldOfLaw: FieldOfLaw;
  phone: Scalars['String'];
};

export type FieldOfLawPhoneInput = {
  fieldOfLawId: Scalars['String'];
  phone: Scalars['String'];
};

export type FieldsOfLaw = {
  __typename?: 'FieldsOfLaw';
  excluded?: Maybe<Array<FieldOfLaw>>;
  included: Array<FieldOfLaw>;
};

export type Filter = {
  name: Scalars['String'];
  operator: FilterOperator;
  value: Scalars['FilterValue'];
};

export enum FilterOperator {
  After = 'after',
  And = 'and',
  At = 'at',
  Before = 'before',
  /**
   * Will transform `FilterValue` to a regex. Special characters will not be escaped.
   *
   * Example:
   * ```
   * const data = [
   *   "a",
   *   "abc",
   *   "abd",
   *   "ccd"
   * ]
   *
   * const filter = {
   *   value: "a",
   *   operator: "contains"
   * }
   *
   * -> ["a", "abc", "abd"]
   *
   * const filter = {
   *   value: "a",
   *   operator: "equals"
   * }
   *
   * -> ["a"]
   * ```
   *
   * If the Document value by which you want to filter is an array, the `FilterValue` has to match at least one of the values of the beforementioned array.
   */
  Contains = 'contains',
  Empty = 'empty',
  /** Document value must match FilterValue. If Document value is an Array, the Array needs to include the FilterValue */
  Equals = 'equals',
  Gt = 'gt',
  Gte = 'gte',
  HasRole = 'hasRole',
  Lt = 'lt',
  Lte = 'lte',
  NotEmpty = 'notEmpty',
  Or = 'or',
  ZipAreaContains = 'zipAreaContains',
  ZipAreaEquals = 'zipAreaEquals'
}

export type Group = {
  __typename?: 'Group';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Holiday = {
  __typename?: 'Holiday';
  end: Scalars['Date'];
  fieldsOfLaw?: Maybe<Array<FieldOfLaw>>;
  global?: Maybe<Scalars['Boolean']>;
  start: Scalars['Date'];
};

export type HolidayInput = {
  end: Scalars['Date'];
  fieldOfLawIds?: InputMaybe<Array<Scalars['String']>>;
  global?: InputMaybe<Scalars['Boolean']>;
  start: Scalars['Date'];
};

/** Return parameter of login query */
export type LoginResponse = {
  __typename?: 'LoginResponse';
  avvRequired?: Maybe<Scalars['Boolean']>;
  /** JWT Token usable for authenticating other requests. */
  jwtToken: Scalars['String'];
  user: User;
};

export type MatchingConfig = {
  __typename?: 'MatchingConfig';
  active: Scalars['Boolean'];
  chancelleryLocation: ChancelleryLocation;
  chancelleryLocationId: Scalars['String'];
  deleted: Scalars['Boolean'];
  fallback: Scalars['Boolean'];
  fieldsOfLaw: FieldsOfLaw;
  id: Scalars['String'];
  partners: Partners;
  priority?: Maybe<Scalars['Int']>;
  products: Products;
  tags?: Maybe<Array<Scalars['String']>>;
  weight: ConfigWeight;
  zipAreas: Array<Scalars['String']>;
};

export type MatchingConfigInput = {
  active: Scalars['Boolean'];
  chancelleryLocationId: Scalars['String'];
  deleted: Scalars['Boolean'];
  fallback: Scalars['Boolean'];
  fieldOfLawIds: FieldOfLawIds;
  id?: InputMaybe<Scalars['String']>;
  partnerIds: PartnerIds;
  priority?: InputMaybe<Scalars['Int']>;
  productIds: ProductIds;
  tags?: InputMaybe<Array<Scalars['String']>>;
  weight: ConfigWeight;
  zipAreas: Array<Scalars['String']>;
};

export type MatchingConfigList = {
  __typename?: 'MatchingConfigList';
  list: Array<MatchingConfig>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptAvv: Scalars['Boolean'];
  confirmAccount: Scalars['Boolean'];
  createPartner: Partner;
  createUser: User;
  deletePartner: DeletionSuccess;
  deleteUser: User;
  googleLogin?: Maybe<LoginResponse>;
  register: Scalars['String'];
  resendConfirm: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  resetPasswordRequest: Scalars['Boolean'];
  saveChancellery?: Maybe<Chancellery>;
  saveChancelleryLocation?: Maybe<ChancelleryLocation>;
  saveGroups: Array<Group>;
  saveMatchingConfig?: Maybe<MatchingConfig>;
  sendClientMessage: Scalars['Boolean'];
  setPassword: Scalars['Boolean'];
  updatePartner: Partner;
  updateUser: User;
};


export type MutationAcceptAvvArgs = {
  userId: Scalars['String'];
};


export type MutationConfirmAccountArgs = {
  token: Scalars['String'];
};


export type MutationCreatePartnerArgs = {
  partner: PartnerInput;
};


export type MutationCreateUserArgs = {
  user: UserInput;
};


export type MutationDeletePartnerArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationGoogleLoginArgs = {
  authCode: Scalars['String'];
  registerToken?: InputMaybe<Scalars['String']>;
};


export type MutationRegisterArgs = {
  urlPrefix: Scalars['String'];
  user: RegisterInput;
};


export type MutationResendConfirmArgs = {
  email: Scalars['String'];
  urlPrefix: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  resetToken: Scalars['String'];
};


export type MutationResetPasswordRequestArgs = {
  email: Scalars['String'];
  urlPrefix: Scalars['String'];
};


export type MutationSaveChancelleryArgs = {
  chancellery: ChancelleryInput;
};


export type MutationSaveChancelleryLocationArgs = {
  chancelleryLocation: ChancelleryLocationInput;
};


export type MutationSaveGroupsArgs = {
  groups: Array<InputMaybe<Scalars['String']>>;
};


export type MutationSaveMatchingConfigArgs = {
  matchingConfig: MatchingConfigInput;
};


export type MutationSendClientMessageArgs = {
  message: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};


export type MutationSetPasswordArgs = {
  current: Scalars['String'];
  id: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationUpdatePartnerArgs = {
  partner: PartnerInput;
};


export type MutationUpdateUserArgs = {
  user: UserInput;
};

export type OpeningHoursValue = {
  __typename?: 'OpeningHoursValue';
  enabled: Scalars['Boolean'];
  end?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
};

export type OpeningHoursValueInput = {
  enabled: Scalars['Boolean'];
  end?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
};

export type OrderVolume = {
  __typename?: 'OrderVolume';
  fieldOfLaw: FieldOfLaw;
  weeklyMax?: Maybe<Scalars['Int']>;
  weeklyMin?: Maybe<Scalars['Int']>;
};

export type OrderVolumeInput = {
  fieldOfLawId: Scalars['String'];
  weeklyMax?: InputMaybe<Scalars['Int']>;
  weeklyMin?: InputMaybe<Scalars['Int']>;
};

export type Partner = {
  __typename?: 'Partner';
  /** Is Partner acitve or deactivated */
  active: Scalars['Boolean'];
  /** Indicates if Partner has already accepted agb */
  agbsPreAccepted: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  /** uuid of Partner */
  id: Scalars['String'];
  /** Legal protection insurer that is automatically set on each AdviceRequest coming from this partner */
  insurer?: Maybe<Scalars['String']>;
  /** Important system-wide Partner that should not be deleted */
  locked: Scalars['Boolean'];
  modifiedAt?: Maybe<Scalars['Date']>;
  /** Visible name of Partner */
  name: Scalars['String'];
  /** Validation configuration for discount i.e. input of valid customer id */
  partnerValidation?: Maybe<PartnerValidation>;
  /** Visible phone of Partner */
  phone?: Maybe<Scalars['String']>;
};

export type PartnerErrorInfo = {
  __typename?: 'PartnerErrorInfo';
  partnerIds: Array<Scalars['String']>;
};

export type PartnerIds = {
  excluded?: InputMaybe<Array<Scalars['String']>>;
  included: Array<Scalars['String']>;
};

export type PartnerInput = {
  active: Scalars['Boolean'];
  agbsPreAccepted: Scalars['Boolean'];
  id: Scalars['String'];
  insurer?: InputMaybe<Scalars['String']>;
  locked: Scalars['Boolean'];
  name: Scalars['String'];
  partnerValidation?: InputMaybe<PartnerValidationInput>;
  phone?: InputMaybe<Scalars['String']>;
};

export type PartnerList = {
  __typename?: 'PartnerList';
  list: Array<Partner>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type PartnerValidation = {
  __typename?: 'PartnerValidation';
  enabled: Scalars['Boolean'];
  intro: Scalars['String'];
  regex: Scalars['String'];
};

export type PartnerValidationInput = {
  enabled: Scalars['Boolean'];
  intro: Scalars['String'];
  regex: Scalars['String'];
};

export type Partners = {
  __typename?: 'Partners';
  excluded?: Maybe<Array<Partner>>;
  included: Array<Partner>;
};

export type Period = {
  __typename?: 'Period';
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type PeriodErrorInfo = {
  __typename?: 'PeriodErrorInfo';
  periods: Array<Period>;
};

export type PeriodInput = {
  daily?: InputMaybe<Scalars['Int']>;
  monthly?: InputMaybe<Scalars['Int']>;
  weekly?: InputMaybe<Scalars['Int']>;
};

export type Person = {
  __typename?: 'Person';
  city?: Maybe<Scalars['String']>;
  firstname: Scalars['String'];
  foa?: Maybe<Scalars['String']>;
  insuranceNumber?: Maybe<Scalars['String']>;
  lastname: Scalars['String'];
  legalProtectionInsurer?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNr?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type PersonInput = {
  address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['String']>;
  firstname: Scalars['String'];
  foa?: InputMaybe<Scalars['String']>;
  insuranceNumber?: InputMaybe<Scalars['String']>;
  lastname: Scalars['String'];
  legalProtectionInsurer?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type PlatformInput = {
  business: Scalars['Boolean'];
  consumer: Scalars['Boolean'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['String'];
  name: Scalars['String'];
  type?: Maybe<ProductType>;
};

export type ProductErrorInfo = {
  __typename?: 'ProductErrorInfo';
  productIds: Array<Scalars['String']>;
};

export type ProductIds = {
  excluded?: InputMaybe<Array<Scalars['String']>>;
  included: Array<Scalars['String']>;
};

export enum ProductType {
  Business = 'BUSINESS',
  Consumer = 'CONSUMER'
}

export type Products = {
  __typename?: 'Products';
  excluded?: Maybe<Array<Product>>;
  included: Array<Product>;
};

export type Query = {
  __typename?: 'Query';
  chancelleries: ChancelleryList;
  chancellery: Chancellery;
  chancelleryLocation: ChancelleryLocation;
  chancelleryLocations: ChancelleryLocationList;
  createExport: Scalars['String'];
  /** Used to check if any given E-Mail is available for registration */
  emailAvailable: Scalars['Boolean'];
  groupList: Array<Group>;
  /**
   * Used for authenticating a user. Returns a jwt token in response that can be used to authenticate other requests.
   * To authenticate requests add an http header of the following form
   * ```
   *  x-access-token: <jwtToken>
   * ```
   */
  login?: Maybe<LoginResponse>;
  matchingConfig: MatchingConfig;
  matchingConfigCoverage: CoverageInfo;
  matchingConfigs: MatchingConfigList;
  partner: Partner;
  partners: PartnerList;
  /** Contains high level access rights of this user */
  rights: Array<Right>;
  /** Contains high level roles of this user */
  roles: Array<Role>;
  user: User;
  userList: UserList;
  /** Used to verify an existing jwt token. Return corresponding User on success */
  verify?: Maybe<User>;
};


export type QueryChancelleriesArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type QueryChancelleryArgs = {
  id: Scalars['String'];
};


export type QueryChancelleryLocationArgs = {
  id: Scalars['String'];
};


export type QueryChancelleryLocationsArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type QueryCreateExportArgs = {
  variant?: InputMaybe<ExportVariant>;
};


export type QueryEmailAvailableArgs = {
  email: Scalars['String'];
};


export type QueryLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  registerToken?: InputMaybe<Scalars['String']>;
};


export type QueryMatchingConfigArgs = {
  id: Scalars['String'];
};


export type QueryMatchingConfigCoverageArgs = {
  cached?: InputMaybe<CoverageCacheConfiguration>;
};


export type QueryMatchingConfigsArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type QueryPartnerArgs = {
  id: Scalars['String'];
};


export type QueryPartnersArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserListArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};


export type QueryVerifyArgs = {
  jwtToken: Scalars['String'];
};

export type RegisterInput = {
  city?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  foa?: InputMaybe<Scalars['String']>;
  lastname: Scalars['String'];
  legalProtectionInsurer?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetNr?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type Right = {
  __typename?: 'Right';
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type RightRef = {
  __typename?: 'RightRef';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['String'];
  name: Scalars['String'];
  rights: Array<Right>;
};

export type RoleInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type RoleRef = {
  __typename?: 'RoleRef';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SecupaySettings = {
  __typename?: 'SecupaySettings';
  active: Scalars['Boolean'];
  contractId: Scalars['String'];
};

export type SecupaySettingsInput = {
  active: Scalars['Boolean'];
  contractId: Scalars['String'];
};

export type Sort = {
  sortBy: Scalars['String'];
  sortDirection: SortDirection;
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type User = {
  __typename?: 'User';
  accessBitSet?: Maybe<Scalars['AccessBitSet']>;
  active: Scalars['Boolean'];
  billingCompany?: Maybe<Company>;
  channel?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  deleted: Scalars['Boolean'];
  email: Scalars['String'];
  excludedRights?: Maybe<Array<Right>>;
  groups: Array<Group>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  lastLogin?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  organisation?: Maybe<Scalars['String']>;
  organisationType?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  organizationType?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
  registeredAt: Scalars['Date'];
  rights: Array<Right>;
  roles: Array<RoleRef>;
};

export type UserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  billingCompany?: InputMaybe<CompanyInput>;
  channel?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<CompanyInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  excludedRights?: InputMaybe<Array<Scalars['String']>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  organisation?: InputMaybe<Scalars['String']>;
  organisationType?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  person?: InputMaybe<PersonInput>;
  rights?: InputMaybe<Array<Scalars['String']>>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  sendRegisterEmail?: InputMaybe<Scalars['Boolean']>;
};

export type UserList = {
  __typename?: 'UserList';
  list: Array<Maybe<User>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type ZipError = {
  __typename?: 'ZipError';
  lower: Scalars['String'];
  upper: Scalars['String'];
};

export type ZipErrorInfo = {
  __typename?: 'ZipErrorInfo';
  areas: Array<ZipError>;
};

export type CreateUserMutationVariables = Exact<{
  user: UserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: string, parentId?: string | null, name: string, email: string, active: boolean, organisation?: string | null, organisationType?: string | null, channel?: string | null, registeredAt: any, lastLogin?: any | null, deleted: boolean, roles: Array<{ __typename?: 'RoleRef', id: string, name: string }>, groups: Array<{ __typename?: 'Group', id: string, name: string }>, rights: Array<{ __typename?: 'Right', id: string, name: string, description: string }>, excludedRights?: Array<{ __typename?: 'Right', id: string, name: string, description: string }> | null } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', email: string } };

export type GetChancelleriesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
  filters?: InputMaybe<Array<Filter> | Filter>;
}>;


export type GetChancelleriesQuery = { __typename?: 'Query', chancelleries: { __typename?: 'ChancelleryList', total: number, list: Array<{ __typename?: 'Chancellery', id: string, name: string, chancelleryLocations: Array<{ __typename?: 'ChancelleryLocation', id: string, address: { __typename?: 'Address', street: string, city: string } }> }> } };

export type GetUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, parentId?: string | null, name: string, email: string, active: boolean, organisation?: string | null, organisationType?: string | null, channel?: string | null, registeredAt: any, lastLogin?: any | null, deleted: boolean, roles: Array<{ __typename?: 'RoleRef', id: string, name: string }>, groups: Array<{ __typename?: 'Group', id: string, name: string }>, rights: Array<{ __typename?: 'Right', id: string, name: string, description: string }>, excludedRights?: Array<{ __typename?: 'Right', id: string, name: string, description: string }> | null } };

export type GetUserGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserGroupsQuery = { __typename?: 'Query', groupList: Array<{ __typename?: 'Group', id: string, name: string }> };

export type GetUserrightsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserrightsQuery = { __typename?: 'Query', rights: Array<{ __typename?: 'Right', id: string, name: string, description: string }> };

export type GetUserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserRolesQuery = { __typename?: 'Query', roles: Array<{ __typename?: 'Role', id: string, name: string, rights: Array<{ __typename?: 'Right', name: string, id: string }> }> };

export type GetUsersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;


export type GetUsersQuery = { __typename?: 'Query', userList: { __typename?: 'UserList', page: number, pageSize: number, total: number, list: Array<{ __typename?: 'User', id: string, parentId?: string | null, name: string, email: string, active: boolean, registeredAt: any, lastLogin?: any | null, organisation?: string | null, roles: Array<{ __typename?: 'RoleRef', name: string }>, groups: Array<{ __typename?: 'Group', id: string, name: string }>, rights: Array<{ __typename?: 'Right', id: string }>, excludedRights?: Array<{ __typename?: 'Right', id: string }> | null } | null> } };

export type GetUsersFilteredQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
  filters?: InputMaybe<Array<Filter> | Filter>;
}>;


export type GetUsersFilteredQuery = { __typename?: 'Query', userList: { __typename?: 'UserList', page: number, pageSize: number, total: number, list: Array<{ __typename?: 'User', id: string, parentId?: string | null, name: string, email: string, active: boolean, registeredAt: any, lastLogin?: any | null, organisation?: string | null, roles: Array<{ __typename?: 'RoleRef', name: string }> } | null> } };

export type SaveUserGroupsMutationVariables = Exact<{
  groups: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type SaveUserGroupsMutation = { __typename?: 'Mutation', saveGroups: Array<{ __typename?: 'Group', id: string, name: string }> };

export type UpdateUserMutationVariables = Exact<{
  user: UserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, parentId?: string | null, name: string, email: string, active: boolean, organisation?: string | null, organisationType?: string | null, channel?: string | null, registeredAt: any, lastLogin?: any | null, deleted: boolean, roles: Array<{ __typename?: 'RoleRef', id: string, name: string }>, groups: Array<{ __typename?: 'Group', id: string, name: string }>, rights: Array<{ __typename?: 'Right', id: string, name: string, description: string }>, excludedRights?: Array<{ __typename?: 'Right', id: string, name: string, description: string }> | null } };
