import * as z from 'zod'

const requiredMessage = 'Fall-ID ist erforderlich'
export const adviceIdValidationSchema = z.object({
  adviceId: z
    .string({
      required_error: requiredMessage,
      invalid_type_error: requiredMessage,
    })
    .trim()
    .nonempty(requiredMessage),
})
