import { zodResolver } from '@hookform/resolvers/zod'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import SaveIcon from '@mui/icons-material/Save'
import { Button, Divider, Stack, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import {
  AbortButton,
  ConfirmDialog,
  createGridColumnsFrom,
  FormActionBox,
  HeaderButtonGroup,
  LoadingMessage,
  PageLayout,
  SaveButton,
} from 'common/components-mui'
import { TextField, Autocomplete } from 'common/components-mui/react-hook-form'
import { useRedirect } from 'common/hooks'
import { useUser } from 'common/user-context'
import { getQueryParam } from 'common/utils'

import {
  cancelRequest,
  getAdviceRequest,
  getFieldsOfLaw,
  getPartner,
  matchChancelleries,
  finishMatching,
  saveAdviceRequest,
} from '../actions'
import { AdviceListStatusLabel, AdviceStaticFields, ChancelleryStaticFields } from '../components'
import {
  EMPTY_ADVICE_REQUEST,
  COUNTRIES,
  adviceForeignFormInitialValues,
  PAGE_URL,
  NEW_URL,
  adviceForeignFormSchema,
} from '../constants'
import { ContactType, Partner, Status, AdviceRequestFlattened, RowChancellery, AdviceForeignForm } from '../interfaces'
import { DisplayComponent } from '../interfaces/DisplayComponent'
import { mapAdviceRequestToMatchInput, mapResponseToRowChancelleries, mapResponseToAdviceRequest } from '../mappings'
import { getMostImportantRole } from '../utils'

const getDisplayComponent = (status: AdviceRequestFlattened['status']): DisplayComponent => ({
  buttonCancel:
    status === Status.AT_CHANCELLERY ||
    status === Status.AWAITING_CALL ||
    status === Status.AWAITING_CALLBACK ||
    status === Status.EDITING ||
    status === Status.RESUBMISSION,
  buttonDraft: status === Status.NEW || status === Status.EDITING,
  buttonForward: status === Status.NEW || status === Status.EDITING,
  tableMatching: status === Status.NEW || status === Status.EDITING,
  formFields: status === Status.NEW || status === Status.EDITING,
})

export const AdviceForeignFormPage: FunctionComponent = () => {
  const adviceId = getQueryParam(useLocation(), 'adviceId')
  const { user } = useUser()
  const { control, getValues, reset, handleSubmit } = useForm<AdviceForeignForm>({
    defaultValues: adviceForeignFormInitialValues,
    resolver: zodResolver(adviceForeignFormSchema),
  })
  const redirect = useRedirect()
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false)
  const [isMatchingDialogOpen, setIsMatchingDialogOpen] = useState<boolean>(false)

  const [chancelleries, setChancelleries] = useState<Array<RowChancellery> | []>([])
  const [selectedChancellery, setSelectedChancellery] = useState<RowChancellery | null>(null)

  // fetch fields of law
  const [fieldsOfLaw, setFieldsOfLaw] = useState<Array<{ id: string; name: string }>>([])
  const [isLoadingFieldsOfLaw, setIsLoadingFieldsOfLaw] = useState<boolean>(true)
  const fetchFieldsOfLaw = async (): Promise<void> => {
    getFieldsOfLaw({}).then(e => {
      setFieldsOfLaw(e.fieldsOfLaw.list.filter(fol => !/.*?: International.*/.test(fol.id)))
      setIsLoadingFieldsOfLaw(false)
    })
  }
  useEffect(() => {
    if (isLoadingFieldsOfLaw) fetchFieldsOfLaw()
  }, [isLoadingFieldsOfLaw])

  // fetch advice request
  const [adviceRequest, setAdviceRequest] = useState<AdviceRequestFlattened>(EMPTY_ADVICE_REQUEST)
  const [isLoadingAdviceRequest, setLoadingAdviceRequest] = useState<boolean>(true)
  const fetchAdviceRequest = async (id: string): Promise<void> => {
    getAdviceRequest({ adviceId: id }).then(e => {
      if (!('adviceRequest' in e)) return
      setAdviceRequest(mapResponseToAdviceRequest(e.adviceRequest))
      setLoadingAdviceRequest(false)
    })
  }

  // fetch partner
  const [partner, setPartner] = useState<Partner>()
  const [isLoadingPartner, setIsLoadingPartner] = useState<boolean>(true)
  const fetchPartner = async (): Promise<void> => {
    getPartner({ id: 'devk-ausland' }).then(e => {
      setPartner(e.partner)
      setIsLoadingPartner(false)
    })
  }
  useEffect(() => {
    if (isLoadingPartner) fetchPartner()
  }, [isLoadingPartner])

  const isLoading = isLoadingFieldsOfLaw || isLoadingPartner || (isLoadingAdviceRequest && !!adviceId)

  useEffect(() => {
    if (!adviceId) {
      setLoadingAdviceRequest(false)
      return
    }

    setLoadingAdviceRequest(true)

    getAdviceRequest({ adviceId })
      .then(({ adviceRequest: request }) => {
        reset({
          damageNumber: request.caseData?.find(e => e.name === 'damageNumber')?.value || '',
          country: request.person.address.country || null,
          fieldOfLaw: request.fieldOfLaw?.id || null,
          description: request.description || '',
        })
        setAdviceRequest(mapResponseToAdviceRequest(request))
      })
      .finally(() => setLoadingAdviceRequest(false))
  }, [adviceId, setLoadingAdviceRequest, reset])

  const handleCancel = async (): Promise<void> => {
    await cancelRequest({ cancelRequestInput: { adviceId: adviceRequest?.adviceId } })
    await fetchAdviceRequest(adviceRequest?.adviceId)
    redirect(`${PAGE_URL}/form-foreign?adviceId=${adviceRequest?.adviceId}`)
  }

  const updateAdviceRequest = (values: AdviceForeignForm): AdviceRequestFlattened => {
    const updatedAdviceRequest = {
      ...adviceRequest,
      ...values,
      zip: adviceRequest.zip || '50667',
      contactType: adviceRequest.contactType || ContactType.Redirect,
      partner,
    }
    setAdviceRequest(updatedAdviceRequest)
    return updatedAdviceRequest
  }

  const onSubmit = async (values: AdviceForeignForm): Promise<void> => {
    const updatedAdviceRequest = updateAdviceRequest(values)
    matchChancelleries({
      matchInput: mapAdviceRequestToMatchInput(updatedAdviceRequest),
    }).then(e => setChancelleries(mapResponseToRowChancelleries(e.matchChancelleries)))
  }

  const handleAbort = (): void => {
    adviceId ? redirect(PAGE_URL) : redirect(NEW_URL)
  }

  const matchAdvice = async (chancelleryId: string): Promise<void> => {
    const adviceRequestId = await finishMatching({ ...adviceRequest, chancelleryId })
    redirect(`${PAGE_URL}/form-foreign?adviceId=${adviceRequestId}`)
  }

  const saveAdvice = async (): Promise<void> => {
    const values = getValues()
    const updatedAdviceRequest = updateAdviceRequest(values)
    const adviceRequestId = await saveAdviceRequest({ ...updatedAdviceRequest })
    !adviceId && redirect(`${PAGE_URL}/form-foreign?adviceId=${adviceRequestId}`)
  }

  const handleOpenMatchingModal = (chancellery: RowChancellery): void => {
    setSelectedChancellery(chancellery)
    setIsMatchingDialogOpen(true)
  }

  const columns: Array<GridColDef> = createGridColumnsFrom([
    { field: 'name', headerName: 'Kanzlei', sortable: false, filterable: false, flex: 0.15 },
    {
      field: 'address',
      headerName: 'Adresse',
      sortable: false,
      filterable: false,
      flex: 0.2,
    },
    { field: 'total', sortable: false, filterable: false, headerName: 'Zähler' },
    {
      field: 'id',
      sortable: false,
      filterable: false,
      headerName: '',
      flex: 0.05,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Button variant="contained" color="primary" onClick={() => handleOpenMatchingModal(params.row)}>
          Weiterleiten
        </Button>
      ),
    },
  ])

  const displayComponent = getDisplayComponent(adviceRequest.status)
  const disableInput = !(adviceRequest.status === Status.NEW || adviceRequest.status === Status.EDITING)

  return (
    <PageLayout
      heading={adviceId ? `Auslandsantrag ${adviceId}` : 'Auslandsantrag erfassen'}
      headingExtension={<AdviceListStatusLabel status={adviceRequest.status} role={getMostImportantRole(user)} />}
    >
      <HeaderButtonGroup>
        {displayComponent.buttonDraft && (
          <Button onClick={saveAdvice} type="submit">
            <SaveIcon titleAccess="Entwurf speichern" />
          </Button>
        )}
        {displayComponent.buttonCancel && (
          <Button onClick={() => setIsCancelDialogOpen(true)}>
            <DoNotDisturbIcon titleAccess="Antrag stornieren" />
          </Button>
        )}
      </HeaderButtonGroup>
      <ConfirmDialog
        isOpen={isCancelDialogOpen}
        confirmAction={handleCancel}
        onClose={() => setIsCancelDialogOpen(false)}
        title={`Antrag ${adviceId} wirklich stornieren?`}
        content="Sie sind dabei, den Antrag zu stornieren. Dies kann nicht rückgängig gemacht werden. Soll der Antrag wirklich storniert werden?"
      />
      <ConfirmDialog
        isOpen={isMatchingDialogOpen}
        confirmAction={() => matchAdvice(selectedChancellery?.id || '')}
        onClose={() => setIsMatchingDialogOpen(false)}
        title="Auslandsantrag an Kanzlei weiterleiten"
        content={
          <>
            <Typography variant="h6" sx={{ pb: 1 }}>
              Antragsdaten
            </Typography>
            <AdviceStaticFields adviceRequest={adviceRequest} />
            <Divider sx={{ pt: 4 }} />
            <Typography variant="h6" sx={{ pb: 1, pt: 2 }}>
              Kanzleidaten
            </Typography>
            <ChancelleryStaticFields chancellery={selectedChancellery} />
          </>
        }
      />
      <LoadingMessage isLoading={isLoading} />
      {displayComponent.formFields ? (
        <Stack spacing={3} component="form" id="advicerequest" onSubmit={handleSubmit(onSubmit, errors => console.error(errors))}>
          <TextField
            name="damageNumber"
            label="Schadensnummer"
            control={control}
            disabled={isLoading || disableInput}
            fullWidth
          />
          <Autocomplete
            name="country"
            label="Land"
            control={control}
            disabled={isLoading || disableInput}
            fullWidth
            options={COUNTRIES}
          />
          <Autocomplete
            name="fieldOfLaw"
            label="Ereignisart"
            control={control}
            disabled={isLoading || disableInput}
            fullWidth
            options={fieldsOfLaw.map(f => f.name)}
          />
          <TextField
            name="description"
            label="Ursache"
            control={control}
            disabled={isLoading || disableInput}
            fullWidth
            multiline
          />
        </Stack>
      ) : (
        <AdviceStaticFields adviceRequest={adviceRequest} />
      )}

      <FormActionBox>
        <AbortButton onClick={handleAbort} disabled={isLoading}>
          Abbrechen
        </AbortButton>
        {displayComponent.buttonForward && (
          <SaveButton type="submit" form="advicerequest">
            Weiterleiten an Kanzlei
          </SaveButton>
        )}
      </FormActionBox>
      {displayComponent.tableMatching && (
        <DataGrid
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          rows={chancelleries}
          columns={columns}
          hideFooter
          autoHeight
          disableSelectionOnClick
        />
      )}
    </PageLayout>
  )
}
