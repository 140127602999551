import useSWR from 'swr'

import { PARTNERS_ENDPOINT, PRODUCTS_BUSINESS_ENDPOINT, PRODUCTS_ENDPOINT } from 'common/constants'
import { fetcher } from 'common/utils'

import partnersQuery from '../graphql/getPartners.graphql'
import productsQuery from '../graphql/getProducts.graphql'
import productsLiteQuery from '../graphqlProductsLite/getProductsLite.graphql'
import { ApiResponse } from '../interfaces'
import { GetPartnersQuery, GetProductsQuery, SortDirection } from '../interfaces/schemaDefinition'
import { GetProductsLiteQuery } from '../interfaces/schemaProducts'

type PartnersApiResponse = ApiResponse<GetPartnersQuery>
type ProductsResponse = ApiResponse<GetProductsQuery>
type ProductsLiteResponse = ApiResponse<GetProductsLiteQuery>

export const useProductAndPartnerData = () => {
  const { data: productsBusinessResponse, error: productsBusinessError } = useSWR(
    [PRODUCTS_BUSINESS_ENDPOINT, productsQuery],
    (e: string, q: string) => fetcher<ProductsResponse>(e, q, { page: 1, pageSize: 100 })
  )
  const { data: productsResponse, error: productsError } = useSWR(
    [PRODUCTS_ENDPOINT, productsLiteQuery],
    (e: string, q: string) => fetcher<ProductsLiteResponse>(e, q, { page: 1, pageSize: 100 })
  )
  const { data: partnersResponse, error: partnersError } = useSWR([PARTNERS_ENDPOINT, partnersQuery], (e: string, q: string) =>
    fetcher<PartnersApiResponse>(e, q, { page: 1, pageSize: 100, sort: { sortBy: 'name', sortDirection: SortDirection.Asc } })
  )

  return {
    isLoading: (!partnersResponse && !partnersError) || (!productsBusinessResponse && !productsBusinessError && !productsError),
    products: {
      response: productsBusinessResponse,
      error: productsBusinessError,
    },
    productsNew: {
      response: productsResponse,
      error: productsError,
    },
    partners: {
      response: partnersResponse,
      error: partnersError,
    },
  }
}
