import { Box, Alert, AlertTitle, TableContainer, TableRow, TableCell, TableBody, Table } from '@mui/material'
import { useTheme } from '@mui/styles'
import dayjs from 'dayjs'
import React, { FC } from 'react'

import { useFallbackCoverage } from './useFallbackCoverage'

export const FallbackIssueWarnings: FC = () => {
  const result = useFallbackCoverage()
  const theme = useTheme()
  const tableCellStyle = {
    border: 'none',
    color: theme.palette.warningdark.main,
  }

  if (!result.data) return null

  const { missingProblems, temporaryMissingProblems } = result.data
  const fallbacksMissing = missingProblems?.map(p => p.fieldOfLaw?.name ?? '')
  const fallbacksOnHolidays = temporaryMissingProblems?.map(p => ({
    ...p,
    fieldOfLaw: p.fieldOfLaw?.name ?? '',
    info: {
      // eslint-disable-next-line fp/no-mutating-methods
      periods: p.info.periods.sort((a, b) => dayjs(a.start).valueOf() - dayjs(b.start).valueOf()),
    },
  }))

  if (fallbacksOnHolidays.length === 0) return null

  const fallbacksSet = new Set<string>()
  fallbacksOnHolidays.forEach(issue =>
    issue.info.periods.forEach(period => {
      const periodString = `${dayjs(period.start).format('DD.MM.YYYY')}–${dayjs(period.end).format('DD.MM.YYYY')}`
      fallbacksSet.add(`${issue.fieldOfLaw};${periodString}`)
    })
  )
  return (
    <Box mb={3}>
      <Alert severity="warning">
        <AlertTitle>Es gibt keine Fallback-Konfigurationen für folgende Rechtsgebiete:</AlertTitle>
        {fallbacksMissing.join(', ')}
      </Alert>

      <Alert severity="warning">
        <AlertTitle>Wegen Urlaubszeiten der beteiligten Standorte sind folgende Rechtsgebiete nicht immer abgedeckt:</AlertTitle>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {Array.from(fallbacksSet).map(value => {
                const [fieldOfLaw, period] = value.split(';')
                return (
                  <TableRow key={value}>
                    <TableCell sx={tableCellStyle}>{fieldOfLaw}</TableCell>
                    <TableCell sx={tableCellStyle}>{period}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Alert>
    </Box>
  )
}
