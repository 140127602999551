import React, { FC } from 'react'

import { PageLayout } from 'common/components-mui'

import { DeleteCustomerData } from '../components/DeleteCustomerData'

export const AdminToolsPage: FC = () => (
  <PageLayout heading="Admin-Tools">
    <DeleteCustomerData />
  </PageLayout>
)
