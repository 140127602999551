import { SelectChangeEvent } from '@mui/material'
import React, { FC } from 'react'

import { customPartners } from '../../constants'
import { AdviceRequest } from '../../interfaces'

interface PartnerViewProps {
  adviceRequest: AdviceRequest
  errors: { [key: string]: string }
  hasWebserviceAccess: boolean
  onCaseDataChange: (
    vnr: string,
    disputeAmount: string,
    deductible: boolean,
    deductibleAmount: string,
    protection: boolean,
    protectionAmount: Array<string>
  ) => void
  onFieldChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCheckboxChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onMultiSelectChange: (e: SelectChangeEvent<Array<string>>) => void
}

export const PartnerForm: FC<PartnerViewProps> = ({
  adviceRequest,
  errors,
  onFieldChange,
  onCheckboxChange,
  onCaseDataChange,
  onMultiSelectChange,
  hasWebserviceAccess = false,
}) => {
  if (!adviceRequest.partner) {
    return null
  }
  const InnerPartnerForm = customPartners[adviceRequest.partner.id!].form
  return (
    <div className="m-t-20">
      <InnerPartnerForm
        adviceRequest={adviceRequest}
        onMultiSelectChange={onMultiSelectChange}
        errors={errors}
        hasWebserviceAccess={hasWebserviceAccess}
        onFieldChange={onFieldChange}
        onCheckboxChange={onCheckboxChange}
        onCaseDataChange={onCaseDataChange}
      />
    </div>
  )
}
