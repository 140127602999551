import { Alert, AlertTitle, CircularProgress, SelectChangeEvent } from '@mui/material'
import { get } from 'lodash/fp'
import React, { ChangeEventHandler, FormEvent, useEffect, useState } from 'react'
import { FormFeedback } from 'reactstrap'

import { Partner } from 'common/api/getPartners'
import { ConfirmCheckbox, ConfirmSecondaryButton } from 'common/components/Confirmables'
import { InsuranceNumberField } from 'common/components/Devk'
import { TextAreaField, SelectField, CheckboxField, FieldSet, RadioField, FieldRow, InputField } from 'common/components/Form'
import { B2B_INDIVIDUAL_PRODUCT, EMPTY_OPTION, FIELDS_OF_LAW_ENTRIES_FILTERED, LANGUAGES } from 'common/constants'
import SVGCaseData from 'common/icons/CaseData.svg'
import SVGPersonData from 'common/icons/PersonData.svg'
import SVGArrow90Right from 'common/icons/arrow-90-right.svg'
import SVGSend from 'common/icons/send.svg'
import { ButtonEdit, ButtonSecondary } from 'common/ui/Button'
import { LoggedInUser } from 'common/user-context'
import { isExtendedDevkPartner, Consumer, Procedure, isNotEmpty, noOp, enqueueSnackbar } from 'common/utils'
import { formatCallbackTime } from 'common/utils/formatter'
import * as roles from 'common/utils/roles'
import { CheckInsuranceResult, ProductType } from 'packages/chancellery-search/interfaces/schemaDefinition'
import { getCallbackTimes } from 'packages/chancellery-search/utils/getCallbackTimes'

import { checkInsuranceNumber } from '../../actions'
import { AdviceRequest } from '../../interfaces'
import { hasPartnerCustomForm } from '../../utils/partner'
import { InsuranceModal } from '../InsuranceModal'

import { PartnerForm } from './PartnerForm'

const SHOW_BILLING_COMPANY_OPTION = false

const dateFormatter = Intl.DateTimeFormat('de', { dateStyle: 'full', timeStyle: 'medium' })

interface StatusRowProps {
  user?: LoggedInUser
  adviceRequest: AdviceRequest
  partners: Array<Partner>
  hidePartnerSelection: boolean
  isTRB?: boolean
  isChannel?: boolean
  isLawyer?: boolean
  isCallcenter?: boolean
  lockFieldOfLaw: boolean
  lockInsured?: boolean
  useBillingCompany?: boolean
  errors: { [key: string]: string }
  onCaseDataChange: (
    vnr: string,
    disputeAmount: string,
    deductible: boolean,
    deductibleAmount: string,
    protection: boolean,
    protectionAmount: Array<string>
  ) => void
  onPersonChange: (firstname: string, lastname: string, street: string, streetNr: string, city: string, zip: string) => void
  onFieldChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCheckboxChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onMultiSelectChange: (e: SelectChangeEvent<Array<string>>) => void
  onSendAGBs: Consumer<AdviceRequest>
  onAcceptAGBsClick: Procedure
  onAcceptDataClick: Procedure
  onSaveInternalNote: Procedure
  serviceType: string
  onServiceTypeChange: Consumer<FormEvent<HTMLInputElement>>
  onCustomerNotReachedClicked: () => Promise<void>
}

const InsuranceNumberResult = ({
  result,
  onTakeClick,
}: {
  result?: CheckInsuranceResult
  onTakeClick: (firstname: string, lastname: string, street: string, streetNr: string, city: string, zip: string) => void
}): JSX.Element | null => {
  const [insuranceModalOpen, setInsuranceModalOpen] = useState<boolean>(false)
  if (!result) {
    return null
  }

  if (!result.insured || !result.firstname || !result.lastname) {
    return (
      <div style={{ marginBottom: '1rem' }}>
        <FormFeedback>Es konnte kein Versicherungsnehmer gefunden werden</FormFeedback>
      </div>
    )
  }

  return (
    <div style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
      Versicherungsnehmer gefunden: {result.lastname}, {result.firstname}
      <ButtonSecondary
        style={{ marginLeft: '1rem', marginRight: '1rem' }}
        onClick={() =>
          onTakeClick(
            result.firstname || '',
            result.lastname || '',
            result.street || '',
            result.streetNr || '',
            result.city || '',
            result.zip || ''
          )
        }
      >
        Übernehmen
      </ButtonSecondary>
      <ButtonSecondary onClick={() => setInsuranceModalOpen(true)}>Versicherungsschutz anzeigen</ButtonSecondary>
      <InsuranceModal
        risks={result.risks}
        name={`${result.lastname}, ${result.firstname}`}
        open={insuranceModalOpen}
        onCompleteClick={() => setInsuranceModalOpen(false)}
      />
    </div>
  )
}

// eslint-disable-next-line complexity
export const SearchForm = ({
  user,
  adviceRequest,
  partners,
  hidePartnerSelection,
  onFieldChange,
  onPersonChange,
  onCaseDataChange,
  lockInsured,
  useBillingCompany,
  onCheckboxChange,
  errors,
  onAcceptAGBsClick,
  onAcceptDataClick,
  onSendAGBs,
  serviceType,
  onServiceTypeChange,
  onMultiSelectChange,
  onCustomerNotReachedClicked,
}: StatusRowProps): JSX.Element => {
  const isEmailSet = isNotEmpty(adviceRequest.email)
  const partner = partners.find(p => p.id === adviceRequest.partner?.id)
  const isBusinessProduct = adviceRequest.product.type === ProductType.Business
  const isIndividualProduct = adviceRequest.product.id === B2B_INDIVIDUAL_PRODUCT
  const isDevkInsured = adviceRequest.legalProtectionInsurer === 'DEVK' || partner?.insurer === 'DEVK'
  const isTRBPartner = ['devk-trb', 'dahag-trb'].includes(adviceRequest.partner ? adviceRequest.partner.id : '')

  const isCallcenter = roles.isCallcenter(user)
  const isLawyer = roles.isLawyer(user)
  const isTRB = roles.isTRB(user) || isTRBPartner
  const isDevk = roles.isChannel(user)
  const isAdmin = roles.isAdmin(user)

  const customerNotReached = (adviceRequest.customerNotReached ?? []).map(({ calledAt }) =>
    dateFormatter.format(new Date(calledAt))
  )

  const highlightAndRequired = !isTRB || serviceType !== 'Service Call'
  const [overrideCallback, setOverrideCallback] = useState<boolean>(false)

  const [checkInsuraceNumberLoading, setCheckInsuranceNumberLoading] = useState(false)
  const [insuranceNumberResult, setInsuranceNumberResult] = useState<CheckInsuranceResult>()

  const [notReachedClicked, setNotReachedClicked] = useState(false)
  const [notReachedTimeout, setNotReachedTimeout] = useState<NodeJS.Timeout | number>(0)

  useEffect(() => () => clearTimeout(notReachedTimeout), [notReachedTimeout])

  const onDEVKInsuranceNumberChange: ChangeEventHandler<HTMLInputElement> = async event => {
    onFieldChange(event)

    const insuranceNumber = event.currentTarget.value.replace(/_|\s/g, '')
    if (insuranceNumber.length === 9) {
      setCheckInsuranceNumberLoading(true)
      const result = await checkInsuranceNumber(insuranceNumber)
      setInsuranceNumberResult(result)
      setCheckInsuranceNumberLoading(false)
    }
  }

  return (
    <>
      {!hidePartnerSelection && (
        <div style={{ maxWidth: '53.5rem', paddingLeft: '36px' }}>
          <FieldRow>
            <SelectField
              id="select-partner"
              name="partner"
              value={adviceRequest.partner ? adviceRequest.partner.id : ''}
              label="Partner"
              onChange={onFieldChange}
              options={[
                ...partners.map(p => ({
                  value: p.id,
                  label: p.name,
                })),
              ]}
              width="calc(50% - 0.75rem)"
            />
          </FieldRow>
        </div>
      )}
      {hasPartnerCustomForm(get('partner.id', adviceRequest)) && (
        <PartnerForm
          adviceRequest={adviceRequest}
          errors={errors}
          hasWebserviceAccess={isDevk || isAdmin}
          onFieldChange={onFieldChange}
          onCheckboxChange={onCheckboxChange}
          onCaseDataChange={onCaseDataChange}
          onMultiSelectChange={onMultiSelectChange}
        />
      )}
      <FieldSet label="Personendaten" icon={<SVGPersonData />}>
        <div style={{ maxWidth: '53.5rem' }}>
          {!!isTRB && (
            <RadioField
              id="service-radio"
              name="serviceType"
              onChange={onServiceTypeChange}
              value={serviceType}
              options={[
                { label: 'Service Call', value: 'Service Call' },
                { label: 'Telefonische Rechtsberatung', value: 'Telefonische Rechtsberatung' },
              ]}
            />
          )}
          <FieldRow>
            {isDevkInsured && (isDevk || isTRB) && (
              <InsuranceNumberField
                label="Versicherungsscheinnummer"
                onChange={onDEVKInsuranceNumberChange}
                value={adviceRequest.insuranceNumber || ''}
                name="insuranceNumber"
                id="input-insuranceNumber"
                highlight={highlightAndRequired && !adviceRequest.insuranceNumberNotAvailabe}
                required={highlightAndRequired && !adviceRequest.insuranceNumberNotAvailabe}
                width="calc(50% - 1.5rem)"
                error={errors.insuranceNumber}
                disabled={adviceRequest.insuranceNumberNotAvailabe}
              />
            )}
            {checkInsuraceNumberLoading && (
              <CircularProgress style={{ marginTop: '2rem', marginLeft: '-3rem', marginRight: '1.5rem' }} size={24} />
            )}
          </FieldRow>
          <InsuranceNumberResult result={insuranceNumberResult} onTakeClick={onPersonChange} />
          <RadioField
            id="foa-radio"
            name="foa"
            onChange={onFieldChange}
            value={adviceRequest.foa}
            options={[
              { label: 'Herr', value: 'Herr' },
              { label: 'Frau', value: 'Frau' },
            ]}
          />

          <FieldRow>
            <InputField
              label="Vorname"
              id="input-firstname"
              name="firstname"
              value={adviceRequest.firstname}
              onChange={onFieldChange}
              required={highlightAndRequired}
              highlight={highlightAndRequired}
              error={errors.firstname}
            />
            <InputField
              label="Name"
              id="input-lastname"
              name="lastname"
              value={adviceRequest.lastname}
              onChange={onFieldChange}
              required={highlightAndRequired}
              highlight={highlightAndRequired}
              error={errors.lastname}
            />
          </FieldRow>
          <FieldRow>
            <InputField label="Straße" onChange={onFieldChange} value={adviceRequest.street} name="street" id="input-street" />
            <InputField
              label="Haus-Nr."
              onChange={onFieldChange}
              value={adviceRequest.streetNr}
              name="streetNr"
              id="input-streetNr"
              width="5rem"
            />
          </FieldRow>
          <FieldRow>
            <InputField
              label="Postleitzahl"
              onChange={onFieldChange}
              value={adviceRequest.zip}
              name="zip"
              id="input-zip"
              width="7rem"
              highlight={!isBusinessProduct && highlightAndRequired}
              required={!isBusinessProduct && highlightAndRequired}
              error={errors.zip}
            />
            <InputField label="Stadt" onChange={onFieldChange} value={adviceRequest.city} name="city" id="input-city" />
          </FieldRow>
          {errors.address && (
            <div style={{ margin: '-0.5rem 0 1rem' }}>
              <FormFeedback>{errors.address}</FormFeedback>
            </div>
          )}
          <FieldRow>
            <InputField
              label="E-Mail"
              onChange={onFieldChange}
              value={adviceRequest.email}
              name="email"
              id="input-email"
              highlight={highlightAndRequired}
              required={highlightAndRequired}
              error={errors.email}
            />
            <InputField
              label="Telefon Nr."
              onChange={onFieldChange}
              value={adviceRequest.phone}
              name="phone"
              id="input-phone"
              highlight={highlightAndRequired}
              required={highlightAndRequired}
              error={errors.phone}
            />
          </FieldRow>
          {!isBusinessProduct && (
            <>
              <FieldRow>
                {(!isDevkInsured || isCallcenter || isLawyer) && (
                  <InputField
                    label="Versicherungsscheinnummer"
                    onChange={onFieldChange}
                    value={adviceRequest.insuranceNumber || ''}
                    name="insuranceNumber"
                    id="input-insuranceNumber"
                    highlight={highlightAndRequired && !adviceRequest.insuranceNumberNotAvailabe}
                    required={highlightAndRequired && !adviceRequest.insuranceNumberNotAvailabe}
                    width="calc(50% - 1.5rem)"
                    error={errors.insuranceNumber}
                    disabled={adviceRequest.insuranceNumberNotAvailabe}
                  />
                )}
                <CheckboxField
                  id="checkbox-insuranceNumber-not-available"
                  label="Versicherungsscheinnummer nicht bekannt"
                  name="insuranceNumberNotAvailabe"
                  checked={!!adviceRequest.insuranceNumberNotAvailabe}
                  onChange={onCheckboxChange}
                  style={{ paddingTop: '1.5rem' }}
                />
              </FieldRow>
            </>
          )}

          <FieldRow>
            <CheckboxField
              id="checkbox-insured"
              label="Kunde hat eine Rechtsschutzversicherung"
              name="insured"
              checked={adviceRequest.insured}
              onChange={onCheckboxChange}
              disabled={!!lockInsured}
            />
          </FieldRow>
          {isTRB && (
            <FieldRow>
              <CheckboxField
                id="checkbox-authenticated"
                label="Kunde wünscht eine Weiterleitung ins KLUGO Netzwerk"
                name="authenticated"
                checked={!!adviceRequest.authenticated}
                onChange={onCheckboxChange}
              />
            </FieldRow>
          )}
          {adviceRequest.status !== 'cancelled' && (
            <>
              {!isTRB && !isExtendedDevkPartner(partner?.id) && (
                <>
                  <FieldRow>
                    <ConfirmCheckbox
                      id="checkbox-agbsAccepted"
                      label="AGBs akzeptiert"
                      name="agbsAccepted"
                      checked={adviceRequest.agbsAccepted}
                      onChange={onCheckboxChange}
                      disabled={adviceRequest.agbsAccepted}
                      error={errors.agbsAccepted}
                      onComplete={onAcceptAGBsClick}
                      question="AGBs wirklich akzeptieren?"
                      description="Bitte bestätigen Sie dass der Kunde die AGBs akzeptiert hat."
                    />
                  </FieldRow>
                  {adviceRequest.product.type !== ProductType.Business && (
                    <FieldRow>
                      <ConfirmCheckbox
                        id="checkbox-dataAccepted"
                        label="Einwilligungserklärung akzeptiert"
                        name="dataAccepted"
                        checked={adviceRequest.dataAccepted}
                        onChange={onCheckboxChange}
                        disabled={adviceRequest.dataAccepted && !isTRB}
                        onComplete={onAcceptDataClick}
                        question="Einwilligungserklärung wirklich akzeptieren?"
                        description={
                          isTRB ? '' : 'Bitte bestätigen Sie dass der Kunde die Einwilligungserklärung akzeptiert hat.'
                        }
                        error={errors.dataAccepted}
                      />
                    </FieldRow>
                  )}
                  {(!adviceRequest.dataAccepted || !adviceRequest.agbsAccepted) && (
                    <div className="flex-row">
                      <SVGArrow90Right />
                      <ConfirmSecondaryButton
                        iconLeft={<SVGSend />}
                        question={
                          isEmailSet
                            ? 'AGBs und Einwilligungserklärung senden?'
                            : 'Sie müssen eine gültige E-Mail-Adresse angeben'
                        }
                        description={
                          isEmailSet
                            ? `Usere AGBs und die Einwilligungserklärung werden an ${adviceRequest.email} geschickt.`
                            : 'Die AGBs und die Einwilligungserklärung können nur an eine gültige E-Mail-Adresse geschickt werden.'
                        }
                        onComplete={isEmailSet ? () => onSendAGBs(adviceRequest) : noOp}
                      >
                        AGBs & Einwilligungserklärung senden
                      </ConfirmSecondaryButton>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </FieldSet>
      {!!isBusinessProduct && (
        <FieldSet label="Unternehmensinformationen" icon={<SVGCaseData />}>
          <div style={{ maxWidth: '53.5rem' }}>
            <FieldRow>
              <InputField
                label="Unternehmensname / Name"
                id="input-company-name"
                name="companyName"
                value={adviceRequest.companyName}
                onChange={onFieldChange}
                required={highlightAndRequired}
                highlight={highlightAndRequired}
                error={errors.companyName}
              />
              <InputField
                label="Unternehmensform"
                id="input-company-companyType"
                name="companyCompanyType"
                value={adviceRequest.companyCompanyType}
                onChange={onFieldChange}
                error={errors.companyCompanyType}
              />
            </FieldRow>
            <FieldRow>
              <InputField
                label="Straße"
                id="input-company-address-street"
                name="companyAddressStreet"
                value={adviceRequest.companyAddressStreet}
                onChange={onFieldChange}
                required={highlightAndRequired && !isIndividualProduct}
                highlight={highlightAndRequired && !isIndividualProduct}
                error={errors.companyAddressStreet}
              />
              <InputField
                label="Haus-Nr."
                id="input-company-address-street-nr"
                name="companyAddressStreetNr"
                value={adviceRequest.companyAddressStreetNr}
                onChange={onFieldChange}
                required={highlightAndRequired && !isIndividualProduct}
                highlight={highlightAndRequired && !isIndividualProduct}
                error={errors.companyAddressStreetNr}
                width="5rem"
              />
            </FieldRow>
            <FieldRow>
              <InputField
                label="Postleitzahl"
                id="input-company-address-zip"
                name="companyAddressZip"
                value={adviceRequest.companyAddressZip}
                onChange={onFieldChange}
                required={highlightAndRequired}
                highlight={highlightAndRequired}
                error={errors.companyAddressZip}
                width="7rem"
              />
              <InputField
                label="Stadt"
                id="input-company-address-city"
                name="companyAddressCity"
                value={adviceRequest.companyAddressCity}
                onChange={onFieldChange}
                required={highlightAndRequired}
                highlight={highlightAndRequired}
                error={errors.companyAddressCity}
              />
            </FieldRow>
            <div style={{ maxWidth: '26rem' }}>
              <FieldRow>
                <InputField
                  label="USt-IdNr. / Steuernummer"
                  id="input-company-taxid"
                  name="companyTaxId"
                  value={adviceRequest.companyTaxId}
                  onChange={onFieldChange}
                />
              </FieldRow>
              {SHOW_BILLING_COMPANY_OPTION && (
                <FieldRow>
                  <CheckboxField
                    id="checkbox-billingCompany"
                    label="Abweichende Rechnungsadresse"
                    name="billingCompany"
                    checked={!!useBillingCompany}
                    onChange={onCheckboxChange}
                  />
                </FieldRow>
              )}
            </div>

            {!!useBillingCompany && SHOW_BILLING_COMPANY_OPTION && (
              <>
                <FieldRow>
                  <InputField
                    label="Unternehmensname / Name"
                    id="input-billingCompany-name"
                    name="billingCompanyName"
                    value={adviceRequest.billingCompanyName}
                    onChange={onFieldChange}
                    required={highlightAndRequired}
                    highlight={highlightAndRequired}
                    error={errors.billingCompanyName}
                  />
                  <InputField
                    label="Unternehmensform"
                    id="input-billingCompany-companyType"
                    name="billingCompanyCompanyType"
                    value={adviceRequest.billingCompanyCompanyType}
                    onChange={onFieldChange}
                    required={highlightAndRequired}
                    highlight={highlightAndRequired}
                    error={errors.billingCompanyCompanyType}
                  />
                </FieldRow>
                <FieldRow>
                  <InputField
                    label="Straße"
                    id="input-billingCompany-address-street"
                    name="billingCompanyAddressStreet"
                    value={adviceRequest.billingCompanyAddressStreet}
                    onChange={onFieldChange}
                    required={highlightAndRequired}
                    highlight={highlightAndRequired}
                    error={errors.billingCompanyAddressStreet}
                  />
                  <InputField
                    label="Haus-Nr."
                    id="input-billingCompany-address-street-nr"
                    name="companyAddressStreetNr"
                    value={adviceRequest.billingCompanyAddressStreetNr}
                    onChange={onFieldChange}
                    required={highlightAndRequired}
                    highlight={highlightAndRequired}
                    error={errors.billingCompanyAddressStreetNr}
                    width="5rem"
                  />
                </FieldRow>
                <FieldRow>
                  <InputField
                    label="Postleitzahl"
                    id="input-billingCompany-address-zip"
                    name="billingCompanyAddressZip"
                    value={adviceRequest.billingCompanyAddressZip}
                    onChange={onFieldChange}
                    required={highlightAndRequired}
                    highlight={highlightAndRequired}
                    error={errors.billingCompanyAddressZip}
                    width="7rem"
                  />
                  <InputField
                    label="Stadt"
                    id="input-billingCompany-address-city"
                    name="billingCompanyAddressCity"
                    value={adviceRequest.billingCompanyAddressCity}
                    onChange={onFieldChange}
                    required={highlightAndRequired}
                    highlight={highlightAndRequired}
                    error={errors.billingCompanyAddressCity}
                  />
                </FieldRow>
                <div style={{ maxWidth: '26rem' }}>
                  <FieldRow>
                    <InputField
                      label="USt-IdNr. / Steuernummer"
                      id="input-firstname"
                      name="firstname"
                      value={adviceRequest.billingCompanyTaxId}
                      onChange={onFieldChange}
                    />
                  </FieldRow>
                </div>
              </>
            )}
          </div>
        </FieldSet>
      )}
      <FieldSet label="Falldaten" icon={<SVGCaseData />}>
        {!isBusinessProduct && (
          <>
            <FieldRow>
              <SelectField
                id="select-fieldOfLaw"
                name="fieldOfLaw"
                value={adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.id : ''}
                label="Rechtsgebiet"
                onChange={onFieldChange}
                options={[
                  EMPTY_OPTION,
                  ...FIELDS_OF_LAW_ENTRIES_FILTERED(user).map(f => ({
                    value: f.id,
                    label: f.name,
                  })),
                ]}
                width="22.5rem"
                required={highlightAndRequired}
                highlight={highlightAndRequired}
                error={errors.fieldOfLaw}
              />

              <SelectField
                id="select-language"
                name="language"
                onChange={onFieldChange}
                value={adviceRequest.language ? adviceRequest.language.code : 'de'}
                label="Sprache"
                options={[...LANGUAGES.map(l => ({ value: l.code, label: l.label }))]}
                width="15rem"
              />
            </FieldRow>
            {adviceRequest.contactType === 'CALLBACK' && adviceRequest.preferredCallbackTime && (
              <>
                <FieldRow>
                  <SelectField
                    id="select-callbackTime"
                    name="preferredCallbackTime"
                    onChange={onFieldChange}
                    value={adviceRequest.preferredCallbackTime.toString()}
                    label="Rückrufzeit:"
                    options={
                      !overrideCallback
                        ? [
                            {
                              value: adviceRequest.preferredCallbackTime.toString(),
                              label: formatCallbackTime(adviceRequest.preferredCallbackTime),
                            },
                          ]
                        : [EMPTY_OPTION, ...getCallbackTimes(new Date())]
                    }
                    width="22.5rem"
                    disabled={!overrideCallback}
                    highlight={overrideCallback}
                    required={overrideCallback}
                    error={errors.preferredCallbackTime}
                  />
                  <CheckboxField
                    id="checkbox-overrideCallback"
                    label="Rückrufzeit ändern"
                    name="overrideCallback"
                    checked={!!overrideCallback}
                    onChange={() => setOverrideCallback(!overrideCallback)}
                    style={{ paddingTop: '1.5rem' }}
                    disabled={overrideCallback}
                  />
                </FieldRow>
                {(isAdmin || isCallcenter) && (
                  <>
                    <FieldRow>
                      <ButtonEdit
                        style={{ marginLeft: '0.75rem' }}
                        color="secondary"
                        disabled={notReachedClicked}
                        onClick={() => {
                          setNotReachedClicked(true)
                          try {
                            onCustomerNotReachedClicked()
                            setNotReachedTimeout(setTimeout(() => setNotReachedClicked(false), 1000))

                            enqueueSnackbar('Eintrag für verpassten Anruf angelegt.', { variant: 'success' })
                          } catch (error) {
                            enqueueSnackbar('Es konnte kein Eintrag für den verpassten Anruf angelegt werden.', {
                              variant: 'error',
                            })
                            setNotReachedClicked(false)
                          }
                        }}
                      >
                        Kunde nicht erreicht
                      </ButtonEdit>
                    </FieldRow>
                    {customerNotReached.length > 0 && (
                      <Alert sx={{ margin: '0.5rem 0 0.75rem' }}>
                        <AlertTitle> Der Kunde wurde bereits zu folgenden Zeitpunkten nicht erreicht: </AlertTitle>
                        <ul style={{ margin: '0' }}>
                          {customerNotReached.map(date => (
                            <li key={date}>{date}</li>
                          ))}
                        </ul>
                      </Alert>
                    )}
                  </>
                )}
              </>
            )}
            {adviceRequest?.fieldOfLaw?.id === 'Dieselcheck' && (
              <FieldRow>
                <p className="important-note">
                  ⚠️ Bitte Autohersteller, -Modell und Kaufdatum abfragen und in der Fallbeschreibung angeben.
                </p>
              </FieldRow>
            )}
            <FieldRow>
              <TextAreaField
                id="textarea-description"
                name="description"
                value={adviceRequest.description}
                onChange={onFieldChange}
                label="Fallbeschreibung"
              />
            </FieldRow>
          </>
        )}
        <FieldRow>
          <TextAreaField
            id="textarea-externalNotes"
            name="externalNotes"
            value={adviceRequest.externalNotes}
            onChange={onFieldChange}
            label="Bemerkungen für den Anwalt"
          />
        </FieldRow>
      </FieldSet>
    </>
  )
}
