import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { AddCustomerNotReachedInput, AdviceRequest } from 'common/graphql/schemaDefinition'
import { request } from 'common/utils'

import query from '../graphql/addCustomerNotReached.graphql'

export const addCustomerNotReached = async (
  variables: AddCustomerNotReachedInput
): Promise<AdviceRequest['customerNotReached']> =>
  request<AdviceRequest['customerNotReached'], { customerNotReached: AddCustomerNotReachedInput }>(
    ADVICE_REQUESTS_ENDPOINT,
    query,
    {
      customerNotReached: variables,
    }
  )
