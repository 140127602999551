import { Card, CardHeader, CardContent, Typography, List, ListItem, ListItemText, Avatar } from '@mui/material'
import { lighten, darken } from '@mui/material/styles'
import { useTheme } from '@mui/styles'
import React, { FC } from 'react'

import { ErrorListCardProps } from '../interfaces'

export const ErrorListCard: FC<ErrorListCardProps> = ({
  children,
  severity,
  sx,
  list,
  title,
  showNumber = true,
  maxHeight = 500,
}) => {
  const theme = useTheme()
  return (
    <Card sx={sx}>
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: 'body2',
          fontWeight: 'bold',
        }}
        avatar={
          showNumber ? (
            <Avatar sx={{ backgroundColor: theme.palette[severity].main }}>{list.length < 100 ? list.length : '99+'}</Avatar>
          ) : null
        }
        sx={{
          backgroundColor: lighten(theme.palette[severity].main, 0.9),
          color: darken(theme.palette[severity].main, 0.6),
        }}
      />
      <CardContent>
        <Typography>{children}</Typography>
        <List
          sx={{
            maxHeight: () => `${maxHeight}px`,
            overflowY: 'auto',
          }}
        >
          {list.map(item => (
            <ListItem key={item}>
              <ListItemText>{item}</ListItemText>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}
