import { FormControl, Input, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useEffect } from 'react'

import { TextAreaField, SelectField, CheckboxField, FieldSet, FieldRow, InputField } from 'common/components/Form'
import { DEDUCTIBLE_AMOUNTS, PROTECTION_AMOUNTS } from 'common/constants'
import SVGCaseData from 'common/icons/CaseData.svg'
import { currencyFormatter } from 'common/utils'

import { AdviceRequest } from '../../../interfaces'

import { DamageNumberInput } from './DamageNumberInput'

interface DEVKFormProps {
  adviceRequest: AdviceRequest
  hasWebserviceAccess: boolean
  errors: { [key: string]: string }
  onFieldChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCheckboxChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onMultiSelectChange: (e: SelectChangeEvent<Array<string>>) => void
  onCaseDataChange: (
    vnr: string,
    disputeAmount: string,
    deductible: boolean,
    deductibleAmount: string,
    protection: boolean,
    protectionAmount: Array<string>
  ) => void
}

const EMPTY_OPTION = {
  label: '---Bitte wählen---',
  value: '',
}

export const DevkSteuerrungstarifForm = ({
  adviceRequest,
  onFieldChange,
  errors,
  onCheckboxChange,
  onCaseDataChange,
  hasWebserviceAccess,
  onMultiSelectChange,
}: DEVKFormProps): JSX.Element => {
  const now = new Date()
  const mask = `${now.getFullYear()} . dd . dd . ddddd . d`
  const formatChars = {
    d: '[0-9]',
  }

  useEffect(() => {
    if (!adviceRequest.deductible) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      onFieldChange({
        currentTarget: {
          name: 'deductible',
          value: true,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any)
    }

    if (adviceRequest.deductibleAmount !== '150') {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      onFieldChange({
        currentTarget: {
          name: 'deductibleAmount',
          value: '150',
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FieldSet className="devk-fieldset" label="DEVK Falldaten" icon={<SVGCaseData />}>
      <div className="devk-background">
        <FieldRow>
          <DamageNumberInput
            onCaseDataChange={onCaseDataChange}
            onCheckboxChange={onCheckboxChange}
            hasWebserviceAccess={hasWebserviceAccess}
            onFieldChange={onFieldChange}
            damageNumber={adviceRequest.damageNumber}
            damageNumberDisabled={adviceRequest.damageNumberDisabled}
            maskOptions={{
              mask,
              formatChars,
            }}
          />
        </FieldRow>
        <FieldRow>
          <InputField
            label="Streitwert"
            onChange={onFieldChange}
            value={adviceRequest.disputeAmount}
            name="disputeAmount"
            id="disputeAmount"
            width="24rem"
          />
        </FieldRow>
        <FieldRow>
          <CheckboxField
            disabled
            className="padding-top-1-5rem"
            onChange={onCheckboxChange}
            checked={!!adviceRequest.deductible}
            name="deductible"
            id="checkbox-deductible"
            label="Selbstbeteiligung"
            width="10rem"
          />
          <SelectField
            label="Höhe der Selbstbeteiligung"
            disabled
            onChange={onFieldChange}
            value={adviceRequest ? adviceRequest.deductibleAmount : ''}
            name="deductibleAmount"
            id="deductibleAmount"
            error={errors.deductibleAmount}
            width="12.5rem"
            options={[
              EMPTY_OPTION,
              ...DEDUCTIBLE_AMOUNTS.map(v => ({
                value: v,
                label: currencyFormatter(v),
              })),
            ]}
          />
        </FieldRow>
        <FieldRow>
          <CheckboxField
            className="padding-top-1-5rem"
            onChange={onCheckboxChange}
            checked={adviceRequest.protection}
            name="protection"
            id="checkbox-protection"
            label="Kostenschutz"
            width="10rem"
          />
          <div>
            <InputLabel
              sx={{
                mx: 1.5,
                fontSize: '14px',
                fontWeight: 500,
                color: 'white',
              }}
              id="select-protectionAmount-label"
            >
              Umfang Kostenschutz
            </InputLabel>

            <FormControl disabled={!adviceRequest.protection} sx={{ minWidth: '15rem', mx: 1.5, fontSize: 2 }}>
              <Select
                id="select-protectionAmount"
                name="protectionAmount"
                sx={{
                  backgroundColor: '#f7f7f9',
                  pl: 2,
                  py: 0.5,
                  borderRadius: 1,
                  maxWidth: '20rem',
                  color: '#495057',
                  '&.Mui-disabled': {
                    backgroundColor: '#e9ecef',
                  },
                  '&.Mui-disabled #select-protectionAmount': {
                    color: '#495057',
                    WebkitTextFillColor: '#495057',
                  },
                }}
                multiple
                displayEmpty
                value={adviceRequest.protectionAmount}
                onChange={onMultiSelectChange}
                input={<Input disableUnderline />}
              >
                <MenuItem value="" disabled>
                  ---Bitte wählen---
                </MenuItem>
                {PROTECTION_AMOUNTS.map(name => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <SelectField
            label="Umfang Kostenschutz"
            disabled={!adviceRequest.protection}
            onChange={onFieldChange}
            value={adviceRequest ? adviceRequest.protectionAmount : []}
            name="protectionAmount"
            id="select-protectionAmount"
            error={errors.protectionAmount}
            width="12.5rem"
            options={[
              EMPTY_OPTION,
              ...PROTECTION_AMOUNTS.map(v => ({
                value: v,
                label: v,
              })),
            ]}
          />
        </FieldRow>
        <FieldRow>
          <TextAreaField
            onChange={onFieldChange}
            value={adviceRequest.internalPartnerNotes}
            name="internalPartnerNotes"
            id="internalPartnerNotes"
            label="DEVK interne Bemerkungen"
          />
        </FieldRow>
      </div>
    </FieldSet>
  )
}
