import { AdviceRequestFlattened } from '../interfaces'

export const CASE_DATA_FIELDS: Array<keyof AdviceRequestFlattened> = [
  'damageNumber',
  'deductible',
  'deductibleAmount',
  'protection',
  'protectionAmount',
  'disputeAmount',
  'internalPartnerNotes',
]
