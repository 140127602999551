import WarningIcon from '@mui/icons-material/Warning'
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { FunctionComponent, useState } from 'react'

import { useFormActionButtonStyles } from '../helpers'

type DeleteWithConfirmationProps = {
  actionButtonText?: string
  disabled?: boolean
  onConfirm?: () => Promise<void>
  overrideOpenState?: { open: boolean; setOpen: (v: boolean) => void }
}

const useStyles = makeStyles(theme => ({
  confirmTitle: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
  },
}))

export const DeleteWithConfirmation: FunctionComponent<DeleteWithConfirmationProps> = ({
  actionButtonText,
  children,
  disabled,
  onConfirm,
  overrideOpenState,
}) => {
  const { button } = useFormActionButtonStyles()
  const { confirmTitle } = useStyles()
  const [deleteConfirmLocalOpen, setDeleteConfirmLocalOpen] = useState(false)
  const setDeleteConfirmOpen = (v: boolean): void => {
    overrideOpenState ? overrideOpenState.setOpen(v) : setDeleteConfirmLocalOpen(v)
  }
  const deleteConfirmOpen = overrideOpenState ? overrideOpenState.open : deleteConfirmLocalOpen
  return (
    <>
      <Button className={button} variant="outlined" type="button" onClick={() => setDeleteConfirmOpen(true)} disabled={disabled}>
        {actionButtonText || 'Löschen'}
      </Button>
      <Dialog open={deleteConfirmOpen} aria-labelledby="delete-confirm-title">
        <DialogTitle classes={{ root: confirmTitle }} id="delete-confirm-title">
          <WarningIcon />
          <Box ml={1}>
            <Typography variant="h6" component="h2">
              Achtung!
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={() => setDeleteConfirmOpen(false)}>
            Abbrechen
          </Button>
          <Button
            onClick={async () => {
              await onConfirm?.()
              setDeleteConfirmOpen(false)
            }}
          >
            {actionButtonText || 'Löschen'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
