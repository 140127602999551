// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NeYPnfpOzO7B5YtEQcUM{list-style-type:none;padding-inline-start:0;padding-top:.5rem;margin-bottom:0}.NeYPnfpOzO7B5YtEQcUM li{margin-bottom:1rem}.NeYPnfpOzO7B5YtEQcUM li:last-child{margin-bottom:.5rem}", "",{"version":3,"sources":["webpack://./src/packages/products-business/components/FormattedList/FormattedList.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,sBAAA,CACA,iBAAA,CACA,eAAA,CAEA,yBACE,kBAAA,CAGF,oCACE,mBAAA","sourcesContent":[".formattedList {\n  list-style-type: none;\n  padding-inline-start: 0;\n  padding-top: 0.5rem;\n  margin-bottom: 0;\n\n  li {\n    margin-bottom: 1rem;\n  }\n\n  li:last-child {\n    margin-bottom: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formattedList": "NeYPnfpOzO7B5YtEQcUM"
};
export default ___CSS_LOADER_EXPORT___;
