import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import getChancelleryQuery from '../graphql/getChancellery.graphql'
import getChancelleryLocationQuery from '../graphql/getChancelleryLocation.graphql'
import matchingConfigQuery from '../graphql/getMatchingConfiguration.graphql'
import saveChancelleryMutation from '../graphql/saveChancellery.graphql'
import saveChancelleryLocationMutation from '../graphql/saveChancelleryLocation.graphql'
import {
  ChancelleryInput,
  ChancelleryLocationInput,
  GetChancelleryLocationQuery,
  GetChancelleryQuery,
  GetMatchingConfigurationQuery,
  SaveChancelleryLocationMutation,
  SaveChancelleryMutation,
} from '../interfaces/schemaDefinition'
import { mapChancelleryAPIDataToForm, mapChancelleryLocationAPIDataToForm, mapMatchingConfigAPIDataToForm } from '../utils'

export const getChancelleryData = async (id: string): Promise<ReturnType<typeof mapChancelleryAPIDataToForm>> => {
  const { chancellery } = await request<GetChancelleryQuery>(CHANCELLERIES_ENDPOINT, getChancelleryQuery, { id })
  return mapChancelleryAPIDataToForm(chancellery)
}
export const getLocationData = async (id: string): Promise<ReturnType<typeof mapChancelleryLocationAPIDataToForm>> => {
  const { chancelleryLocation } = await request<GetChancelleryLocationQuery>(
    CHANCELLERIES_ENDPOINT,
    getChancelleryLocationQuery,
    { id }
  )
  return mapChancelleryLocationAPIDataToForm(chancelleryLocation)
}
export const getConfigData = async (id: string): Promise<ReturnType<typeof mapMatchingConfigAPIDataToForm>> => {
  const { matchingConfig } = await request<GetMatchingConfigurationQuery>(CHANCELLERIES_ENDPOINT, matchingConfigQuery, { id })
  return mapMatchingConfigAPIDataToForm(matchingConfig)
}

export const saveChancellery = (chancellery: ChancelleryInput): Promise<SaveChancelleryMutation> =>
  request<SaveChancelleryMutation>(CHANCELLERIES_ENDPOINT, saveChancelleryMutation, { chancellery })

export const saveChancelleryLocation = (
  chancelleryLocation: ChancelleryLocationInput
): Promise<SaveChancelleryLocationMutation> =>
  request<SaveChancelleryLocationMutation>(CHANCELLERIES_ENDPOINT, saveChancelleryLocationMutation, {
    chancelleryLocation,
  })
