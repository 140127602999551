type ContactPerson = {
  name: string
  email: string
}

export const CONTACT_PERSONS: Array<ContactPerson> = [
  { name: 'Henrike Schreyer-Vogt', email: 'henrike.schreyer-vogt@klugo.de' },
  { name: 'Sandra Schmid', email: 'sandra.schmid@klugo.de' },
  { name: 'Marcel Daniels', email: 'marcel.daniels@klugo.de' },
]
