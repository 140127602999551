import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Alert, Button, Typography } from '@mui/material'
import { sort } from 'ramda'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { fetchPartners } from 'common/api/getPartners'
import {
  PageLayout,
  FormActionBox,
  AbortButton,
  SaveButton,
  DeleteWithConfirmation,
  LoadingMessage,
  Grid,
} from 'common/components-mui'
import { TextField, Checkbox, Select, Autocomplete, ControlledTransferList, Option } from 'common/components-mui/react-hook-form'
import { PARTNERS_ENDPOINT, ROLES, USERS_ENDPOINT, USER_AUTH_ENDPOINT } from 'common/constants'
import { useParams, useRedirect } from 'common/hooks'
import { enqueueSnackbar } from 'common/utils'
import { generatePassword } from 'common/utils/passwordGen'

import { createUser, deleteUser, fetchUser, fetchUserGroups, fetchUserRights, updateUser } from '../actions'
import { FormUser, initialValues, userSchemaCreate, userSchemaUpdate } from '../constants'
import { useTRBManagersSelection, useRoleSelection, useChancellerySelection } from '../hooks'
import { Role, UserFormPageParams } from '../interfaces'
import { hasRoleTRB, hasRoleLawyer, hasRoleChannel, mapResponseToUser, mapUserToInput } from '../utils'

const sortOptionArray = (a: Option, b: Option): number => {
  if (a.label > b.label) {
    return 1
  } else {
    return -1
  }
}

export const UserFormPage: FC = () => {
  const redirect = useRedirect()
  const { id: userId } = useParams<UserFormPageParams>()
  const [isUserLoading, setUserLoading] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)

  const { data: groups, error: groupError } = useSWR([USERS_ENDPOINT, 'groups'], fetchUserGroups)
  const { data: partners, error: partnerError } = useSWR(PARTNERS_ENDPOINT, fetchPartners)
  const { data: availableRights } = useSWR([USER_AUTH_ENDPOINT, 'rights'], fetchUserRights)
  const sortedRights = useMemo(
    () => sort(sortOptionArray, availableRights?.map(right => ({ id: right.id, label: right.name })) ?? []),
    [availableRights]
  )
  const { control, reset, getValues, setValue, watch, handleSubmit } = useForm<FormUser>({
    defaultValues: initialValues,
    resolver: zodResolver(userId ? userSchemaUpdate : userSchemaCreate),
  })

  const [deleted, currentId, organisation] = getValues(['deleted', 'id', 'organisation'])
  const [roles] = watch(['roles'])

  const { disabled: rolesDisabled, getNewRights } = useRoleSelection(getValues('rights') ?? [])
  const { chancelleries: trbChancelleries, trbManagers, usersChancelleryName } = useTRBManagersSelection()
  const {
    disabled: chancelleryDisabled,
    options: chancelleryOptions,
    getOptionLabel,
    getOrganisationType,
  } = useChancellerySelection(organisation ?? '')

  const showChancellerySelect = hasRoleLawyer(roles)
  const showTRBSelect = hasRoleTRB(roles)
  const showChannelSelect = hasRoleChannel(roles)

  const disabled = isUserLoading || deleted

  useEffect(() => {
    if (!userId) {
      reset(initialValues)
      setUserLoading(false)
      return
    }

    setUserLoading(true)

    fetchUser(userId)
      .then(user => reset(mapResponseToUser(user)))
      .catch(() => enqueueSnackbar(`Der User mit der Id: ${userId} konnte nicht geladen werden.`, { variant: 'error' }))
      .then(() => setUserLoading(false))
  }, [userId, setUserLoading, reset])

  const onGeneratePassword = (): void => {
    const generatedPassword = generatePassword(10, 1)
    setValue('password', generatedPassword)
    setValue('passwordRepeat', generatedPassword)
    setHidePassword(false)
  }

  const onRolesChange = (newValue: Array<Role>, oldValue: Array<Role>): void => {
    const rights = getNewRights(newValue, oldValue)
    setValue('rights', rights)
  }

  const handleRedirect = (): void => redirect('/users')
  const onSubmit = async (values: FormUser): Promise<void> => {
    const organisationType = getOrganisationType(values.organisation ?? '')
    try {
      const user = await mapUserToInput({ ...values, organisationType })
      const shouldUpdate = values?.id
      const savedUser = shouldUpdate ? (await updateUser(user)).updateUser : (await createUser(user)).createUser

      if (!savedUser) return

      reset(mapResponseToUser(savedUser))
      enqueueSnackbar(`Nutzer ${savedUser.email} wurde erfolgreich ${shouldUpdate ? 'gespeichert' : 'angelegt'}.`, {
        variant: 'success',
      })

      if (!shouldUpdate) {
        // necessary as we need id in params to decide on schema
        redirect(`/users/edit/${savedUser.id}`)
      }
    } catch (error) {
      enqueueSnackbar(`Systemfehler: Nutzer konnte nicht gespeichert werden.`, {
        variant: 'error',
      })
      if (values.id) {
        enqueueSnackbar('Diese Email Adresse ist schon vorhanden.', { variant: 'error' })
      }
    }
  }

  const onSubmitAndClose = (values: FormUser): void => {
    onSubmit(values).then(handleRedirect)
  }

  const handleDeleteUser = async (id?: string): Promise<void> => {
    if (!id) return
    try {
      const deletedUser = (await deleteUser(id)).deleteUser
      if (!deletedUser) return
      enqueueSnackbar(`Nutzer ${deletedUser.email} wurde erfolgreich gelöscht.`, {
        variant: 'success',
      })
      handleRedirect()
    } catch (error) {
      enqueueSnackbar(`Systemfehler: Nutzer konnte nicht gelöscht werden.`, {
        variant: 'error',
      })
    }
  }

  return (
    <PageLayout heading="Nutzerverwaltung">
      <Box component="form" autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)} id="users">
        <LoadingMessage isLoading={isUserLoading} />
        {deleted && (
          <Box mb={3}>
            <Alert severity="warning">Dieser Benutzer wurde als „gelöscht“ markiert. Änderungen sind nicht mehr möglich.</Alert>
          </Box>
        )}
        <Grid container spacing={4}>
          <Grid xs={12}>
            <Checkbox control={control} name="active" label="Aktiv" disabled={disabled} />
          </Grid>
          <Grid xs={12} md={6} display="flex" flexDirection="column" gap={3}>
            <TextField control={control} name="name" label="Name" placeholder="Erika Mustermann" disabled={disabled} />
            <TextField
              control={control}
              type="email"
              name="email"
              label="E-Mail"
              placeholder="email@organistation.de"
              disabled={disabled || !!userId}
            />
            <TextField
              control={control}
              name="password"
              autoComplete="new-password"
              label="Passwort"
              type={hidePassword ? 'password' : 'text'}
              placeholder="Passwort"
              InputProps={{
                endAdornment: (
                  <Button onClick={onGeneratePassword} variant="contained" color="primary">
                    Generieren
                  </Button>
                ),
              }}
            />

            <TextField
              name="passwordRepeat"
              autoComplete="new-password-repeat"
              label="Wiederholung"
              control={control}
              type={hidePassword ? 'password' : 'text'}
              placeholder="Passwort wiederholung"
            />
          </Grid>
          <Grid xs={12} md={6} display="flex" flexDirection="column" gap={3}>
            <Autocomplete
              control={control}
              name="groups"
              multiple
              label="Nutzergruppen"
              placeholder="Hinzufügen…"
              disabled={!groups || groupError}
              options={groups ?? []}
              getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
              isOptionEqualToValue={(option, value) =>
                typeof option === 'string' || typeof value === 'string' ? option === value : option.id === value.id
              }
            />
            <Autocomplete
              control={control}
              multiple
              name="roles"
              label="Benutzerrollen"
              placeholder="z.B. Administrator"
              disabled={rolesDisabled}
              options={ROLES}
              filterSelectedOptions
              onBeforeChange={onRolesChange}
              getOptionLabel={(role: Role) => role.name}
              isOptionEqualToValue={(option: Role, value: Role) => option.id === value.id}
            />
            {showChancellerySelect && (
              <Autocomplete
                control={control}
                name="organisation"
                options={chancelleryOptions}
                getOptionLabel={getOptionLabel}
                disabled={chancelleryDisabled}
                label="Kanzlei"
                placeholder="Keine Auswahl"
              />
            )}
            {showTRBSelect && (
              <Select control={control} disabled={trbManagers.length === 0} name="parentId" label="TRB-Manager" fullWidth>
                {trbManagers.map(manager => (
                  <option key={manager.id} value={manager.id}>
                    {manager.name}, {usersChancelleryName(manager, trbChancelleries)}
                  </option>
                ))}
              </Select>
            )}
            {showChannelSelect && (
              <Select control={control} name="channel" label="Kanal" fullWidth disabled={!partners && !partnerError}>
                <option value="" />
                {partners &&
                  partners.map(partner => (
                    <option key={partner.id} value={partner.id}>
                      {partner.name}
                    </option>
                  ))}
              </Select>
            )}
          </Grid>
          <Grid xs={12}>
            <ControlledTransferList control={control} name="rights" options={sortedRights} label="Nutzerrechte" />
          </Grid>
        </Grid>
        <FormActionBox>
          {!deleted && (
            <DeleteWithConfirmation
              actionButtonText="Benutzer löschen"
              onConfirm={async () => {
                handleDeleteUser(currentId)
              }}
              disabled={!currentId}
            >
              <Typography>Diese Aktion ist nicht umkehrbar. Benutzer wird permanent gelöscht.</Typography>
            </DeleteWithConfirmation>
          )}
          <AbortButton onClick={handleRedirect}>{deleted ? 'Schließen' : null}</AbortButton>
          {deleted || (
            <>
              <SaveButton form="users" submit disabled={isUserLoading} />
              <SaveButton submit={false} onClick={handleSubmit(onSubmitAndClose)} disabled={isUserLoading}>
                Speichern und Schließen
              </SaveButton>
            </>
          )}
        </FormActionBox>
      </Box>
    </PageLayout>
  )
}
