import { createTheme } from '@mui/material/styles'
import { deDE } from '@mui/x-data-grid'

import { breakpoints } from './breakpoints'
import { createMuiButtonTheme } from './component-overrides/MuiButton'
import { createMuiLink } from './component-overrides/MuiLink'
import { palette } from './palette'
import { createTypography } from './typography'

// Add custom breakpoint to type
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    maxContentWidth: true
  }

  interface TypographyVariants {
    loginLink: React.CSSProperties
    loginTitle: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    loginLink?: React.CSSProperties
    loginTitle?: React.CSSProperties
  }

  interface Palette {
    loginbg: Palette['primary']
    warningdark: Palette['primary']
  }

  interface PaletteOptions {
    loginbg: PaletteOptions['primary']
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    loginLink: true
    loginTitle: true
  }
}
declare module '@mui/material/TextField' {
  interface TextFieldPropsOverrides {
    login: true
  }
}

const defaultTheme = createTheme()
const baseTheme = createTheme({ typography: createTypography(defaultTheme), palette, breakpoints })

const theme = createTheme(
  {
    ...baseTheme,
    components: {
      MuiButton: createMuiButtonTheme(baseTheme),
      MuiLink: createMuiLink(baseTheme),
    },
  },
  deDE
)

export default theme
